import React, { useContext } from "react";
import { Columns, Button } from "react-bulma-components/full";
import { SiteTypeContainer, SiteTypeCard } from "./SiteTypes";
import LocaleContext from "../../../context/LocaleContext";
import { CustomButton } from "../../CustomButton";
import { CustomButtonSecondary } from "../../CustomButtonSecondary";

export const Step0 = (props) => {
  const { choices, cancelEditor } = props;
  const { i18n, Translation } = useContext(LocaleContext);
  return (
    <div className="create-site-wrapper container">
      <div className="title is-4 has-text-centered">
        {i18n.t("projectInfo")}
      </div>
      <Columns className="has-margin-vertical ">
        <SiteTypeContainer numberOfChoices={choices.length}>
          {choices.map((choice, position) => (
            <SiteTypeCard
              key={choice.name}
              choice={choice}
              position={position}
              numberOfChoices={choices.length}
              buttonTrigger={choice.buttonTrigger}
            />
          ))}
        </SiteTypeContainer>
      </Columns>
      <Columns.Column></Columns.Column>
      <Columns.Column
        style={{ marginTop: "15%" }}
        size={4}
        offset={4}
        className="has-margin-vertical mt5 is-4 is-justify-content-end"
      >
        <div
          className="has-text-centered"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CustomButtonSecondary padding="2%" onClick={cancelEditor}>
            <Translation>{(t) => t("cancel")}</Translation>
          </CustomButtonSecondary>
        </div>
      </Columns.Column>
    </div>
  );
};
