const getKey = () => {
  switch (process.env.REACT_APP_ENV) {
    case "development":
      return "6Lfu5LwZAAAAAKeFrFRjRqtpVDACGvrrI39YfuJp";
    case "stage":
    case "test":
      return "6Ld8NVkcAAAAAHdScmexOBJiz2kkJil914h7KVCD";

    default:
      return "6LdL47wZAAAAAPaHujz65N4Kjy4DxCiJMCtwzLd6";
  }
};

const KEYS = {
  MAPS: "AIzaSyCESro7Kvz3ZzpIBhnFv8j4GuTWdxYU0qw",
  PROD: "AIzaSyBK3Wuib4Mhag3W7gAFnr93kD_sX_o7x58",
  DEV: "AIzaSyAaplEYbV2hAHRCGgTX6A5gqH3pTweIhsg",
  CAPTCHA: getKey(),
};

export default KEYS;
