import firebase from "firebase";
import { uuid } from "uuidv4";
import UserService from "./User";
import { handleRedirects } from "../helpers/Router";
import dayjs from "dayjs";
import _ from "lodash";
export const checkPresence = async (props) => {
  const { history, timestamp } = props;

  // Fetch the current user's ID from Firebase Authentication.
  var uid = firebase.auth()?.currentUser?.uid;
  if (!uid) return false;
  // Fetch the current user's from Firebase Authentication with current uid.
  let account = await UserService.get(uid);

  // Check if the user has a renew license. If so, we update the user's license.
  if (
    !_.isEmpty(account.renewLicense) &&
    dayjs().isAfter(dayjs(account.renewLicense.startDate, "DD/MM/YYYY")) &&
    dayjs().isBefore(dayjs(account.renewLicense.endDate, "DD/MM/YYYY"))
  ) {
    account = {
      ...account,
      allowedNumberOfSites: account.renewLicense?.allowedNumberOfSites,
      domain: account.renewLicense?.domain,
      endDate: account.renewLicense?.endDate,
      maxUsers: account.renewLicense?.maxUsers,
      optimNumber: account.renewLicense?.optimNumber,
      license: account.renewLicense?.plan,
      startDate: account.renewLicense?.startDate,
      trackerOkd: account.renewLicense?.trackerOkd,
      type: account.renewLicense?.type,
      renewLicense: {},
    };
    // Renew the user's license.
    return await UserService.update(uid, account);
  }

  // Create a reference to this user's specific status node.
  const navId = localStorage.getItem("navId") ?? uuid();
  localStorage.setItem("navId", navId);

  // Check if the user licence is expired.
  if (
    dayjs(account.startDate, "DD/MM/YYYY").isAfter(dayjs()) ||
    dayjs(account.endDate, "DD/MM/YYYY").isBefore(dayjs())
  ) {
    if (
      dayjs(account._createdAt, "DD/MM/YYYY").isBefore(
        dayjs().subtract(15, "day"),
      ) &&
      account.license !== "DEMO"
    ) {
      handleRedirects(history, "#/signout");
      return window.location.reload();
    }
  }

  // Create a reference to this account.
  var userStatusDatabaseRef = firebase.database().ref("/users/" + uid);

  // Create a reference to this user with navId.
  var navIdStatusDatabaseRef = firebase
    .database()
    .ref("/users/" + uid + "/" + navId);

  // Fetching data from realtime database of the current user.
  async function getData() {
    const result = await new Promise((resolve, reject) => {
      try {
        userStatusDatabaseRef.on("value", (snapshot) => {
          return resolve(snapshot.val());
        });
      } catch (error) {
        reject(error);
      }
    });
    const data = await result;
    if (data) {
      return data;
    } else {
      return {};
    }
  }
  const data = async () => await getData();
  const count = async () => Object.keys(await data()).length;

  // If the number of online users is greater than the maximum number of users allowed for this account, we disconnect the user.
  if ((await count()) + 1 > account.maxUsers) {
    const dataRT = await data();
    if (!dataRT[navId]) {
      handleRedirects(history, "#/signout", { message: "Max users reached" });
      return window.location.reload();
    }
  }
  // Create a reference to the special '.info/connected' path in
  // Realtime Database. This path returns `true` when connected
  // and `false` when disconnected.
  firebase
    .database()
    .ref(".info/connected")
    .on("value", async function (snapshot) {
      //If we're not currently connected, don't do anything.
      if (snapshot.val() == false) {
        // handleRedirects(history, "#/signout", { message: "Max users reached" });
        // return window.location.reload();
      }

      // If we are currently connected, then use the 'onDisconnect()'
      // method to add a set which will only trigger once this
      // client has disconnected by closing the app,
      // losing internet, or any other means.
      navIdStatusDatabaseRef
        .onDisconnect()
        .remove()
        .then(async function () {
          // The promise returned from .onDisconnect().set() will
          // resolve as soon as the server acknowledges the onDisconnect()
          // request, NOT once we've actually disconnected:
          // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

          // We can now safely set ourselves as 'online' knowing that the
          // server will mark us as offline once we lose connection.

          if (
            (await count()) + 1 > account.maxUsers ||
            !localStorage.getItem("navId")
          ) {
            const dataRT = await data();
            if (!localStorage.getItem("navId") || !dataRT[navId]) {
              handleRedirects(history, "#/signout");
              return window.location.reload();
            }
          }
          navIdStatusDatabaseRef.set(timestamp);
          // If someone tries to disconnect while this instance is still online
          // then reset the state to online
          navIdStatusDatabaseRef.on("value", async function (snapshot) {
            if (!navigator.onLine) {
              navIdStatusDatabaseRef.remove();
              return;
            }
            if (!snapshot.val()) {
              if (
                (await count()) + 1 > account.maxUsers ||
                !localStorage.getItem("navId")
              ) {
                const dataRT = await data();
                if (!localStorage.getItem("navId") || !dataRT[navId]) {
                  handleRedirects(history, "#/signout");
                  return window.location.reload();
                }
              } else if (snapshot.val() === false) {
                handleRedirects(history, "#/signout");
                return window.location.reload();
              } else {
                navIdStatusDatabaseRef.set(timestamp);
              }
            } else {
              if ((await count()) + 1 > account.maxUsers) {
                const dataRT = await data();
                if (!dataRT[navId]) {
                  handleRedirects(history, "#/signout");
                  return window.location.reload();
                }
              }
            }
          });
        });
    });
};
