import ImgTech1 from "./../assets/img/techs/1_photovoltaic.png";
import ImgTech2 from "./../assets/img/techs/2_eolian.png";
import ImgTech3 from "./../assets/img/techs/3_battery.png";
import ImgTech4 from "./../assets/img/techs/4_elec_group.png";
import ImgTech5 from "./../assets/img/techs/5_network.png";
import ImgTech8 from "./../assets/img/techs/8_fuel.png";
import ImgTech9 from "./../assets/img/techs/9_electrolysis.png";
import ImgTech10 from "./../assets/img/techs/10_virtual_storage.png";
import ImgTech12 from "./../assets/img/techs/12_station.png";
import ImgTech13 from "./../assets/img/techs/13_chauffe_eau.png";

import JpgTech1 from "./../assets/img/techs_jpg/1_photovoltaic.jpg";
import JpgTech2 from "./../assets/img/techs_jpg/2_eolian.jpg";
import JpgTech3 from "./../assets/img/techs_jpg/3_battery.jpg";
import JpgTech4 from "./../assets/img/techs_jpg/4_elec_group.jpg";
import JpgTech5 from "./../assets/img/techs_jpg/5_network.jpg";
import JpgTech8 from "./../assets/img/techs_jpg/8_fuel.jpg";
import JpgTech9 from "./../assets/img/techs_jpg/9_electrolysis.jpg";
import JpgTech10 from "./../assets/img/techs_jpg/10_virtual_storage.jpg";
import JpgTech12 from "./../assets/img/techs_jpg/12_station.jpg";
import JpgTech13 from "./../assets/img/techs_jpg/13_chauffe_eau.jpg";

export default {
  version: 2.95,
  optimizationYear: 1,
  PV_TMY:
    "https://firebasestorage.googleapis.com/v0/b/likewatt-4bb4b.appspot.com/o/utils%2FTMY_PV.JPG?alt=media&token=36b5f3cb-19b7-4d34-9729-a093ca1d8370",
  WT_TMY:
    "https://firebasestorage.googleapis.com/v0/b/likewatt-4bb4b.appspot.com/o/utils%2FTMY_WT.JPG?alt=media&token=a1fae7ce-d207-4b8d-8843-a079b793dd67",
  POWERS_PV:
    "https://firebasestorage.googleapis.com/v0/b/likewatt-4bb4b.appspot.com/o/utils%2FPOWERS_PV.JPG?alt=media&token=228d166b-3c83-4e90-9a65-f57e75e6050c",
  POWERS_WT:
    "https://firebasestorage.googleapis.com/v0/b/likewatt-4bb4b.appspot.com/o/utils%2FPOWERS_WT.JPG?alt=media&token=426a8994-86cc-4e9a-8774-925c584145d8",
  ELECTROLYSIS_DEMAND:
    "https://firebasestorage.googleapis.com/v0/b/likewatt-4bb4b.appspot.com/o/utils%2FELECTROLYSIS_DEMAND.JPG?alt=media&token=66fa5bc2-3bd0-46c4-b081-aff63d41f939",
  ECS_DEMAND:
    "https://firebasestorage.googleapis.com/v0/b/likewatt-4bb4b.appspot.com/o/utils%2FECS_DEMAND.JPG?alt=media&token=263965e0-ffe6-485b-ad68-f14d2422c032",
  BEARINGS:
    "https://firebasestorage.googleapis.com/v0/b/likewatt-4bb4b.appspot.com/o/utils%2Frose_des_vents.png?alt=media&token=e99c3831-7fd5-42af-a238-02baa8b441bb",
  DISTRIBUTION_KEY: {
    PRORATA: "prorata",
    FIXED: "fixed",
    VARIABLE: "variable",
  },
  techTypes: {
    NETWORK: {
      id: 5,
      name: "Réseau",
      description: `Permet d’acheminer l’électricité des centrales de production vers les consommateurs mais aussi d’absorber la production décentralisée et de participer aux marchés de l’énergie.`,
      icon: ImgTech5,
      jpg: JpgTech5,

      techPrefix: "grid",
    },
    PHOTOVOLTAIC: {
      id: 1,
      name: "Photovoltaïque",
      description: `Transforme le rayonnement solaire en électricité grâce à des cellules intégrées à des panneaux qui peuvent être installés sur des bâtiments ou posés au sol. Sa capacité est très modulaire.`,
      icon: ImgTech1,
      jpg: JpgTech1,
      techPrefix: "pv",
    },
    PHOTOVOLTAIC2: {
      id: 11,
      name: "Photovoltaïque",
      description: `Transforme le rayonnement solaire en électricité grâce à des cellules intégrées à des panneaux qui peuvent être installés sur des bâtiments ou posés au sol. Sa capacité est très modulaire.`,
      icon: ImgTech1,
      jpg: JpgTech1,

      techPrefix: "pv2",
    },

    BATTERY: {
      id: 3,
      name: "Batterie",
      description: `Accumulateur réversible qui restitue sous forme d’énergie électrique l’énergie chimique générée par réactions électrochimiques. Les technologies Li-ion et Ni/MH ont remplacé le Pb et le Ni/Cd.`,
      icon: ImgTech3,
      jpg: JpgTech3,

      techPrefix: "battery",
    },
    VIRTUAL: {
      id: 10,
      name: "Stockage virtuel",
      description:
        "Offres de marché où le surplus de production locale n'est pas vendu mais comptabilisé et réutilisé à d'autres instants, moyennant le paiement des taxes de transport (TURPE).",
      icon: ImgTech10,
      jpg: JpgTech10,

      techPrefix: "vs",
    },
    STATION: {
      id: 12,
      name: "Bornes de recharge",
      description:
        "Une station de recharge de véhicules est une infrastructure équipée d'une — ou plusieurs — borne de recharge permettant la recharge des véhicules électriques.",
      icon: ImgTech12,
      jpg: JpgTech12,

      techPrefix: "station",
    },
    ECS: {
      id: 13,
      name: "Production d'eau chaude",
      description:
        "L'accumulateur d'une pompe à chaleur permet de conserver la chaleur en la stockant pour ensuite la restituer au moment où vous en avez besoin.",
      icon: ImgTech13,
      jpg: JpgTech13,
      techPrefix: "ecs",
    },
    EOLIAN: {
      id: 2,
      name: "Eolien",
      description: `Convertit l’énergie cinétique du vent en énergie mécanique entraînant un générateur qui produit de l’électricité. Une éolienne fournit une puissance de quelques kW jusqu’à 6 MW.`,
      icon: ImgTech2,
      jpg: JpgTech2,

      techPrefix: "wt",
    },

    FUEL: {
      id: 8,
      name: "Pile à combustible",
      description:
        "La PAC génère de l'électricité grâce à l'oxydation d'un combustible réducteur (l'hydrogène) sur une électrode et la réduction d'un oxydant, tel que l'oxygène de l'air, sur l'autre électrode.",
      icon: ImgTech8,
      jpg: JpgTech8,

      techPrefix: "fuelCell",
    },

    ELECTROLYSIS: {
      id: 9,
      name: "Electrolyse",
      description:
        "Système de production et de stockage de l'hydrogène par électrolyse de l'eau en consommant l'électricité, principalement produite par les énergies renouvelables.",
      icon: ImgTech9,
      jpg: JpgTech9,

      techPrefix: "electrolysis",
    },
    ELEC_GROUP: {
      id: 4,
      name: "Groupe électrogène",
      description: `Utilisé dans des zones que le réseau électrique ne dessert pas ou pour garantir une alimentation sans interruption, il se base sur un moteur consommant du Diesel, de l’essence ou du gaz.`,
      icon: ImgTech4,
      jpg: JpgTech4,

      techPrefix: "gen",
    },
  },
  objectiveTypes: {
    // PURCHASE_OPTIMISATION: {
    //   id: 1,
    //   name: "Arbitrage économique"
    // },
    // COVER_HEEL: {
    //   id: 2,
    //   name: "Couverture talon"
    // },
    // NETWORK_OFFLINE: {
    //   id: 3,
    //   name: "Déconnecté réseau"
    // },
    BACKUP: {
      id: 4,
      name: "Alimentation secours",
      unit: "h",
      unit2: "",
      value: 0,
      value2: 0,
    },
    SELF_PRODUCTION: {
      id: 5,
      name: "Autoproduction",
      unit: "%",
      value: 0,
    },

    CO2_REDUCTION: {
      id: 6,
      name: "Réduction CO2",
      unit: "%",
      value: 0,
    },
    // NEBEF: {
    //   id: 7,
    //   name: "Effacements",
    //   value: false,
    // },
    FCR: {
      id: 8,
      name: "Réglage de fréquence",
      value: false,
    },
    // SELF_CONSUMPTION: {
    //   id: 9,
    //   name: "Autoconsommation",
    //   unit: "%",
    //   value: 0,
    // },
    // CAPA: {
    //   id: 9,
    //   name: "Mécanisme de capacité",
    //   value: false,
    // },
  },
  pvUseCases: {
    FULL_CONSUMPTION: "FULL_CONSUMPTION",
    SURPLUS: "SURPLUS",
    TOTAL_PRODUCTION: "TOTAL_PRODUCTION",
    // AO_CONSUMPTION: "AO_CONSUMPTION",
    AOVS: "AOVS",
  },

  consumptionReferenceLabels: {
    REFERENCE: "REFERENCE",
    POST_REALIZATION: "POST_REALIZATION",
  },
};
