import React, { useState } from "react";
import { URL_DUPLICATE_FILE } from "../../constants/Auth";

const DuplicateFile = ({ token }) => {
  const [result, setResult] = useState("");
  const [color, setColor] = useState("green");
  const [isLoading, setIsLoading] = useState(false);
  const [siteId, setSiteId] = useState("ZY7s5Frve4MveNWFHrWF");
  const [scenarioId, setScenarioId] = useState("kjltAo4p1NZ5X344AMXs");
  const [to, setTo] = useState("kjltAo4p1NZ5X344AMXS");

  const send = () => {
    setIsLoading(true);
    fetch(URL_DUPLICATE_FILE, {
      method: "POST",
      headers: { Authorization: token },
      body: JSON.stringify({ siteId, scenarioId, to }),
    })
      .then((res) => {
        setColor(res.ok ? "green" : "red");
        res
          .text()
          .then((txt) => {
            try {
              setResult(JSON.stringify(JSON.parse(txt), null, "\t"));
              setIsLoading(false);
            } catch {
              setResult(txt);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            setColor("red");
            setResult(JSON.stringify(err));
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setColor("red");
        setResult(JSON.stringify(err));
        setIsLoading(false);
      });
  };
  return (
    <div className="cloud-functions-tester-container">
      {isLoading && <div class="cloud-functions-tester-loader"></div>}
      {!isLoading && (
        <>
          <label htmlFor="siteId">siteId:</label>
          <input
            id="siteId"
            type="text"
            value={siteId}
            onChange={({ target }) => setSiteId(target.value)}
          />
          <label htmlFor="scenarioId">scenarioId:</label>
          <input
            id="scenarioId"
            type="text"
            value={scenarioId}
            onChange={({ target }) => setScenarioId(target.value)}
          />
          <label htmlFor="to">to:</label>
          <input
            id="to"
            type="text"
            value={to}
            onChange={({ target }) => setTo(target.value)}
          />
          <hr />
          <label htmlFor="textarea">Response:</label>
          <textarea
            cols="30"
            rows="10"
            id="textarea"
            type="text"
            style={{ color }}
            value={result}
          />

          <button id="send" onClick={send}>
            SEND
          </button>
        </>
      )}
    </div>
  );
};

export default DuplicateFile;
