import { storage } from "../fire";

export const getFileUrlByPath = async (basePath, name) => {
  let url = "";
  try {
    url = await storage.ref(`${basePath}${name}`).getDownloadURL();
    console.log("URL:", url);
    return url;
  } catch (err) {
    console.log("There is no manual available");
    url = await storage.ref(`${basePath}${name}`).getDownloadURL();
    console.log("URL:", url);
    return url;
  }
};
