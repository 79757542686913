import React from "react";
import styled from "styled-components";
const StyledAbbr = styled.abbr`
  text-decoration: ${(props) =>
    props.isUnderlined ? "underline" : "none!important"};
`;

const AbbrContainer = styled.div`
  display: inline;
  &:hover {
    cursor: pointer;
  }
`;

export const CustomAbbr = ({ title, type, children, isUnderlined = false }) => {
  const ICONS = {
    QUESTION: "question-circle",
    INFO: "info-circle",
  };
  return (
    <AbbrContainer>
      <StyledAbbr title={title} isUnderlined={isUnderlined}>
        {children} {type && <i className={`fa fa-${ICONS[type]}`} />}
      </StyledAbbr>
    </AbbrContainer>
  );
};
