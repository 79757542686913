import React from "react";
import to from "await-to-js";
import { Columns } from "react-bulma-components/full";
import { Redirect, withRouter } from "react-router-dom";
import "./Home-c-and-i.scss";
import "react-datepicker/dist/react-datepicker.css";
import ApiService from "../../services/Api";
import SiteService from "../../services/Site";
import AuthService from "../../services/Auth";
import UserService from "../../services/User";
import loadable from "loadable-components";
import dayjs from "dayjs";
import { checkWidth } from "../../utils/Responsive";

const asideMenuItems = {
  SITES: "SITES",
  CREATE_SITE: "CREATE_SITE",
  EDIT_SITE: "EDIT_SITE",
};

const defaultUserInfo = {
  license: "OneSiteAccess",
  isTemporary: true,
  optimNumber: 9999999,
};

const EditorSiteForm = loadable(() =>
  import("../../components/SiteC&I/EditorSiteForm"),
);

class Home extends React.Component {
  initialStateTrackingCard = () => ({
    indicator: "",
    period: "",
    site: "",
    result: "",
  });

  constructor(props) {
    super(props);
    this.state = {
      rendering: "",
      sites: [],
      trackingCards: [],
      siteToEdit: null,
      siteToDelete: null,
      isLoadingUserSites: true,
      isLoadingUserTrackingCards: true,
      isCreatingTrackingCard: false,
      showDeleteSiteModal: false,
      allowedNumberOfSites: null,
      canCreateSite: true,
      isProducer: false,
      optimNumber: null,
      isSearching: false,
      wordToSearch: "",
      userType: "",
      userDefaultRates: [],
      isActive: false,
      isMobile: checkWidth(),
      editorStartingStep: 0,
    };
  }

  createUserSiteTag = async (sites) => {
    if (AuthService.getUserId()) {
      let countConsumer = 0,
        countProducer = 0;
      let siteTag = { hasConsumerTag: false, hasProducerTag: false };
      sites.forEach((site) => {
        site.type === "CONSUMER" ? countConsumer++ : countProducer++;
      });
      if (countConsumer > 0) siteTag.hasConsumerTag = true;
      if (countProducer > 0) siteTag.hasProducerTag = true;

      UserService.update(AuthService.getUserId(), { siteTag });
    }
  };

  fetchUserSites = async () => {
    let err,
      sites = null;

    this.setState({ isLoadingUserSites: true });
    [err, sites] = await to(SiteService.getUserSites(AuthService.getUserId()));
    this.setState({ isLoadingUserSites: false });
    if (err)
      return console.error(`Cannot fetch user sites. Error : ${err.message}`);
    sites.map((s) => (s.score = 0));
    this.setState({
      sites,
      wordToSearch: "",
    });

    this.createUserSiteTag(sites);

    UserService.update(AuthService.getUserId(), {
      _lastConnected: dayjs().format("DD/MM/YYYY HH:mm"),
    });
    this.canCreateSite();
  };

  fetchNumberOfSites = async () => {
    let err,
      user = null;

    [err, user] = await to(
      UserService.getNumberOfSite(AuthService.getUserId()),
    );

    if (err)
      return console.error(`Cannot fetch user sites. Error : ${err.message}`);

    this.setState({
      allowedNumberOfSites: user?.allowedNumberOfSites || 0,
      optimNumber: user?.optimNumber || 0,
    });
    this.canCreateSite();
  };
  canCreateSite = () => {
    let { sites, allowedNumberOfSites } = this.state;

    this.setState({
      canCreateSite: sites.length < allowedNumberOfSites,
    });
  };
  handleClickAsideMenu = (menuItem) => {
    switch (menuItem) {
      // case asideMenuItems.DASHBOARD:
      //   // We fetch also sites because we use the list of sites in card
      //   this.fetchUserTrackingCards();
      //   this.fetchNumberOfSites();
      //   this.fetchUserSites();
      //   break;
      case asideMenuItems.SITES:
        this.fetchNumberOfSites();
        this.fetchUserSites();

        break;
      default:
        // Nothing expected to be done
        // We put a default to be compliant with eslint
        break;
    }
    this.setState({ rendering: menuItem });
  };
  handleShowCreateSite = () => {
    // this.canCreateSite();
    this.setState({
      rendering: asideMenuItems.CREATE_SITE,
    });
  };

  handleShowEditSite = (siteToEdit) =>
    this.setState({ rendering: asideMenuItems.EDIT_SITE, siteToEdit });
  handleSubmitSuccessSite = async () => {
    await this.fetchUserSites();
    // this.setState({ rendering: asideMenuItems.ANALYZE });
  };
  handleShowModalRemoveSite = (siteToDelete) =>
    this.setState({ siteToDelete, showDeleteSiteModal: true });
  handleHideModalRemoveSite = () =>
    this.setState({ siteToDelete: null, showDeleteSiteModal: false });
  handleRemoveSite = async () => {
    const siteId = this.state.siteToDelete.id;
    let err = null;

    this.setState({
      isLoadingUserSites: true,
      showDeleteSiteModal: false,
      siteToDelete: null,
    });
    [err] = await to(SiteService.remove(siteId));
    if (err) return console.error(`Cannot remove site. Error : ${err.message}`);
    this.fetchUserSites();
  };

  updateNumberOfSite = async (allowedNumberOfSites) => {
    let err = null;

    [err] = await to(
      UserService.updateNumberOfSite(
        AuthService.getUserId(),
        allowedNumberOfSites,
      ),
    );
    if (err) console.log("err :", err);
  };

  handleGoSearch = () => {
    this.handleSearch(this.state.wordToSearch);
  };

  handleWordToSearch = (word) => this.setState({ wordToSearch: word });

  handleIsSearching = () => this.setState({ isSearching: true });

  handleSearch = (item) => {
    const capitalize = (s) => {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    };
    let itemArray = item.split(" ");
    const { sites } = this.state;
    let buffer = [...sites];
    buffer = buffer.map((u) => ({ ...u, score: 0 }));
    const criteria = ["name", "addressCity", "addressZipCode"];
    for (let i = 0; i < buffer.length; i++) {
      let score = 0;
      criteria.forEach((c) => {
        itemArray.forEach((item) => {
          if (
            sites?.[i]?.[c] &&
            (sites[i][c].toString().includes(item) ||
              sites[i][c].toString().includes(item.toLowerCase()) ||
              sites[i][c].toString().includes(item.toUpperCase()) ||
              sites[i][c].toString().includes(capitalize(item)) ||
              sites[i][c] === item)
          ) {
            score++;
            buffer[i].score = score;
          }
        });
      });
    }
    return this.setState({ sites: buffer, isSearching: false });
  };

  handleSiteUpdate = async (site) => {
    const { id } = site;
    this.setState({
      sites: this.state.sites.map((s) =>
        s.id === id ? { ...s, reportURL: "", updateStatus: true } : s,
      ),
    });
    await SiteService.update(id, {
      automaticUpdateError: null,
      reportURL: "",
    });
    ApiService.forceUpdateData(id);
    this.handleCheckSiteReportUrl(this.state.sites.find((s) => s.id === id));
  };

  // Used to check report URL for one site only
  handleCheckSiteReportUrl = async (site) => {
    let s = null;
    [, s] = await to(SiteService.getById(site.id));

    if (!AuthService.isAuth() || !site.purchaseAreaOfTension) return;

    if (s.reportURL) {
      return this.setState({
        sites: this.state.sites.map((site) =>
          site.id === s.id ? { ...s, updateStatus: false } : site,
        ),
      });
    }

    if (site.reportURL !== "" && site.reportURL !== null) return;

    if (site.reportURL === "" || site.reportURL === null) {
      if (
        s.automaticUpdateError &&
        s.automaticUpdateError !== "Report Retrieved"
      ) {
        console.log("1", s);
        return this.setState({
          sites: this.state.sites.map((site) =>
            s.id === site.id
              ? {
                  ...s,

                  automaticUpdateError: s.automaticUpdateError,
                  updateStatus: false,
                }
              : site,
          ),
        });
      } else if (s.reportURL === "") {
        console.log("2");

        this.handleCheckSiteReportUrl(s);
      }
    } else {
      console.log("3");

      return this.setState({
        sites: this.state.sites.map((s) =>
          s.id === site.id
            ? { ...s, automaticUpdateError: null, updateStatus: false }
            : s,
        ),
      });
    }
  };

  cancelEditor = () =>
    this.setState({ rendering: asideMenuItems.SITES, siteToEdit: null });
  renderCreateSite = () => (
    <EditorSiteForm
      onSubmitSuccess={this.handleSubmitSuccessSite}
      type={this.state.userType}
      userDefaultRates={this.state.userDefaultRates}
      cancelEditor={this.cancelEditor}
      routerProps={this.props.history}
    />
  );
  renderBody = () => {
    const { rendering, sites } = this.state;
    switch (rendering) {
      case asideMenuItems.ANALYZE:
        return (
          <Redirect
            to={{
              pathname: "/analyze",
              state: { site: sites[0], fromSiteList: true },
            }}
          />
        );
      case asideMenuItems.CREATE_SITE:
        return this.renderCreateSite();
      default:
        return <div></div>;
    }
  };

  render() {
    const { isActive, isMobile } = this.state;

    return (
      <div className="container__Columns">
        <Columns className="is-fullheight">
          {/* <Columns.Column
            id={`side_navbar`}
            className={`has-background-blue has-no-padding-horizontal${toggleClassName(
              isMobile,
              "isMobile"
            )}${toggleClassName(isActive, "active")}`}
            size={2}
          >
            <span
              className="icon trigger"
              onClick={() => this.setState({ isActive: !isActive })}
            >
              <i className="fa fa-angle-right" />
            </span>
          </Columns.Column> */}
          <Columns.Column size={12} className="body-container">
            {this.renderBody()}
          </Columns.Column>
        </Columns>
      </div>
    );
  }

  checkIfReportURL = async (site, counter) => {
    if (counter-- === 0) {
      SiteService.update(site.id, {
        automaticUpdateError:
          'Une erreur est survenue, merci de cliquer sur le bouton "Actualiser"',
      });
      return this.setState({
        sites: this.state.sites.map((stateSite) =>
          stateSite.id === site.id
            ? {
                ...s,
                automaticUpdateError:
                  'Une erreur est survenue, merci de cliquer sur le bouton "Actualiser"',
              }
            : stateSite,
        ),
      });
    }

    // if site already have a reportURL do nothing
    if (site.reportURL || site.automaticUpdateError) return;

    // fetch site from DB to check changes
    if (!AuthService.isAuth()) return;
    let [err, s] = await to(SiteService.getById(site.id));

    if (err) return console.log("Couldn't fetch site from DB");

    // if api throws an error update state
    if (
      s.automaticUpdateError !== null &&
      s.automaticUpdateError !== "Report Retrieved"
    ) {
      return this.setState({
        sites: this.state.sites.map((stateSite) =>
          stateSite.id === site.id
            ? {
                ...s,
              }
            : stateSite,
        ),
      });
    }

    // if api generated a reportURL update state
    if (s.reportURL && s.automaticUpdateError === "Report Retrieved")
      return this.setState({
        sites: this.state.sites.map((stateSite) =>
          stateSite.id === site.id
            ? {
                ...s,
              }
            : stateSite,
        ),
      });

    // enter the loop if there's no error and no report and site configured
    if (
      s.reportURL === "" &&
      s.enedisNumber &&
      s.automaticUpdateError === null &&
      s.purchaseAreaOfTension
    ) {
      setTimeout(() => {
        this.checkIfReportURL(s, counter);
      }, 5000); // recheck data base for changes after 5s
    }
  };

  fetchUserType = async () => {
    let user = null;
    [, user] = await to(UserService.get(AuthService.getUserId()));
    this.setState({ userType: user?.type || "CONSUMER" });
  };

  fetchUserDefaultRates = async () => {
    let user = null;
    [, user] = await to(UserService.get(AuthService.getUserId()));

    this.setState({ userDefaultRates: user?.defaultRates || [] });
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", () => {});
  };

  handleAnonymouseSignup = async () => {
    const { user } = await AuthService.createAnonymousAccount();
    const dataToBeAdded = {
      uid: user.uid,
      ...defaultUserInfo,
    };
    await UserService.add(user.uid, dataToBeAdded);
    AuthService.setUserData(dataToBeAdded);
  };

  componentDidMount = async () => {
    window.addEventListener("resize", () => {
      return this.setState({ isMobile: checkWidth() });
    });
    if (this.props?.location?.state) {
      if (this.props.location.state.fromAnalysis) {
        console.log({ site: this.props.location.state.siteToEdit });
        this.fetchUserType();

        return this.setState({
          siteToEdit: this.props.location.state.siteToEdit,
          editorStartingStep: 1,
          rendering: asideMenuItems.CREATE_SITE,
        });
      }
    }
    console.log("isAuth ", AuthService.isAuth());
    if (AuthService.isAuth() && AuthService.isAuthLocally()) {
      this.setState({
        rendering: asideMenuItems.CREATE_SITE,
      });
    } else {
      console.log("Creating account");
      await this.handleAnonymouseSignup();
      this.setState({
        rendering: asideMenuItems.CREATE_SITE,
      });
    }
  };
}
export default withRouter(Home);
