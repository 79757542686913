import { db, storage } from "./../fire";
import to from "await-to-js";
import Randomstring from "randomstring";
import Firebase from "firebase";
import moment from "moment";
import DatabaseConstants from "./../constants/Database";
import ConsumerScenarioConstants from "./../constants/Scenario";
import Axios from "axios";
import SupplierScenarioConstants from "./../constants/SupplyScenario";
import SiteConstants from "./../constants/Site";
import Api from "./Api";
import Auth from "./Auth";
import SiteService from "./Site";
import AuthConstants from "../constants/Auth";
import { getRandomColor } from "../utils/Utils";
import CollectiveSitesService from "./CollectiveSites";
import UserService from "./User";
import { getDefaultValues } from "../store/defaultValues/DefaultValuesStore";
import i18n from "../config/i18n";
const ConsumerConstants = getDefaultValues(SiteConstants.type.CONSUMER);
class ScenarioService {
  constructor(props) {
    this.storageBasePhotovoltaicData = "/scenarios/config/pv";
    this.storageBaseEolianData = "/scenarios/config/eolian";
  }

  getAll = async () => {
    const docRef = db.collection(DatabaseConstants.COLLECTION_SCENARIOS);
    const [err, scenarios] = await to(docRef.get());
    if (err) {
      console.error(`Could not get user. Error : ${err.message}`);
      return null;
    }
    if (scenarios) return scenarios.docs.map((doc) => doc.data());
    return null;
  };

  update = async (scenarioId, data, recordModification = true) => {
    const refScenario = db
      .collection(DatabaseConstants.COLLECTION_SCENARIOS)
      .doc(scenarioId);
    if (recordModification) data._lastModified = moment(Date.now()).format();
    if (data.score) delete data.score;
    return refScenario.update(data);
  };

  updateKey = (scenId, key, value) => {
    const siteRef = db
      .collection(DatabaseConstants.COLLECTION_SCENARIOS)
      .doc(scenId);

    return siteRef.set({ [key]: value }, { merge: true });
  };

  getFileNamesByType = (type) => {
    switch (type) {
      case SiteConstants.type.CONSUMER:
        return Object.values(ConsumerConstants.consumerFiles).filter(
          (file) => file !== ConsumerConstants.consumerFiles.CONSO_DATA,
        );
      default:
    }
  };

  duplicateAllScenarios = async (siteId, newSiteId) => {
    const scenarios = await this.getSiteScenarios(siteId);
    if (scenarios.length >= 1)
      scenarios.map(async (scenario) => {
        let err, newId;
        [err, newId] = await to(this.add(newSiteId, scenario));
        if (err) return Promise.reject(err);
        await this.duplicateScenarioFile(scenario, newSiteId, newId);
      });
    return Promise.resolve();
  };
  getTechArrayByType = (type, technologies) => {
    switch (type) {
      case SiteConstants.type.CONSUMER:
        return technologies.filter(
          (tech) =>
            tech.type === ConsumerScenarioConstants.techTypes.PHOTOVOLTAIC.id,
        );
      default:
    }
  };

  uploadFile = async (
    siteId,
    scenId,
    payload,
    string = null,

    onStateChange = () => {},
  ) => {
    return new Promise((resolve, reject) => {
      const fileRef = storage.ref(`Sites/${siteId}/${scenId}/Data/${string}`);
      const uploadTask = fileRef.put(payload);
      if (payload == null) return reject(new Error("Blob is null"));
      uploadTask.on(
        Firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => onStateChange(snapshot),
        (err) => reject(err),
        () =>
          uploadTask.snapshot.ref.getDownloadURL().then((url) => resolve(url)),
      );
    });
  };

  getFileDownloadURL = async (siteId, file, newScenarioId) => {
    const fileRef = storage.ref(
      `Sites/${siteId}/${newScenarioId}/Data/${file}`,
    );
    return fileRef
      .getDownloadURL()
      .then((url) => {
        return url;
      })
      .catch((e) => console.log(e));
  };

  checkIfScenarioHasFiles = async (siteId, scenario) => {
    console.log({ siteId, scenarioId: scenario });
    const folderRef = storage.ref(`Sites/${siteId}/${scenario}/Data/`);
    return folderRef
      .listAll()
      .then((dir) => {
        return dir.items.length > 0;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getFileNameWithExtension = (file) => {
    let name = file.name.split("/");
    return name[name.length - 1];
  };
  duplicateScenarioFile = async ({ siteId, id }, siteType, newScenarioId) => {
    const scenario = await this.getById(newScenarioId);
    const scenarioHasFiles = await this.checkIfScenarioHasFiles(siteId, id);

    if (!scenarioHasFiles) {
      console.log("Scenario has no files");
      return Promise.resolve();
    }

    console.log("scenairo has files");
    // console.log({ siteId, id, siteType, newScenarioId });
    const token = await Api.getToken();

    const res = await Axios.post(
      AuthConstants.URL_DUPLICATE_FILE,
      {
        siteId,
        scenarioId: id,
        to: newScenarioId,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `${token}`,
        },
      },
    );
    let files = res.data?.files?.[0];
    console.log({ files });
    if (files) {
      files = files.map((file) => this.getFileNameWithExtension(file));
      let fileArray = [];
      Promise.all(
        files.map(async (file) => {
          return this.getFileDownloadURL(siteId, file, newScenarioId).then(
            (url) => {
              if (url) {
                fileArray.push({ url, file });
              }
            },
          );
        }),
      ).then(() => {
        return this.buildTechnologyObjectFromFiles(
          scenario,
          fileArray,
          siteType,
        ).then(() =>
          console.log("technology object was built from files: ", fileArray),
        );
      });
    }
  };

  buildTechnologyObjectFromFiles = async (scenario, fileArray, siteType) => {
    if (fileArray.length === 0) return;
    let scenarioBuffer = { ...scenario };
    switch (siteType) {
      case SiteConstants.type.CONSUMER:
        scenarioBuffer.technologies = scenarioBuffer.technologies
          .map((t) => {
            return t.type ===
              ConsumerScenarioConstants.techTypes.PHOTOVOLTAIC.id
              ? {
                  ...t,
                  settings: {
                    ...t.settings,
                    pvPowers:
                      fileArray.find(
                        (f) =>
                          f.file === ConsumerConstants.consumerFiles.PV_PROD,
                      )?.url ?? "",
                    pvTMY:
                      fileArray.find(
                        (f) =>
                          f.file === ConsumerConstants.consumerFiles.PV_TMY,
                      )?.url ?? "",
                  },
                }
              : t;
          })
          .map((t) =>
            t.type === ConsumerScenarioConstants.techTypes.EOLIAN.id
              ? {
                  ...t,
                  settings: {
                    ...t.settings,
                    windTurbinePowers:
                      fileArray.find(
                        (f) =>
                          f.file === ConsumerConstants.consumerFiles.WT_PROD,
                      )?.url ?? "",
                    windTurbineTMY:
                      fileArray.find(
                        (f) =>
                          f.file === ConsumerConstants.consumerFiles.WT_TMY,
                      )?.url ?? "",
                  },
                }
              : t,
          );
        break;
    }
    await this.update(scenario.id, scenarioBuffer);
    return Promise.resolve();
  };

  duplicate = async (scenarioId) => {
    let err, scenario, newId;
    [err, scenario] = await to(this.getById(scenarioId));
    if (err) return Promise.reject(err);
    scenario.name = `${i18n.t("copyOF")} ${scenario.name}`;
    scenario.color = getRandomColor();
    delete scenario.optimizationResults;
    scenario.technologies.map((tech) => {
      if (tech.type === SupplierScenarioConstants.techTypes.PHOTOVOLTAIC.id) {
        tech.settings.pvPowers = "";
        tech.settings.pvTMY = "";
        tech.settings.pvPpa = "";
      }
      if (tech.type === SupplierScenarioConstants.techTypes.EOLIAN.id) {
        tech.settings.windTurbinePpa = "";
        tech.settings.windTurbineTMY = "";
        tech.settings.windTurbinePowers = "";
      }
    });
    [err, newId] = await to(this.add(scenario.siteId, scenario));

    if (!err) return newId;
  };

  getOutputFiles = async (siteId, scenId) => {
    let files = [
      "solution.json",
      "Powers1.csv",
      "Powers10.csv",
      "Powers10no.csv",
      "Powers1no.csv",
      "optimizationResults.json",
    ];

    return await Promise.all(
      files.map((file) => {
        const fileRef = storage.ref(
          `Sites/${siteId}/${scenId}/Results/${file}`,
        );
        // const fileRef = storage.ref(`testOptimOutput/${file}`);
        return fileRef
          .getDownloadURL()
          .then((url) => url)
          .catch((err) => {
            return Promise.reject(err);
            // console.error(`File ${file} not found : `, err);
            // Here you can handle the error for individual download
          });
      }),
    )
      .then((aResponses) => {
        // This will be called if all promises are resolved. aResponses contain all download url
        // console.log("All files were found :", aResponses);

        return Promise.resolve(aResponses);
      })
      .catch((err) => {
        return Promise.resolve([]);
        // This will be called if any of the download path is incorrect, with the error of first promise which fails
      });
  };

  getSupplierOutputFiles = async (siteId, scenId) => {
    let files = ["solution.json", "Powers1.csv", "Powers10.csv"];

    return await Promise.all(
      files.map((file) => {
        const fileRef = storage.ref(
          `Sites/${siteId}/${scenId}/Results/${file}`,
        );
        // const fileRef = storage.ref(`testOptimOutput/${file}`);
        return fileRef
          .getDownloadURL()
          .then((url) => url)
          .catch((err) => {
            return Promise.reject(err);
            // console.error(`File ${file} not found : `, err);
            // Here you can handle the error for individual download
          });
      }),
    )
      .then((aResponses) => {
        // This will be called if all promises are resolved. aResponses contain all download url
        // console.log("All files were found :", aResponses);

        return Promise.resolve(aResponses);
      })
      .catch((err) => {
        return Promise.resolve([]);
        // This will be called if any of the download path is incorrect, with the error of first promise which fails
      });
  };

  getSupplierInputFiles = async (siteId, file) => {
    const fileRef = storage.ref(`Sites/${siteId}/${file}`);
    // const fileRef = storage.ref(`testOptimOutput/${file}`);
    return fileRef
      .getDownloadURL()
      .then((url) => Promise.resolve(url))
      .catch((err) => {
        return Promise.reject(err);
      });
  };

  getById = (scenarioId) =>
    db
      .collection(DatabaseConstants.COLLECTION_SCENARIOS)
      .doc(scenarioId)
      .get()
      .then((doc) => (doc.exists ? { ...doc.data() } : null));
  getSiteScenarios = async (siteId) => {
    let err,
      data = null;
    // console.log("hello siteId", siteId);
    [err, data] = await to(
      db
        .collection(DatabaseConstants.COLLECTION_SCENARIOS)
        .where("siteId", "==", siteId)
        .get(),
    );
    if (err) return Promise.reject(err);

    return Promise.resolve(data.docs.map((doc) => doc.data()));
  };

  add = async (siteId = null, data) => {
    const refScenario = db
      .collection(DatabaseConstants.COLLECTION_SCENARIOS)
      .doc();
    let timestamp = moment(Date.now()).format();
    data.id = refScenario.id;
    data.siteId = siteId;
    data._createdAt = timestamp;
    if (data.score) delete data.score;
    refScenario.set(data);
    return refScenario.id;
  };
  deleteFilesOnOptim = async (siteId, scenId) => {
    const files = [
      "solution.json",
      "Powers1.csv",
      "Powers10.csv",
      "Finance.csv",
      "MixEnergétique.json",
      "Powers1no.csv",
      "Powers10no.csv",
    ];
    files.map((file) => {
      let desertRef = storage.ref(`Sites/${siteId}/${scenId}/Results/${file}`);

      return desertRef.delete().catch((e) => e);
    });
    this.update(scenId, {
      "optimizationResults.files.solutions": "",
      "optimizationResults.files.powers1": "",
      "optimizationResults.files.powers10": "",

      "optimizationResults.files.solutionsFinance": "",
      "optimizationResults.files.powers1no": "",
      "optimizationResults.files.powers10no": "",
    });
    return;
  };

  removeById = async (scenarioId) => {
    return db
      .collection(DatabaseConstants.COLLECTION_SCENARIOS)
      .doc(scenarioId)
      .delete();
  };

  removeDefaultScenarioFromUsers = async (scenarioId) => {
    //Removing entry from all users with the same default scenarios
    const users = await UserService.findUsersWithDefaultScenario(scenarioId);
    console.log({ usersWithScenarios: users });
    users.map(async (user) =>
      UserService.update(user.uid, {
        defaultScenarios: user.defaultScenarios.filter(
          (scenId) => scenId !== scenarioId,
        ),
      }),
    );
  };

  remove = async (scenarioId) => {
    let err,
      scenario,
      site = null;

    [err, scenario] = await to(this.getById(scenarioId));

    if (err) {
      console.error(
        `Fail to remove scenario, could not get scenario ${scenarioId}! ${err.message}`,
      );
      return Promise.reject(err);
    }
    //If ACC, CollectiveSiteService must be called
    if (scenario?.optim_params?.paramsOptimization.conColl) {
      [err, site] = await to(CollectiveSitesService.getById(scenario.siteId));
    } else {
      [err, site] = await to(SiteService.getById(scenario.siteId));
    }
    if (err) {
      console.error(
        `Fail to remove scenario, could not get scenario site ${scenario.siteId}! ${err.message}`,
      );
      return Promise.reject(err);
    }
    if (site == null) {
      console.warn("Could not find site of scenario when removing scenario.");
      return Promise.resolve();
    }

    return db
      .collection(DatabaseConstants.COLLECTION_SCENARIOS)
      .doc(scenarioId)
      .delete();
  };

  deleteScenarioFiles = async (siteId, scenId) => {
    const files = [
      "pvPowers.csv",
      "pvPpa.csv",
      "pvTMY.csv",
      "windTurbinePpa.csv",
      "windTurbineTMY.csv",
    ];
    files.map(async (file) => {
      let desertRef = storage.ref(`Sites/${siteId}/${scenId}/Data/${file}`);
      await to(Api.deleteScenarioFiles(siteId, scenId, file));
      return desertRef.delete().catch((e) => {
        console.log("Fichier non trouvé :", file);
      });
    });
    return;
  };

  deleteSpecificFile = async (siteId, scenId, file) => {
    let desertRef = storage.ref(`Sites/${siteId}/${scenId}/Data/${file}`);
    return desertRef.delete().catch((e) => {});
  };

  uploadPhotovoltaicConfigData = async (
    blob = null,
    existingFileName,
    onStateChange = () => {},
  ) =>
    new Promise((resolve, reject) => {
      const fileName =
        existingFileName == null ? Randomstring.generate(30) : existingFileName;
      const fileRef = storage.ref(
        `${this.storageBasePhotovoltaicData}/${Auth.getUserId()}/${fileName}`,
      );
      const taskUpload = fileRef.put(blob);

      if (blob == null) return reject(new Error("The blob is null."));
      taskUpload.on(
        Firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => onStateChange(snapshot),
        (err) => reject(err),
        () =>
          taskUpload.snapshot.ref
            .getDownloadURL()
            .then((url) => resolve([url, fileName])),
      );
    });

  uploadEolianConfigData = async (
    blob = null,
    existingFileName,
    onStateChange = () => {},
  ) =>
    new Promise((resolve, reject) => {
      const fileName =
        existingFileName == null ? Randomstring.generate(30) : existingFileName;
      const fileRef = storage.ref(
        `${this.storageBaseEolianData}/${Auth.getUserId()}/${fileName}`,
      );
      const taskUpload = fileRef.put(blob);

      if (blob == null) return reject(new Error("The blob is null."));
      taskUpload.on(
        Firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => onStateChange(snapshot),
        (err) => reject(err),
        () =>
          taskUpload.snapshot.ref
            .getDownloadURL()
            .then((url) => resolve([url, fileName])),
      );
    });

  uploadProducerParamsFile = async (
    siteId,
    scenId,
    payload,
    string = null,

    onStateChange = () => {},
  ) => {
    return new Promise((resolve, reject) => {
      const fileRef = storage.ref(`Sites/${siteId}/${scenId}/Data/${string}`);

      const uploadTask = fileRef.put(payload);
      uploadTask.on(
        Firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => onStateChange(snapshot),
        (err) => reject(err),
        () =>
          uploadTask.snapshot.ref.getDownloadURL().then((url) => resolve(url)),
      );
    });
  };

  uploadSupplierFinancialFile = async (
    siteId,
    scenId,
    payload,

    onStateChange = () => {},
  ) => {
    return new Promise((resolve, reject) => {
      const fileRef = storage.ref(
        `Sites/${siteId}/${scenId}/Results/Finance.csv`,
      );
      const uploadTask = fileRef.put(payload);
      if (payload == null) return reject(new Error("Blob is null"));
      uploadTask.on(
        Firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => onStateChange(snapshot),
        (err) => reject(err),
        () =>
          uploadTask.snapshot.ref.getDownloadURL().then((url) => resolve(url)),
      );
    });
  };

  uploadSupplierPrettyFile = async (
    siteId,
    scenId,
    payload,

    onStateChange = () => {},
  ) => {
    return new Promise((resolve, reject) => {
      const fileRef = storage.ref(
        `Sites/${siteId}/${scenId}/Results/MixEnergétique.json`,
      );
      const uploadTask = fileRef.put(payload);
      if (payload == null) return reject(new Error("Blob is null"));
      uploadTask.on(
        Firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => onStateChange(snapshot),
        (err) => reject(err),
        () =>
          uploadTask.snapshot.ref.getDownloadURL().then((url) => resolve(url)),
      );
    });
  };

  uploadProducerPpaConsumptionFile = async (
    siteId,
    scenId,
    payload,
    string = null,

    onStateChange = () => {},
  ) => {
    return new Promise((resolve, reject) => {
      const fileRef = storage.ref(`Sites/${siteId}/${scenId}/Data/${string}`);
      const uploadTask = fileRef.put(payload);
      if (payload == null) return reject(new Error("Blob is null"));
      uploadTask.on(
        Firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => onStateChange(snapshot),
        (err) => reject(err),
        () =>
          uploadTask.snapshot.ref.getDownloadURL().then((url) => resolve(url)),
      );
    });
  };

  removeUselessScenarios = async () => {
    let err,
      data = null;
    // console.log("hello siteId", siteId);
    [err, data] = await to(
      db
        .collection(DatabaseConstants.COLLECTION_SCENARIOS)
        .where("id", "==", null)
        .get()
        .delete(),
    );
    if (err) return Promise.reject(err);

    return Promise.resolve(data.docs.map((doc) => doc.data()));
  };

  setGraphComment = (scenarioData, { graphName, comment }) => {
    const siteRef = db
      .collection(DatabaseConstants.COLLECTION_SCENARIOS)
      .doc(scenarioData.id);

    return siteRef.set({ comments: { [graphName]: comment } }, { merge: true });
  };

  updateScenariosAfterSiteDeletion = async (subSite) => {
    console.log(`Deleting subSite ${subSite.id} | ${subSite.name}`);
    const collectiveSite = await SiteService.getCollectiveSiteById(
      subSite.collectiveSiteId,
    );
    const scenarios = await this.getSiteScenarios(collectiveSite.id);
    if (scenarios.length === 0)
      return Promise.resolve("No scenario, skipping step");
    return await Promise.all(
      scenarios.map(async (scenario) => {
        const newConfigSite = {};
        collectiveSite.sites.map((site) => {
          if (scenario.configSite[site]) {
            newConfigSite[site] = scenario.configSite[site];
          }
        });
        if (scenario?.optim_params?.paramsGrid) {
          await this.update(scenario.id, {
            ...scenario,
            configSite: newConfigSite,
            optim_params: {
              ...scenario.optim_params,
              paramsGrid: {
                ...scenario?.optim_params.paramsGrid,
                counters: scenario.optim_params.paramsGrid.counters.filter(
                  (counter) => counter.id !== subSite.id,
                ),
              },
            },
          });
        }
      }),
    );
  };

  getByCriterion = async (criterion, operator, value) => {
    let err,
      data = null;

    [err, data] = await to(
      db
        .collection(DatabaseConstants.COLLECTION_SCENARIOS)
        .where(criterion, operator, value)
        .get(),
    );
    if (err) return Promise.reject(err);
    return Promise.resolve(data.docs.map((doc) => doc.data()));
  };

  // C&I

  deleteAllScenariosFromSite = async (siteId) => {
    const scenarios = await this.getSiteScenarios(siteId);
    if (scenarios.length >= 1)
      scenarios.map(async (scenario) => await this.removeById(scenario.id));
  };
}

export default new ScenarioService();
