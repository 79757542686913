const green = "#9dcf56"; // green de Likewatt
const greenOpac = "rgba(158, 190, 90, 0.5)";
const lightGray = "rgba(201, 201, 201, 0.5)";
const blue = "#006276";
const warning = "#37b06e";
const dark = "rgb(36, 36, 36)";
const light = "#fafafa";
const optim = "#008a7d";
const danger = "#b93217";
const info = "hsl(207, 61%,  53%)";
const lightInfo = "#9ABDD9";
const mainIntermarche = "#c0b38a";
export {
  green,
  greenOpac,
  lightGray,
  blue,
  warning,
  dark,
  light,
  optim,
  danger,
  info,
  lightInfo,
};
