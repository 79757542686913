import React, { useEffect, useContext, useState } from "react";
import LocaleContext from "../../context/LocaleContext";
import { Loader } from "react-bulma-components/full";
import dayjs from "dayjs";
import loadable from "loadable-components";
import {
  isAllSitesAnalyzable,
  isAllSitesOptimizable,
  isSiteExtractingData,
  isSiteHasError,
  isSiteNeedUpdate,
  // isSiteIsDisabled,
  isSomeSitesExtractingData,
  isSomeSitesHasError,
  isSomeSitesNeedUpdate,
} from "../../helpers/Validators";
import classNames from "classnames";
import SiteService from "../../services/Site";
import ScenarioService from "../../services/Scenario";
import PLANS from "../../constants/Plans";
import { FaList, FaPollH, FaEdit, FaExclamationTriangle } from "react-icons/fa";
import { SiGooglemaps, SiGoogleanalytics } from "react-icons/si";
import { isSiteCompletelyConfigured } from "../../helpers/Validators";
import { CustomButton, StyledLoader } from "../CustomButton";
import { CustomButtonSecondary } from "../CustomButtonSecondary";
import { BsFillTrash3Fill } from "react-icons/bs";
import { ShowConsentFileStatus } from "./ConsentFileWarning";
import { extractEnedisErrorCode, formatErrorStatus } from "../../utils/Utils";
import styled from "styled-components";
import {
  SiteMetadata,
  SiteMetadataName,
  SiteMetadataAdress,
  Data,
  DownloadConsoData,
  ContainerButtons,
  SiteActionsContainer,
  ActionsButton,
} from "./HomeSiteComponents";

const ReportResult = loadable(() => import("../../components/AnalyzeResult"));

const ErrorMessages = ({ sites, forceUpdate, collectiveSite }) => {
  const { Translation } = useContext(LocaleContext);
  return sites
    .filter((site) => isSiteHasError(site))
    .map(({ name, automaticUpdateError, reportURL, enedisNumber }, index) => {
      if (!automaticUpdateError && !reportURL)
        return (
          <span key={index} style={{ display: "block" }}>
            {name} - <Translation>{(t) => t("faultyProject")}</Translation>
          </span>
        );

      return (
        <span key={index} style={{ display: "block" }}>
          {name} -{" "}
          {formatErrorStatus(extractEnedisErrorCode(automaticUpdateError))}
          {isSiteNeedUpdate({
            enedisNumber,
            automaticUpdateError,
            reportURL,
          }) && (
            <>
              &nbsp;
              <Translation>{(t) => t("clicking")}</Translation>
              <a
                href="#"
                onClick={() => forceUpdate(collectiveSite)}
                style={{
                  color: "red",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                <Translation>{(t) => t("refresh")}</Translation>
              </a>
            </>
          )}
        </span>
      );
    });
};
const EditorSiteForm = loadable(() =>
  import("../../components/Site/EditorSiteForm"),
);

const Site = styled.div`
  background-color: white;
  border: 1px solid rgba(128, 128, 128, 0.4);
  border-radius: ${(props) => props.radius || "8px"};
  display: flex;
  align-items: ${(props) => props.alignItems || "center"};
  justify-content: space-between;
  gap: 20px;
  margin: ${(props) => props.margin || "0 0 20px 0"};
  padding: ${(props) => props.padding || "20px"};
  position: relative;

  &::after {
    content: "";
    width: 2px;
    height: calc(100% + 18px);
    background-color: white;
    position: absolute;
    z-index: 10;
    left: -17px;
    top: -18px;
  }

  &:last-child::after {
    height: calc(50% + 18px);
  }

  &::before {
    content: "";
    --half-height: calc(100% / 2);
    width: 17px;
    height: 2px;
    background-color: white;
    position: absolute;
    z-index: 10;
    left: -17px;
    top: 50%;
  }
`;

const CollapseCollectiveSite = styled.div`
  background-color: #003b58;
  border-radius: 5px;
  border: 1px solid black;
  width: 250%;
  padding: 10px;
  position: absolute;
`;

const TogglerCollapse = styled.div`
  color: white;
  font-size: 1rem;
  font-weight: bold;
  line-height: 15px;
  cursor: pointer;

  i {
    font-size: 0.85rem !important;

    &:hover {
      color: white !important;
      transform: none !important;
    }
  }
`;

const CollapseBody = styled.div`
  position: relative;
`;

const SiteEnedisNumber = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.widthEnedisNumber || ""};
  height: 100%;
  font-size: ${(props) => props.smallSize || "1rem"};
`;

const SiteReportUrl = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  width: ${(props) => props.widthSiteReportUrl || ""};
`;

export const SiteListItem = ({
  site,
  handleShowEditSite,
  handleDeletesubSite,
  isCollectiveSiteEditorMode = false,
  fromEditor = false,
  radius,
  alignItems,
  padding,
  margin,
  widthMetadata,
  isMargin,
  colorName,
  fontSize,
  fontWeight,
  widthEnedisNumber,
  smallSize,
  marginTop,
  widthSiteReportUrl,
  marginLeft,
  warningMargin,
}) => {
  const { name, enedisNumber, reportURL } = site;
  const { Translation, i18n } = useContext(LocaleContext);
  console.log({ name: site.name, site });
  return (
    <Site
      className={classNames({
        "collective-site-editor": isCollectiveSiteEditorMode,
      })}
      radius={radius}
      alignItems={alignItems}
      padding={padding}
      margin={margin}
    >
      <SiteMetadata widthMetadata={widthMetadata} isMargin={isMargin}>
        <SiteMetadataName
          style={{
            color: name === "Site en paramètrage" ? "red" : "",
          }}
          colorName={colorName}
          fontSize={fontSize}
          fontWeight={fontWeight}
        >
          {name}{" "}
        </SiteMetadataName>
        {
          <ShowConsentFileStatus
            site={site}
            smallSize={smallSize}
            warningMargin={warningMargin}
          />
        }
      </SiteMetadata>
      {site.enedisData ? (
        <SiteEnedisNumber
          smallSize={smallSize}
          widthEnedisNumber={widthEnedisNumber}
        >
          Compteur n° {enedisNumber || ""}
        </SiteEnedisNumber>
      ) : site.profile ? (
        <SiteEnedisNumber>Profil type</SiteEnedisNumber>
      ) : (
        <SiteEnedisNumber>Fichier .csv</SiteEnedisNumber>
      )}
      <SiteReportUrl widthSiteReportUrl={widthSiteReportUrl}>
        {isCollectiveSiteEditorMode && (
          <>
            <CustomButton
              icon={<FaEdit />}
              padding="6px 20px 6px 10px"
              onClick={() => handleShowEditSite(site)}
              disabled={isSiteExtractingData(site)}
            >
              <Translation>{(t) => t("settings")}</Translation>
            </CustomButton>
          </>
        )}
        {fromEditor && (
          <CustomButtonSecondary
            padding="6px 12px 6px 2px"
            onClick={() => handleDeletesubSite(site)}
            icon={<BsFillTrash3Fill />}
            hoverBackgroundColor="red"
            hoverColor="white"
            // disabled={isSiteIsDisabled(site)}
          >
            {/* {isSiteIsDisabled(site) ? (
                  <CustomHoverPopUp
                    content={i18n.t(`captions.navigation.disabledConsent`)}
                  > */}
            <Translation>{(t) => t("delete")}</Translation>
            {/* </CustomHoverPopUp>
                ) : (
                  <Translation>{(t) => t("delete")}</Translation>
                )} */}
          </CustomButtonSecondary>
        )}
        {reportURL && (
          // !isSiteIsDisabled(site) &&
          <DownloadConsoData
            href={reportURL}
            target="_blank"
            rel="noopener noreferrer"
            smallSize={smallSize}
            marginTop={marginTop}
            marginLeft={marginLeft}
          >
            <Translation>{(t) => t("downloadCurrentHistory")}</Translation>
            <i className="fa fa-download"></i>
          </DownloadConsoData>
        )}
        {isSiteExtractingData(site) && (
          <div className="site-actions has-text-right">
            <p className="load-message">
              <Loader className="fetching" />
              <Translation>{(t) => t("extractingData")}</Translation>
            </p>
          </div>
        )}
      </SiteReportUrl>
    </Site>
  );
};

const CollectiveSiteCollapse = (props) => {
  const { i } = props;
  const [expanded, setExpanded] = useState(false);
  const { Translation } = useContext(LocaleContext);
  const deletesubSiteFromDb = async (subSite) => {
    props.updateCollectiveSiteListOfSites(props.collectiveSite.id, subSite);
    await SiteService.deleteSubSite(subSite);
    await ScenarioService.updateScenariosAfterSiteDeletion(subSite);
  };
  return (
    <CollapseCollectiveSite style={{ zIndex: 100 - i }}>
      <div>
        <TogglerCollapse onClick={() => setExpanded(!expanded)}>
          <i className={`fa fa-caret-${expanded ? "down" : "right"}`} />
          &nbsp;<Translation>{(t) => t("seeListOfCounters")}</Translation>
        </TogglerCollapse>
        {expanded && (
          <CollapseBody>
            {props.collectiveSite.sites.map((site, i) => (
              <SiteListItem
                key={i}
                site={site}
                handleDeletesubSite={deletesubSiteFromDb}
                radius="5px"
                alignItems="flex-start"
                padding="20px 15px"
                margin="10px 0 0 20px"
                widthMetadata="100%"
                isMargin="0"
                fontWeight="500"
                colorName="#9dcf56"
                widthEnedisNumber="20%"
                smallSize="0.85rem"
                marginTop="0"
                widthSiteReportUrl="20%"
                marginLeft="0"
                warningMargin="5px"
              />
            ))}
          </CollapseBody>
        )}
      </div>
    </CollapseCollectiveSite>
  );
};

const CollectiveSiteListItem = (props) => {
  const {
    selectedSites,
    handleCheckboxClick,
    widthMetadata,
    marginTop,
    paddingTop,
    addressMargin,
  } = props;

  const { Translation, i18n } = useContext(LocaleContext);
  const [collectiveSite, setCollectiveSite] = useState(props.collectiveSite);
  const [i, setI] = useState(props.i);

  const [allSitesAnalyzable, setAllSitesAnalyzable] = useState(
    isAllSitesAnalyzable(props.collectiveSite.sites),
  );
  const [allSitesOptimizable, setAllSitesOptimizable] = useState(
    isAllSitesOptimizable(props.collectiveSite.sites),
  );
  const [someSitesExtractingData, setSomeSitesExtractingData] = useState(
    isSomeSitesExtractingData(props.collectiveSite.sites),
  );
  const [someSitesHasError, setSomeSitesHasError] = useState(
    isSomeSitesHasError(props.collectiveSite.sites),
  );
  const [someSitesNeedUpdate, setSomeSitesNeedUpdate] = useState(
    isSomeSitesNeedUpdate(props.collectiveSite.sites),
  );
  const { handleDeleteSite, forceUpdate, folders, userLicense } = props;

  const handleButtonClick = (e) => props.handleClick(e, props.collectiveSite);

  useEffect(() => {
    const { collectiveSite } = props;
    setCollectiveSite(collectiveSite);
    setI(props.i);
    setAllSitesAnalyzable(isAllSitesAnalyzable(collectiveSite.sites));
    setAllSitesOptimizable(isAllSitesOptimizable(collectiveSite.sites));
    setSomeSitesExtractingData(isSomeSitesExtractingData(collectiveSite.sites));
    setSomeSitesHasError(isSomeSitesHasError(collectiveSite.sites));
    setSomeSitesNeedUpdate(isSomeSitesNeedUpdate(collectiveSite.sites));

    props.addConsentKeys(collectiveSite.sites);
    props.checkConsent(collectiveSite.sites);
  }, [props.collectiveSite, props.i]);

  return (
    <>
      <tr>
        <td style={{ verticalAlign: "middle", margin: "auto" }}>
          <input
            checked={!!selectedSites.find((s) => s.id === collectiveSite.id)}
            onChange={() => handleCheckboxClick(collectiveSite)}
            type="checkbox"
            style={{ width: "20px", height: "20px" }}
          ></input>
        </td>
        <td>
          <SiteMetadata widthMetadata={widthMetadata}>
            <SiteMetadataName
              style={{
                color:
                  collectiveSite.name === "Site en paramètrage" ? "red" : "",
              }}
            >
              {collectiveSite.name}{" "}
              {/* <div style={{ display: "flex", alignItems: "center" }}>
                {collectiveSite.name}{" "}
              </div> */}
            </SiteMetadataName>
            {/* {process.env.REACT_APP_ENV !== "production" && (
              <div>{collectiveSite.id}</div>
            )} */}
            <SiteMetadataAdress addressMargin={addressMargin}>
              <span>
                <SiGooglemaps style={{ marginRight: "5px" }} />
                {collectiveSite.address.split(",")[0].trim()}
              </span>
              <span>
                {
                  collectiveSite.address.split(
                    collectiveSite.address.split(", ")[0] + ",",
                  )[1]
                }
              </span>
            </SiteMetadataAdress>
            <div
              style={{
                position: "relative",
                display: "flex",
                // zIndex: 9999 - i,
              }}
            >
              <CollectiveSiteCollapse {...props} />
            </div>
            <br />
            <br />
          </SiteMetadata>
        </td>
        <td>
          <SiteMetadata widthMetadata={widthMetadata}>
            <Data marginTop={marginTop}>
              {folders.length > 0 && (
                <>
                  {folders.find((f) => f.id === collectiveSite?.folder)
                    ?.displayName || "Non renseigné"}
                </>
              )}
            </Data>
          </SiteMetadata>
        </td>
        <td>
          <SiteMetadata widthMetadata={widthMetadata}>
            <Data marginTop={marginTop}>
              {collectiveSite?._lastModified ? (
                <>
                  {dayjs(collectiveSite._lastModified).format("DD/MM/YYYY")}
                  <br />
                  {dayjs(collectiveSite._lastModified).format("HH:mm")}
                </>
              ) : (
                "N/A"
              )}
            </Data>
          </SiteMetadata>
        </td>
        <td>
          <SiteMetadata widthMetadata={widthMetadata}>
            <Data marginTop={marginTop}>
              {collectiveSite?._createdAt ? (
                <>
                  {dayjs(collectiveSite._createdAt).format("DD/MM/YYYY")}
                  <br />
                  {dayjs(collectiveSite._createdAt).format("HH:mm")}
                </>
              ) : (
                "N/A"
              )}
            </Data>
          </SiteMetadata>
        </td>
        <td>
          <div
            className="site-metadata"
            style={{
              marginTop: "20%",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></div>
        </td>
        <td style={{ verticalAlign: "middle" }}>
          <ContainerButtons paddingTop={paddingTop}>
            <div className="update-button-container">
              {collectiveSite.sites.length > 0 && someSitesExtractingData && (
                <div className="site-actions ">
                  <p
                    style={{ color: "#3273dc" }}
                    className="site-actions t content"
                  >
                    {/* <Loader className="fetching" />{" "} */}
                    <Translation>{(t) => t("extractingData")}</Translation>
                  </p>
                </div>
              )}

              {collectiveSite.sites.length > 0 &&
                !someSitesExtractingData &&
                someSitesHasError &&
                collectiveSite.sites.length > 0 && (
                  <div className="error-message">
                    <span>
                      <ErrorMessages
                        sites={collectiveSite.sites}
                        forceUpdate={forceUpdate}
                        collectiveSite={collectiveSite}
                      />
                    </span>
                  </div>
                )}
            </div>
            <SiteActionsContainer>
              <ActionsButton
                bgBtn="#003b58"
                colorBtn="white"
                hoverBgBtn="white"
                hoverColorBtn="#003b58"
                onClick={handleButtonClick}
                name="analyze"
                disabled={
                  !collectiveSite.sites.length > 0 || !allSitesAnalyzable
                  // || hasDisabledSite(collectiveSite.sites)
                }
              >
                <SiGoogleanalytics style={{ fontSize: "1rem" }} />
                {/* {hasDisabledSite(collectiveSite.sites) ? (
                  <CustomHoverPopUp
                    content={i18n.t(`captions.navigation.disabledConsent`)}
                  > */}
                {i18n.t("analyze")}
                {/* </CustomHoverPopUp>
                ) : (
                  i18n.t("analyze")
                )} */}
              </ActionsButton>
              <ActionsButton
                bgBtn="#003b58"
                colorBtn="white"
                hoverBgBtn="white"
                hoverColorBtn="#003b58"
                onClick={handleButtonClick}
                disabled={
                  !collectiveSite.sites.length > 0 || !allSitesOptimizable
                  // || hasDisabledSite(collectiveSite.sites)
                }
                onMouseOver={() => {
                  ReportResult.load();
                }}
                name="scenarios"
              >
                <FaList style={{ fontSize: "1rem" }} />
                {/* {hasDisabledSite(collectiveSite.sites) ? (
                  <CustomHoverPopUp
                    content={i18n.t(`captions.navigation.disabledConsent`)}
                  > */}
                {i18n.t("pages.scenarios")}
                {/* </CustomHoverPopUp>
                ) : (
                  i18n.t("pages.scenarios")
                )} */}
              </ActionsButton>
              <ActionsButton
                bgBtn="#003b58"
                colorBtn="white"
                hoverBgBtn="white"
                hoverColorBtn="#003b58"
                onClick={handleButtonClick}
                disabled={
                  !collectiveSite.sites.length > 0 || !allSitesAnalyzable
                  // || hasDisabledSite(collectiveSite.sites)
                }
                name="results"
              >
                <FaPollH style={{ fontSize: "1rem" }} />{" "}
                {/* {hasDisabledSite(collectiveSite.sites) ? (
                    <CustomHoverPopUp
                      content={i18n.t(`captions.navigation.disabledConsent`)}
                    > */}
                {i18n.t("pages.results")}
                {/* </CustomHoverPopUp>
                  ) : (
                    i18n.t("pages.results")
                  )} */}
              </ActionsButton>
            </SiteActionsContainer>
            <SiteActionsContainer>
              <ActionsButton
                onClick={handleButtonClick}
                onMouseOver={() => {
                  EditorSiteForm.load();
                }}
                name="edit"
                editable={isSiteCompletelyConfigured(collectiveSite)}
              >
                <FaEdit style={{ fontSize: "1rem" }} />
                <Translation>{(t) => t("settings")}</Translation>
              </ActionsButton>
              <ActionsButton
                hoverBorderColor="red"
                hoverBgBtn="red"
                onClick={() => handleDeleteSite(collectiveSite.id)}
                name="delete"
                disabled={
                  userLicense === PLANS().DEMO.toUpperCase() ||
                  userLicense === PLANS().LEADS.name
                  // || hasDisabledSite(collectiveSite.sites)
                }
              >
                <i
                  className={classNames("fa fa-trash", {
                    disabled:
                      userLicense === PLANS().DEMO.toUpperCase() ||
                      userLicense === PLANS().LEADS.name,
                    // ||  hasDisabledSite(collectiveSite.sites),
                  })}
                  title="Supprimer le site"
                ></i>{" "}
                {/* {hasDisabledSite(collectiveSite.sites) ? (
                  <CustomHoverPopUp
                    content={i18n.t(`captions.navigation.disabledConsent`)}
                  > */}
                <Translation>{(t) => t("delete")}</Translation>
                {/* </CustomHoverPopUp>
                ) : (
                  <Translation>{(t) => t("delete")}</Translation>
                )} */}
              </ActionsButton>
            </SiteActionsContainer>
          </ContainerButtons>
        </td>
      </tr>
      {/* <tr>
        <td colSpan={5}>
          <CollectiveSiteCollapse {...props} />
        </td>
      </tr> */}
    </>
  );
};

export default CollectiveSiteListItem;
