import Randomstring from "randomstring";
import moment from "moment";

class EnedisHelper {
  formatAreaOfTension = (areaOfTension) => {
    switch (areaOfTension) {
      case "BTSUP":
        return "BT>36 kVA";
      case "HTA":
        return "HTA";
      case "HTB":
        return "HTB1";
      case "BTINF":
        return "BT<36 kVA";
      default:
        break;
    }
  };

  getHoursBTINF = (range) => {
    let hours = [];
    let newRange = range
      .substr(3, range.length)
      .replace(/[()]/g, "")
      .replace(/[-]/g, ";")
      .split(";")
      .map((hour) =>
        parseInt(
          moment(hour, "HH[H]mm").add(30, "minutes").startOf("hours").hours(),
        ),
      );
    for (let i = 0; i < newRange.length; i++) {
      if (i % 2 === 0)
        hours.push({
          id: Randomstring.generate(10),
          startTime: newRange[i],
          endTime: newRange[i + 1],
        });
    }
    return hours;
  };
  filterOffPeakHours = (array) => {
    let hours = [];
    hours.push({
      id: Randomstring.generate(10),
      startTime: parseInt(array.periodesHoraires._text.slice(0, 2)),
      endTime: parseInt(array.periodesHoraires._text.slice(8, 10)),
    });
    return hours;
  };
  getRatesOption = (option) => {
    switch (option) {
      case "BTSUPLU4":
        return "LU";
      case "BTSUPCU4":
        return "CU";
      case "HTACU5":
        return "CU pointe fixe";
      case "HTALU5":
        return "LU pointe fixe";
      case "HTACUPM5":
        return "CU pointe mobile";
      case "HTALUPM5":
        return "LU pointe mobile";
      case "BTINFCUST":
        return "CU";
      case "BTINFCU4":
        return "CU4";
      case "BTINFMU4":
        return "MU4";
      case "BTINFLU":
        return "LU";
      case "BTINFMUDT":
        return "MUDT";
      default:
        return "CU";
    }
  };
  getPurchaseContractType = (type) => {
    switch (type) {
      case "C1":
      case "P1":
        return "CARD/T";
      case "C2":
      case "C3":
      case "C4":
      case "C5":
      case "P2":
      case "P3":
      case "P4":
      case "P5":
        return "Contrat unique";
      default:
        break;
    }
  };
  getSubscribedPowers = (array, areaOfTension) => {
    if (areaOfTension !== "BT>36 kVA" && areaOfTension !== "BT<36 kVA")
      return [
        {
          HCB: array.find((e) => e._attributes.code === "HCE").puissance.valeur
            ._text,
          HCH: array.find((e) => e._attributes.code === "HCH").puissance.valeur
            ._text,
          HPB: array.find((e) => e._attributes.code === "HPE").puissance.valeur
            ._text,
          HPH: array.find((e) => e._attributes.code === "HPH").puissance.valeur
            ._text,
          PTE: array.find(
            (e) => e._attributes.code === "PTE" || e._attributes.code === "PM",
          ).puissance.valeur._text,
          id: Randomstring.generate(10),
        },
      ];
    else
      return [
        {
          HCB: array.find((e) => e._attributes.code === "HCE").puissance.valeur
            ._text,
          HCH: array.find((e) => e._attributes.code === "HCH").puissance.valeur
            ._text,
          HPB: array.find((e) => e._attributes.code === "HPE").puissance.valeur
            ._text,
          HPH: array.find((e) => e._attributes.code === "HPH").puissance.valeur
            ._text,
          PTE: array.find((e) => e._attributes.code === "HPH").puissance.valeur
            ._text,
          id: Randomstring.generate(10),
        },
      ];
  };
}

export default new EnedisHelper();
