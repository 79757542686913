import React from "react";
import styled from "styled-components";
import { Button, Modal } from "react-bulma-components/full";
import { capitalize } from "../../utils/Utils";

const EmphasisSpan = styled.span`
  font-weight: bold;
`;

export default function ModalRemoveScenario(props) {
  const { show, onClose, onConfirm, action = "", message, target = [] } = props;

  return (
    <Modal show={show} onClose={onClose} className="optimization-config-modal">
      <Modal.Card>
        <Modal.Card.Head showClose={false}>
          <EmphasisSpan>{capitalize(action)}</EmphasisSpan>
        </Modal.Card.Head>
        <Modal.Card.Body>
          <p>
            Êtes-vous sûr de vouloir {action} les éléments suivants ? <br />
            <br />
            <ul>
              {target &&
                target.length > 0 &&
                target.map((target, index) => (
                  <li key={index}>
                    <EmphasisSpan>{target.name}</EmphasisSpan>
                  </li>
                ))}
            </ul>
            <br />
            <b>&#9888;&#65039; {message}</b>
          </p>
        </Modal.Card.Body>
        <Modal.Card.Foot style={{ justifyContent: "flex-end" }}>
          <Button color="danger" onClick={() => onConfirm(target)}>
            Oui {action}
          </Button>
          <Button onClick={onClose}>Annuler</Button>
        </Modal.Card.Foot>
      </Modal.Card>
    </Modal>
  );
}
