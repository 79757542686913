import SiteConstants from "../constants/Site";

export const calcCoefficient = (site, sales = false) => {
  const {
    purchaseContractType,
    purchaseAreaOfTension,
    purchaseMeterOwner,
    ratesOption,
    subscribedPowers,
  } = site;
  let TCSPE,
    TCTA,
    COEFCG,
    COEFCC,
    COEFCI,
    COEFCER,
    BI,
    CI,
    D = 0;
  // console.log("Number isInterger", Number.isInteger("4"));

  console.log({ site });
  let newSub = [...subscribedPowers];

  delete newSub[0].endDate;
  delete newSub[0].startDate;
  delete newSub[0].id;

  /** Calculation of TCSPE  */
  TCSPE = 2.25;
  switch (purchaseAreaOfTension) {
    case SiteConstants.purchaseAreaOfTension["HTA"]:
    case SiteConstants.purchaseAreaOfTension["BT>36 kVA"]:
    case SiteConstants.purchaseAreaOfTension["BT<36 kVA"]:
      TCTA = 21.93;
      break;
    default:
      TCTA = 10.14;
  }
  /** Calculation of others */
  switch (purchaseAreaOfTension) {
    case SiteConstants.purchaseAreaOfTension["BT<36 kVA"]: {
      COEFCI = 0;
      COEFCER = 0;
      COEFCG = sales
        ? 24.36
        : purchaseContractType === SiteConstants.purchaseContractType["CARD/T"]
        ? 16.56
        : purchaseContractType ===
          SiteConstants.purchaseContractType["Contrat unique"]
        ? 15.48
        : 24.36;

      COEFCC = 19.92;
      BI =
        ratesOption === SiteConstants.ratesOption["CU"]
          ? [9.96, 9.96, 9.96, 9.96, 9.96] // array length should be 5
          : ratesOption === SiteConstants.ratesOption["LU"]
          ? [81.24, 81.24, 81.24, 81.24, 81.24]
          : ratesOption === SiteConstants.ratesOption["CU4"]
          ? [9, 9, 9, 9, 9]
          : ratesOption === SiteConstants.ratesOption["MU4"]
          ? [10.56, 10.56, 10.56, 10.56, 10.56]
          : ratesOption === SiteConstants.ratesOption["MUDT"]
          ? [12.24, 12.24, 12.24, 12.24, 12.24]
          : [];
      CI =
        ratesOption === SiteConstants.ratesOption["CU"]
          ? [4.37, 4.37, 4.37, 4.37, 4.37] // array length should be 5
          : ratesOption === SiteConstants.ratesOption["LU"]
          ? [1.1, 1.1, 1.1, 1.1, 1.1]
          : ratesOption === SiteConstants.ratesOption["CU4"]
          ? [6.67, 6.67, 4.56, 1.43, 0.88]
          : ratesOption === SiteConstants.ratesOption["MU4"]
          ? [6.12, 6.12, 4.24, 1.39, 0.87]
          : ratesOption === SiteConstants.ratesOption["MUDT"]
          ? [4.47, 4.47, 3.16, 4.47, 3.16]
          : [];
      D = 0;
      break;
    }
    case SiteConstants.purchaseAreaOfTension["BT>36 kVA"]: {
      COEFCI = 0;
      COEFCER = 2.16;
      COEFCG = sales
        ? 329.64
        : purchaseContractType === SiteConstants.purchaseContractType["CARD/T"]
        ? 229.8
        : purchaseContractType ===
          SiteConstants.purchaseContractType["Contrat unique"]
        ? 199.8
        : 329.64;

      COEFCC = 255.84;
      BI =
        ratesOption === SiteConstants.ratesOption["CU"]
          ? [14.67, 14.67, 11.29, 10.59, 9.57] // array length should be 5
          : ratesOption === SiteConstants.ratesOption["LU"]
          ? [24.55, 24.55, 15.46, 13.44, 10.82]
          : [];
      CI =
        ratesOption === SiteConstants.ratesOption["CU"]
          ? [5.63, 5.63, 4.11, 2.37, 1.72]
          : ratesOption === SiteConstants.ratesOption["LU"]
          ? [4.76, 4.76, 3.63, 2.16, 1.5]
          : [];
      D = 11.21; // à calculer par heure !
      break;
    }
    case SiteConstants.purchaseAreaOfTension["HTA"]: {
      COEFCI = 0;
      COEFCER = 2.2;
      COEFCG =
        purchaseContractType === SiteConstants.purchaseContractType["CARD/T"]
          ? 459.48
          : purchaseContractType ===
            SiteConstants.purchaseContractType["Contrat unique"]
          ? 399.48
          : 659.28;
      COEFCC = 339.96;
      BI =
        ratesOption === "CU pointe fixe"
          ? [10.12, 10.04, 9.94, 9.89, 9.65] //€ par kVa
          : ratesOption === "CU pointe mobile"
          ? [10.28, 10.02, 9.94, 9.89, 9.65]
          : ratesOption === "LU pointe fixe"
          ? [27.39, 25.01, 16.01, 12.49, 9.89]
          : ratesOption === "LU pointe mobile"
          ? [30.04, 26.81, 16.01, 12.49, 9.89]
          : [];
      CI =
        ratesOption === "CU pointe fixe"
          ? [5.35, 4.01, 2.45, 1.08, 0.7] //cts/€ par kWh diviser les CI par cent pour avoir les euros
          : ratesOption === "CU pointe mobile"
          ? [6.49, 3.87, 2.45, 1.08, 0.7]
          : ratesOption === "LU pointe fixe"
          ? [2.88, 2.19, 1.58, 0.74, 0.59]
          : ratesOption === "LU pointe mobile"
          ? [3.33, 1.99, 1.58, 0.74, 0.59]
          : [];
      D = 0.04;
      break;
    }
    case SiteConstants.purchaseAreaOfTension["HTB1"]: {
      COEFCI = 0;
      COEFCER = 0.96;
      COEFCG = 10032.24;
      COEFCC =
        purchaseMeterOwner === SiteConstants.purchaseMeterOwner["AODE"]
          ? 3302.04
          : 592.8;
      BI =
        ratesOption === SiteConstants.ratesOption["CU"]
          ? [8.16, 8.04, 7.92, 7.8, 7.68]
          : ratesOption === SiteConstants.ratesOption["MU"]
          ? [13.56, 13.32, 12, 10.44, 8.76]
          : ratesOption === SiteConstants.ratesOption["LU"]
          ? [35.4, 33.84, 26.88, 18.48, 12.48]
          : [];
      CI =
        ratesOption === SiteConstants.ratesOption["CU"]
          ? [2.21, 1.84, 1.57, 1.06, 0.76]
          : ratesOption === SiteConstants.ratesOption["MU"]
          ? [1.68, 1.46, 1.19, 0.8, 0.59]
          : ratesOption === SiteConstants.ratesOption["LU"]
          ? [0.89, 0.77, 0.58, 0.49, 0.35]
          : [];
      D = 0.009;
      break;
    }
    case SiteConstants.purchaseAreaOfTension["HTB2"]: {
      COEFCI = 0.023;
      COEFCER = 0.96;
      COEFCG = 10032.24;
      COEFCC =
        purchaseMeterOwner === SiteConstants.purchaseMeterOwner["AODE"]
          ? 3302.04
          : 592.8;
      BI =
        ratesOption === SiteConstants.ratesOption["CU"]
          ? [2.64, 2.64, 2.64, 2.64, 2.52]
          : ratesOption === SiteConstants.ratesOption["MU"]
          ? [4.2, 4.08, 3.96, 3.48, 3.0]
          : ratesOption === SiteConstants.ratesOption["LU"]
          ? [11.28, 10.8, 8.64, 6.24, 4.2]
          : [];
      CI =
        ratesOption === SiteConstants.ratesOption["CU"]
          ? [1.11, 0.9, 0.81, 0.63, 0.51]
          : ratesOption === SiteConstants.ratesOption["MU"]
          ? [0.93, 0.81, 0.68, 0.54, 0.44]
          : ratesOption === SiteConstants.ratesOption["LU"]
          ? [0.65, 0.58, 0.49, 0.42, 0.35]
          : [];
      D = 0.0143;
      break;
    }
    case SiteConstants.purchaseAreaOfTension["HTB3"]: {
      COEFCI = 0.023;
      COEFCER = 0.96;
      COEFCG = 10032.24;
      COEFCC =
        purchaseMeterOwner === SiteConstants.purchaseMeterOwner["AODE"]
          ? 3302.04
          : 592.8;
      BI = [0, 0, 0, 0, 0];
      CI = [0.35, 0.35, 0.35, 0.35, 0.35];
      D = 0;
      break;
    }
    default: {
      console.error("Unknown area of tension");
    }
  }

  return {
    TCSPE: TCSPE / 100,
    TCTA: TCTA / 100,
    COEFCG,
    COEFCC,
    COEFCER: COEFCER / 100,
    COEFCI,
    BI,
    CI,
    D,
  };
};
