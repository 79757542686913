import React from "react";
import styled from "styled-components";

const StyledCaption = styled.caption`
  font-weight: 500 !important;
  padding: 1em;
  border: 1px solid blue;
  caption-side: ${(props) => props.captionSide};
  border: ${(props) => (props.border ? "1px solid #deebde" : "none")};
  /* font-style: italic; */
  ${(props) => props.isFullWidth && `width:100%;`}
  ${(props) => props.hasTextCentered && `display:block ; text-align:center;`}
`;

export const CustomCaption = (props) => {
  const {
    children = "",
    position = "top",
    isFullWidth = false,
    hasTextCentered = false,
    withBorder = false,
    isUnderligned = false,
  } = props;
  return (
    <StyledCaption
      captionSide={position}
      border={withBorder}
      isFullWidth={isFullWidth}
      hasTextCentered={hasTextCentered}
      isUnderligned={isUnderligned}
    >
      {children}
    </StyledCaption>
  );
};
