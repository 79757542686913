module.exports = {
  type: {
    CONSUMER: "CONSUMER",
    CONSUMER_PLUS: "CONSUMER_PLUS",
  },

  siteChoices: {
    INDIV: "INDIV",
    ACC: "ACC",
    FULL_SELLOUT: "FULL_SELLOUT",
  },

  PDL_EXEMPLE:
    "https://firebasestorage.googleapis.com/v0/b/likewatt-4bb4b.appspot.com/o/utils%2F202305_O%C3%B9%20trouver%20le%20PDL%20sur%20une%20facture.pdf?alt=media&token=c7a35abd-284e-4d7b-989d-1502d3f613e8",

  CONSO_DATA_SAMPLE:
    "https://firebasestorage.googleapis.com/v0/b/likewatt-4bb4b.appspot.com/o/utils%2FExemple_Courbe_De_Charge.csv?alt=media&token=a83f1483-50fa-4ce7-b762-4b3d415f4eca",
  CONSENT_TEMPLATE:
    "https://firebasestorage.googleapis.com/v0/b/likewatt-4bb4b.appspot.com/o/utils%2FAutorisation%20ENEDIS.docx?alt=media&token=eb9d5f92-bca5-4066-ad5b-58cebe591c19",
  HELP_CONTRACT:
    "https://firebasestorage.googleapis.com/v0/b/likewatt-4bb4b.appspot.com/o/utils%2Fhelp%20contrat.jpg?alt=media&token=3775babd-edfa-423b-a98c-9f0b8ae8a8f7",
  ConsoData:
    "https://firebasestorage.googleapis.com/v0/b/likewatt-4bb4b.appspot.com/o/utils%2FCONSO_DATA.png?alt=media&token=7f51082e-0bca-481a-93b2-7f80d887b22c",
  ProdData:
    "https://firebasestorage.googleapis.com/v0/b/dev-likewatt-1ec3f.appspot.com/o/Producers%2FUtils%2FConsoData.csv?alt=media&token=92cd464e-f836-4db7-85db-4c23076ed7a4",

  FULLSELLOUT_DATA:
    "https://firebasestorage.googleapis.com/v0/b/likewatt-4bb4b.appspot.com/o/utils%2FFullSellOut.csv?alt=media&token=b2c3de17-58a0-4137-b568-77987c7cceac",

  profiles: {
    RES_BASE: "RES_BASE",
    RES_BASE_INF_6: "RES_BASE_INF_6",
    RES_BASE_SUP_6: "RES_BASE_SUP_6",
    RES_HP_HC: "RES_HP_HC",
    PRO_BASE: "PRO_BASE",
    PRO_HP_HC: "PRO_HP_HC",
    ENT_BT_SUP: "ENT_BT_SUP",
    ENT_HTA: "ENT_HTA",
  },

  profilesDisplayNames: {
    RES_BASE: "Résidentiel Base",
    RES_HP_HC: "Résidentiel HP/HC",
    PRO_BASE: "Professionnel Base",
    PRO_HP_HC: "Professionnel HP/HC",
    ENT_BT_SUP: "Entreprise >36kVa",
    ENT_HTA: "Entreprise HTA",
  },

  _profilesDisplayNames: {
    RES_BASE: "RES_BASE",
    RES_HP_HC: "RES_HP_HC",
    PRO_BASE: "PRO_BASE",
    PRO_HP_HC: "PRO_HP_HC",
    ENT_BT_SUP: "ENT_BT_SUP",
    ENT_HTA: "ENT_HTA",
  },

  timeSlotsByProfile: {
    RES_BASE_INF_6: ["Consommation (kWh)"],
    RES_BASE_SUP_6: ["Consommation (kWh)"],
    RES_BASE: ["Consommation (kWh)"],
    RES_HP_HC: [
      "Consommation en heures pleines (kWh)",
      "Consommation en heures creuses (kWh)",
    ],
    PRO_BASE: ["Consommation (kWh)"],
    PRO_HP_HC: [
      "Consommation en heures pleines (kWh)",
      "Consommation mensuelle heures creuses (kWh)",
    ],
    ENT_BT_SUP: [
      "Consommation en heures pleines hiver (kWh)",
      "Consommation en heures creuses hiver (kWh)",
      "Consommation en heures pleines été (kWh)",
      "Consommation en heures creuses été (kWh)",
    ],
    ENT_HTA: [
      "Consommation en heures de pointe (kWh)",
      "Consommation en heures pleines hiver (kWh)",
      "Consommation en heures creuses hiver (kWh)",
      "Consommation en heures pleines été (kWh)",
      "Consommation en heures creuses été (kWh)",
    ],
  },

  DATA_SOURCES: {
    MESURES: "MESURES",
    CSV: "CSV",
    ENEDIS: "ENEDIS",
    PROFILE: "PROFILE",
    DAILY_INDEX: "INDEX J.",
    HYBRID: "HYBRIDE",
  },
  purchaseContractType: {
    "CARD/T": "CARD/T",

    "Contrat unique": "Contrat unique",
    Autoproducteur: "Autoproducteur",
  },
  purchaseMeterOwner: {
    AODE: "AODE",
    Utilisateur: "Utilisateur",
  },
  purchaseAreaOfTension: {
    "BT<36 kVA": "BT<36 kVA",
    "BT>36 kVA": "BT>36 kVA",
    HTA: "HTA",
    HTB1: "HTB1",
    HTB2: "HTB2",
    HTB3: "HTB3",
  },
  ratesOption: {
    CU: "CU",
    LU: "LU",
    "CU pointe fixe": "CU pointe fixe",
    "CU pointe mobile": "CU pointe mobile",
    "LU pointe fixe": "LU pointe fixe",
    "LU pointe mobile": "LU pointe mobile",
    MU: "MU",
    CU4: "CU4",
    MU4: "MU4",
    MUDT: "MUDT",
  },
  areaOfTensionRatesOption: {
    "BT>36 kVA": ["CU", "LU"],
    HTA: [
      "CU pointe fixe",
      "CU pointe mobile",
      "LU pointe fixe",
      "LU pointe mobile",
    ],
    HTB1: ["CU", "MU", "LU"],
    HTB2: ["CU", "MU", "LU"],
    HTB3: ["CU", "MU", "LU"],
    "BT<36 kVA": ["CU4", "CU", "MU4", "MUDT", "LU"],
  },
  cityObject: {
    address: "59 Rue de l'Abondance, 69003 Lyon, France",
    addressCity: "LYON",
    addressDepartement: "RHÔNE",
    addressDepartementNum: 69,
    addressZipCode: "69003",
    addressGeocode: [45.7540459, 4.8558866],
  },
  subscribedPowersRanges: {
    "<36kVA": "<36kVA",
    "<=250kVA": "<=250kVA",
    ">250kVA": ">250kVA",
  },
  CSPE: {
    "<36kVA": 3.20625,
    "<=250kVA": 2.56875,
    ">250kVA": 2.25,
  },

  WINTER_MONTH: [11, 12, 1, 2, 3],
  PTE_MONTH: [12, 1, 2],
  ENT_BT_SUP_WINTER_INDEXES: [0, 1],
  ENT_HTA_WINTER_INDEXES: [1, 2],
  ENT_HTA_PTE_INDEXES: [0],
  ENEDIS_DATA_OK: "Report Retrieved",
  SITE_MAX_DATA_SOURCE_HISTORY_LENGTH: 5,
  FRANCE_COUNTRY_CODES: ["GP", "MQ", "GF", "RE", "YT", "fr"],
  userManual:
    "https://firebasestorage.googleapis.com/v0/b/likewatt-4bb4b.appspot.com/o/utils%2FLK_parcours%20client%20Optiwize_20200812.pdf?alt=media&token=eb7534f9-dd46-4edc-a3d4-081b3ff56da5",

  TIME_SLOTS: {
    HCBS: "HCB",
    HPBS: "HPB",
    HCHS: "HCH",
    HPHS: "HPH",
    PTE: "PTE",
  },

  TURPE_VERSION: 1,
};
