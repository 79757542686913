/**
 * Here is the list of all the tutorials that will be automatically displayed.
 * They are different from the ones that appear when clicking "Help".
 * If a new feature needs a tutorial (to show the user something has changed),
 * list it here, and add the content to constants/TutorialValues.
 * And of course, trigger the tutorial at the appropriate location
 *
 * Use the two validators shouldTutorialBeShown & setTutorialAsShown to check/set localStorage
 *
 * Tutorial-related localstorage keys will NOT be deleted on logout (see shouldLocalStorageKeyBeDeleted validator)
 */
export const TUTORIALS = {
  NEW_RESULTS_BUTTONS: "NEW_RESULTS_BUTTONS",
  NEW_ACC_ACI: "NEW_ACC_ACI",
  NEW_TEMPO: "NEW_TEMPO",
};
