import React, { useContext, useState } from "react";
import LocaleContext from "../../context/LocaleContext";
import Auth from "../../services/Auth";
import { Button } from "react-bulma-components/full";
import ETAPE3 from "../../assets/img/etape3.png";

import DeveloppersService from "../../services/Developpers";
import User from "../../services/User";
import landingPV from "../../assets/img/PV.jpg";
import styled from "styled-components";
import { Square } from "../../assets/style/commonStyledComponent";
const defaultUserInfo = {
  license: "OneSiteAccess",
  isTemporary: true,
  optimNumber: 9999999,
};
export const EndOfLanding = (props) => {
  const { analytics, PageNames } = useContext(LocaleContext);
  const [loading, setLoading] = useState(false);
  const { triggerRedirect } = props;
  const handleAnonymouseSignup = async () => {
    // //Fetching developper defaultScenarios id for later use
    setLoading(true);
    const devRef = await DeveloppersService.getByDevelopper();
    const devData = await User.get(devRef.account);
    console.log({ devData });
    try {
      localStorage.setItem("scenarioTemplates", devData.defaultScenarios);
    } catch (e) {
      console.log("No scenarios were found");
    }
    setLoading(false);
    triggerRedirect(true);
  };

  const StepContainer = styled.div`
    height: 98vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align &.step-5 {
      background: url(${landingPV});
    }
  `;

  const Main = styled.div`
    color: white;
    font-weight: bold;
    padding: 10px 1em;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `;

  const Secondary = styled.div`
    color: white;
    padding: 1em;
    padding-top: 0;
    padding-bottom: 10px;
    font-size: 1.3rem !important;
    height: 25%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
  `;

  const StartButton = styled(Button)`
    z-index: 9999;
    position: absolute;
    right: 3%;
    top: 50%;
    background-color: ${(props) => props.theme.main};
    border-radius: 100px;
    color: white;
    padding: 30px;
    font-size: 2rem;
    &:hover {
      background-color: ${(props) => props.theme.third};
      color: white;
    }
    &.is-loading {
      &.is-info {
        &:after {
          color: ${(props) => props.theme.main};
          border-color: transparent transparent ${(props) => props.theme.main}
            ${(props) => props.theme.main} !important;
        }
      }
    }
  `;

  const Img = styled.img`
    position: relative;
    margin: 0 auto;
    &.step-3 {
      width: 75% !important;
    }
  `;

  return (
    <StepContainer>
      <Square>
        <Main>
          A l'issue de cette simulation, vous obtiendrez une étude personnalisée
        </Main>

        <Secondary>
          <p>
            Détaillant votre consommation actuelle et projetée ainsi que les
            bilans financiers (investissement, économie, temps de retour...)
          </p>
        </Secondary>
        <Img className="step-3" src={ETAPE3}></Img>
      </Square>
      <StartButton loading={loading} onClick={handleAnonymouseSignup}>
        Je commence
      </StartButton>
    </StepContainer>
  );
};
