import { BehaviorSubject } from "rxjs";

let notifications = {};

const notifications$ = new BehaviorSubject(notifications);

export const toogleNotification = (notification) => {
  notifications[notification] = !notifications[notification];
  notifications$.next(notifications);
};

export const resetAllNotifications = () => {
  for (let notification in notifications) {
    notifications[notification] = false;
  }
  notifications$.next(notifications);
};

export const getNotificationsToShow = () => {
  let notificationsToShow = [];
  for (let notif in notifications) {
    if (notifications[notif]) {
      notificationsToShow.push(notif);
    }
  }
  return notificationsToShow;
};

export default notifications$;
