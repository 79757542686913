import React, { useState } from "react";
import { URL_ADMIN_SIGNUP } from "../../constants/Auth";
import Auth from "../../services/Auth";

const SendMailToAdminSignUp = ({ token }) => {
  const [result, setResult] = useState("");
  const [color, setColor] = useState("green");
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(Auth.getUser().email);
  const [firstname, setFirstname] = useState("Omar");
  const [lastname, setLastname] = useState("JIBAR");
  const [address, setAddress] = useState("Av maarakat anoual");
  const [addressCity, setAddressCity] = useState("Fnideq");
  const [addressZipCode, setAddressZipCode] = useState("93102");
  const [phone, setPhone] = useState("");
  const [env, setEnv] = useState("production");

  const send = () => {
    setIsLoading(true);
    fetch(URL_ADMIN_SIGNUP, {
      method: "POST",
      headers: { Authorization: token },
      body: JSON.stringify({
        email,
        firstname,
        lastname,
        address,
        addressCity,
        addressZipCode,
        phone,
        env,
      }),
    })
      .then((res) => {
        setColor(res.ok ? "green" : "red");
        res
          .text()
          .then((txt) => {
            try {
              setResult(JSON.stringify(JSON.parse(txt), null, "\t"));
              setIsLoading(false);
            } catch {
              setResult(txt);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            setColor("red");
            setResult(JSON.stringify(err));
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setColor("red");
        setResult(JSON.stringify(err));
        setIsLoading(false);
      });
  };
  return (
    <div className="cloud-functions-tester-container">
      {isLoading && <div class="cloud-functions-tester-loader"></div>}
      {!isLoading && (
        <>
          <label htmlFor="email">email:</label>
          <input
            id="email"
            type="text"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
          />
          <label htmlFor="firstname">firstname:</label>
          <input
            id="firstname"
            type="text"
            value={firstname}
            onChange={({ target }) => setFirstname(target.value)}
          />
          <label htmlFor="lastname">lastname:</label>
          <input
            id="lastname"
            type="text"
            value={lastname}
            onChange={({ target }) => setLastname(target.value)}
          />
          <label htmlFor="address">address:</label>
          <input
            id="address"
            type="text"
            value={address}
            onChange={({ target }) => setAddress(target.value)}
          />
          <label htmlFor="addressCity">addressCity:</label>
          <input
            id="addressCity"
            type="text"
            value={addressCity}
            onChange={({ target }) => setAddressCity(target.value)}
          />
          <label htmlFor="addressZipCode">addressZipCode:</label>
          <input
            id="addressZipCode"
            type="text"
            value={addressZipCode}
            onChange={({ target }) => setAddressZipCode(target.value)}
          />
          <label htmlFor="phone">phone:</label>
          <input
            id="phone"
            type="text"
            value={phone}
            onChange={({ target }) => setPhone(target.value)}
          />
          <label htmlFor="env">env:</label>
          <input
            id="env"
            type="text"
            value={env}
            onChange={({ target }) => setEnv(target.value)}
          />
          <hr />
          <label htmlFor="token">Response:</label>
          <textarea
            cols="30"
            rows="10"
            id="textarea"
            type="text"
            style={{ color }}
            value={result}
          />

          <button id="send" onClick={send}>
            SEND
          </button>
        </>
      )}
    </div>
  );
};

export default SendMailToAdminSignUp;
