import React, { useState } from "react";
import { URL_TIMEZONE } from "../../constants/Auth";

const GetTimezone = ({ token }) => {
  const [result, setResult] = useState("");
  const [color, setColor] = useState("green");
  const [isLoading, setIsLoading] = useState(false);
  const [lat, setLat] = useState("48.856613");
  const [lng, setLng] = useState("2.352222");

  const send = () => {
    setIsLoading(true);
    fetch(URL_TIMEZONE, {
      method: "POST",
      headers: { Authorization: token },
      body: JSON.stringify({ GEOCODE: [lat, lng] }),
    })
      .then((res) => {
        setColor(res.ok ? "green" : "red");
        res
          .text()
          .then((txt) => {
            try {
              setResult(JSON.stringify(JSON.parse(txt), null, "\t"));
              setIsLoading(false);
            } catch {
              setResult(txt);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            setColor("red");
            setResult(JSON.stringify(err));
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setColor("red");
        setResult(JSON.stringify(err));
        setIsLoading(false);
      });
  };
  return (
    <div className="cloud-functions-tester-container">
      {isLoading && <div class="cloud-functions-tester-loader"></div>}
      {!isLoading && (
        <>
          <label htmlFor="lat">lat:</label>
          <input
            id="lat"
            type="text"
            value={lat}
            onChange={({ target }) => setLat(target.value)}
          />
          <label htmlFor="lng">lng:</label>
          <input
            id="lng"
            type="text"
            value={lng}
            onChange={({ target }) => setLng(target.value)}
          />
          <hr />
          <label htmlFor="textarea">Response:</label>
          <textarea
            cols="30"
            rows="10"
            id="textarea"
            type="text"
            style={{ color }}
            value={result}
          />

          <button id="send" onClick={send}>
            SEND
          </button>
        </>
      )}
    </div>
  );
};

export default GetTimezone;
