import DatabaseConstants from "./../constants/Database";
import to from "await-to-js";
import Auth from "./Auth";
import moment from "moment-timezone";
import { db, storage } from "./../fire";
import Firebase from "firebase";
import UserConstants from "../constants/User";
import {
  DEFAULT_GRID_KEYS,
  GRID_REFERENCES,
  SYNTHESIS_FI_KEYS,
} from "../components/shared/ReactGridLayout/constants";
import { setDefaultGridLayout } from "../store/gridLayout/store";

const DEFAULT_LOGO =
  "https://firebasestorage.googleapis.com/v0/b/dev-likewatt-1ec3f.appspot.com/o/Utils%2FDefaultLogo.jpg?alt=media&token=5850fb15-ace0-4ed0-bed0-9b8b8563d9b9";

const DEFAULT_BG =
  "https://firebasestorage.googleapis.com/v0/b/likewatt-4bb4b.appspot.com/o/utils%2FPV.jpg?alt=media&token=11861450-75f7-4bd5-bd45-1018a96c3182";
const GENERAL = GRID_REFERENCES.OPTIM_GENERAL_CONSUMER;
const SCENARIO = GRID_REFERENCES.OPTIM_SCENARIO_CONSUMER;
const ANALYSIS_INDIV = GRID_REFERENCES.ANALYSIS_INDIVIDUAL;
const ANALYSIS_COLL = GRID_REFERENCES.ANALYSIS_COLLECTIVE;

class UserService {
  add = (customId = null, data) => {
    if (data.score) delete data.score;
    if (customId != null) {
      db.collection(DatabaseConstants.COLLECTION_USERS).doc(customId).set(data);
      return Promise.resolve();
    }
    return db.collection(DatabaseConstants.COLLECTION_USERS).add(data);
  };

  get = async (userId) => {
    const docRef = db
      .collection(DatabaseConstants.COLLECTION_USERS)
      .doc(userId);
    const [err, doc] = await to(docRef.get());

    if (err) {
      console.error(`Could not get user. Error : ${err.message}`);
      return null;
    }
    if (doc.exists) return doc.data();
    return null;
  };

  getAll = async () => {
    const docRef = db.collection(DatabaseConstants.COLLECTION_USERS);
    const [err, users] = await to(docRef.get());
    if (err) {
      console.error(`Could not get user. Error : ${err.message}`);
      return null;
    }
    if (users) return users.docs.map((doc) => doc.data());
    return null;
  };

  getLimitUser = async (size, user) => {
    if (user?.length >= size) {
      return [];
    }

    let query = db
      .collection(DatabaseConstants.COLLECTION_USERS)
      .orderBy("_lastConnected");

    if (size === 50) {
      query = query.limit(50);
    } else if (size === 9999) {
      return this.getAll();
    } else {
      query = query
        .startAt(user[user.length - 1]._lastConnected)
        .limit(size - user.length);
    }

    try {
      const documentSnapshots = await query.get();
      return documentSnapshots.docs.map((doc) => doc.data());
    } catch (error) {
      console.error(
        "Une erreur s'est produite lors de la récupération des utilisateurs :",
        error,
      );
      throw error;
    }
  };

  update = async (userId, data) => {
    let err = null;
    if (data.score) delete data.score;
    const docRef = db
      .collection(DatabaseConstants.COLLECTION_USERS)
      .doc(userId);

    [err] = await to(docRef.set(data, { merge: true }));
    if (err) return Promise.reject(err);

    return Promise.resolve();
  };

  updateInfos = async (userId, data, fromAdmin = false) => {
    let err = null;
    const {
      firstname,
      lastname,
      address,
      addressCity,
      addressZipCode,
      company,
      allowedNumberOfSites,
      isAdmin = false,
      plan,
      maxUsers,
      optimNumber,
      startDate,
      endDate,
      type,
      domain = UserConstants.DOMAINS.BTSUP,
      isManager = false,
      trackerOkd = false,
      managerLicenses,
      phone = "",
      license,
      renewLicense,
    } = data;

    const docRef = db
      .collection(DatabaseConstants.COLLECTION_USERS)
      .doc(userId);

    const updateData = {
      firstname,
      lastname,
      address,
      addressCity,
      addressZipCode,
      allowedNumberOfSites,
      organization: company,
      company: company,
      license: plan || data.license,
      isAdmin,
      maxUsers,
      optimNumber,
      startDate,
      endDate,
      type,
      domain,
      isManager,
      trackerOkd,
      managerLicenses,
      phone,
      renewLicense,
    };

    [err] = await to(docRef.set(updateData, { merge: true }));

    if (err) return Promise.reject(err);

    if (!fromAdmin) {
      const userData = Auth.getUser();
      Auth.setUserData({
        ...userData,
        ...updateData,
      });
      return Promise.resolve();
    }
  };

  toggleDisable = async (userId, active) => {
    let err,
      res = null;
    let payload = !active
      ? { active, _removedAt: moment().format("YYYY-MM-DD HH:mm:ss") }
      : { active, _removedAt: null };
    const docRef = db
      .collection(DatabaseConstants.COLLECTION_USERS)
      .doc(userId);

    [err] = await to(docRef.set(payload, { merge: true }));
    if (err) return Promise.reject(err);
    return Promise.resolve();
  };

  updateVerified = async (userId) => {
    let err = null;

    const docRef = db
      .collection(DatabaseConstants.COLLECTION_USERS)
      .doc(userId);

    [err] = await to(docRef.set({ emailVerified: true }, { merge: true }));
    if (err) return Promise.reject(err);
    Auth.setUserData({
      ...Auth.getUser(),
      emailVerified: true,
    });
    return Promise.resolve();
  };

  updateNumberOfSite = async (userId, number) => {
    let err = null;

    const docRef = db
      .collection(DatabaseConstants.COLLECTION_USERS)
      .doc(userId);

    [err] = await to(
      docRef.set({ allowedNumberOfSites: number }, { merge: true }),
    );
    if (err) return Promise.reject(err);
    Auth.setUserData({
      ...Auth.getUser(),
      allowedNumberOfSites: number,
    });
    return Promise.resolve();
  };

  updateLastConnected = async (userId) => {
    let err = null;

    const docRef = db
      .collection(DatabaseConstants.COLLECTION_USERS)
      .doc(userId);
    [err] = await to(
      docRef.set(
        {
          _lastConnected: moment()
            .tz("Europe/Paris")
            .format("DD/MM/YYYY HH:mm"),
        },
        { merge: true },
      ),
    );
    if (err) return Promise.reject(err);
    return Promise.resolve();
  };

  updateOptimNumber = async (userId) => {
    let err = null;

    const docRef = db
      .collection(DatabaseConstants.COLLECTION_USERS)
      .doc(userId);

    [err] = await to(docRef.set({ optimNumber: 0 }, { merge: true }));
    if (err) return Promise.reject(err);
    return Promise.resolve();
  };

  getNumberOfAllowedSites = async (userId) => {
    const [err, user] = await to(this.get(userId));

    if (err)
      return {
        allowedNumberOfSites: 0,
        optimNumber: 0,
      };

    return {
      allowedNumberOfSites: user.allowedNumberOfSites,
      optimNumber: user.optimNumber,
    };
  };

  getKey = async (userId, key) => {
    if (!userId) return;
    let err,
      user = null;
    const docRef = db
      .collection(DatabaseConstants.COLLECTION_USERS)
      .doc(userId);

    [err, user] = await to(docRef.get());
    if (err) {
      console.error(`Could not get user. Error : ${err.message}`);
      return null;
    }
    if (user.exists) {
      if (user.data()[key]) return user.data()[key];
      else return null;
    }
    return null;
  };

  updateKey = async (key, value) => {
    let err = null;

    const docRef = db
      .collection(DatabaseConstants.COLLECTION_USERS)
      .doc(Auth.getUserId());

    [err] = await to(docRef.set({ [key]: value }, { merge: true }));
    if (err) return Promise.reject(err);
    return Promise.resolve();
  };

  uploadLogo = async (
    blob = null,
    userId,

    onStateChange = () => {},
  ) => {
    return new Promise((resolve, reject) => {
      const fileRef = storage.ref(`User/${userId}/logo.png`);

      const uploadTask = fileRef.put(blob, {
        contentType: "image/png",
      });

      if (blob == null) return reject(new Error("Blob is null"));
      uploadTask.on(
        Firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => onStateChange(snapshot),
        (err) => reject(err),
        () =>
          uploadTask.snapshot.ref.getDownloadURL().then((url) => resolve(url)),
      );
    });
  };

  deleteLogo = async (uid) => {
    const desertRef = storage.ref(`User/${uid}/logo`);
    desertRef.delete().catch((e) => e);
    await this.update(uid, { logo: Firebase.firestore.FieldValue.delete() });
  };

  deleteLogoSubordinates = async (subordinates) => {
    return Promise.all(
      subordinates.map(async (subordinate) => {
        await this.deleteLogo(subordinate);
        return await this.update(subordinate, {
          logo: "",
        });
      }),
    );
  };

  getLogo = async () => {
    let err,
      user = null;
    const docRef = db
      .collection(DatabaseConstants.COLLECTION_USERS)
      .doc(Auth.getUserId());

    [err, user] = await to(docRef.get());
    if (err) {
      console.error(`Could not get user. Error : ${err.message}`);
      return null;
    }
    if (user.exists) {
      if (user.data().logo) return user.data().logo;
      else return DEFAULT_LOGO;
    }
    return null;
  };

  getLogoWithUserId = async (id) => {
    let err,
      user = null;
    const docRef = db.collection(DatabaseConstants.COLLECTION_USERS).doc(id);

    [err, user] = await to(docRef.get());
    if (err) {
      console.error(`Could not get user. Error : ${err.message}`);
      return null;
    }
    if (user.exists) {
      if (user.data().logo) return user.data().logo;
      else return DEFAULT_LOGO;
    }
    return null;
  };

  deleteOne = async (userId) =>
    db.collection(DatabaseConstants.COLLECTION_USERS).doc(userId).delete();

  deleteFaltyUsers = async () =>
    await db
      .collection(DatabaseConstants.COLLECTION_USERS)
      .get()
      .then((users) =>
        users.forEach(async (user) => {
          if (!user.data().email) await this.deleteOne(user.id);
        }),
      )
      .catch((err) => console.log({ err }));

  getByEmail = async (email) => {
    let err,
      data = null;

    [err, data] = await to(
      db
        .collection(DatabaseConstants.COLLECTION_USERS)
        .where("email", "==", email)
        .get(),
    );
    if (err) return Promise.reject(err);
    return Promise.resolve(data.docs.map((doc) => doc.data()));
  };

  getManagerSubordinates = async (manager) => {
    if (
      typeof manager.subordinates === "undefined" ||
      manager.subordinates?.length === 0
    )
      return [];
    return Promise.all(
      manager.subordinates.map(async (subordinate) => {
        return await this.get(subordinate);
      }),
    );
  };

  updateManagerSubordinates = async (manager) => {
    if (
      typeof manager.subordinates === "undefined" ||
      manager.subordinates?.length === 0
    )
      return [];
    return Promise.all(
      manager.subordinates.map(async (subordinate) => {
        await this.update(subordinate, {
          defaultRates: manager?.defaultRates ?? [],
        });
        return await this.update(subordinate, {
          defaultScenarios: manager?.defaultScenarios ?? [],
        });
      }),
    );
  };

  updateLogoSubordinates = async (fileToUpload, logo, subordinates) => {
    return Promise.all(
      subordinates.map(async (subordinate) => {
        await this.uploadLogo(fileToUpload, subordinate);
        return await this.update(subordinate, {
          logo,
        });
      }),
    );
  };

  findUsersWithDefaultScenario = async (scenarioId) => {
    let err,
      data = null;

    [err, data] = await to(
      db
        .collection(DatabaseConstants.COLLECTION_USERS)
        .where("defaultScenarios", "array-contains", scenarioId)
        .get(),
    );
    if (err) console.log({ err });

    return Promise.resolve(data.docs.map((doc) => doc.data()));
  };
  findUsersWithDefaultRates = async (rateId) => {
    let err,
      data = null;

    [err, data] = await to(
      db
        .collection(DatabaseConstants.COLLECTION_USERS)
        .where("defaultRates", "array-contains", rateId)
        .get(),
    );

    return Promise.resolve(data.docs.map((doc) => doc.data()));
  };

  getByCriterion = async (criterion, operator, value) => {
    let err,
      data = null;

    [err, data] = await to(
      db
        .collection(DatabaseConstants.COLLECTION_USERS)
        .where(criterion, operator, value)
        .get(),
    );
    if (err) return Promise.reject(err);
    return Promise.resolve(data.docs.map((doc) => doc.data()));
  };

  getGridLayout = async (type) => {
    let err,
      user = null;
    const docRef = db
      .collection(DatabaseConstants.COLLECTION_USERS)
      .doc(Auth.getUserId());

    [err, user] = await to(docRef.get());
    if (err) {
      console.error(`Could not get user. Error : ${err.message}`);
      return null;
    }

    if (user.exists) {
      switch (type) {
        case GENERAL:
          return user.data()[GENERAL];
        case SCENARIO:
          return user.data()[SCENARIO];
        case ANALYSIS_INDIV:
          return user.data()[ANALYSIS_INDIV];
        case ANALYSIS_COLL:
          return user.data()[ANALYSIS_COLL];
        default:
          break;
      }
    }
    return null;
  };

  addGridDefaultValues = async (userData) => {
    try {
      // ADD GRID DEFAULT VALUES
      for (const key in DEFAULT_GRID_KEYS) {
        if (!(key in userData) || !Array.isArray(userData[key])) {
          await to(this.updateKey(key, DEFAULT_GRID_KEYS[key]));
        }
      }
      // ADD SYNTHESIS FI DEFAULT VALUES
      if (!userData.synthesis_fi || !Array.isArray(userData.synthesis_fi)) {
        await to(this.updateKey("synthesis_fi", SYNTHESIS_FI_KEYS));

        try {
          localStorage.removeItem("synthesis-fi");
          localStorage.setItem(
            "synthesis-fi",
            JSON.stringify(SYNTHESIS_FI_KEYS),
          );
        } catch (error) {
          console.error(
            "Error fetching or setting default synthesis keys :",
            error,
          );
        }
      }
    } catch (e) {
      console.log("Error in creating graph default values", e);
    }
  };

  getGridDefaultValues = async (userData) => {
    try {
      const gridReferences = [
        GRID_REFERENCES.ANALYSIS_INDIVIDUAL,
        GRID_REFERENCES.ANALYSIS_COLLECTIVE,
        GRID_REFERENCES.OPTIM_SCENARIO_CONSUMER,
        GRID_REFERENCES.OPTIM_GENERAL_CONSUMER,
      ];

      const gridLayouts = await Promise.all(
        gridReferences.map((reference) => this.getGridLayout(reference)),
      );

      gridLayouts.forEach((layout, index) => {
        setDefaultGridLayout(gridReferences[index], layout);
      });

      const defaultSynthesisValues = await this.getKey(
        userData.uid,
        "synthesis_fi",
      );

      if (localStorage.getItem("synthesis-fi")) {
        const localSynthesisValues = [
          ...JSON.parse(localStorage.getItem("synthesis-fi")),
        ];

        const filteredValues = localSynthesisValues.filter((value) =>
          defaultSynthesisValues.includes(value),
        );

        localStorage.removeItem("synthesis-fi");
        localStorage.setItem("synthesis-fi", JSON.stringify(filteredValues));
      } else {
        localStorage.setItem(
          "synthesis-fi",
          JSON.stringify(defaultSynthesisValues),
        );
      }
    } catch (error) {
      console.error("Error fetching or setting default grid layouts:", error);
    }
  };

  getBackgroudImage = async (id) => {
    let err,
      user = null;

    const docRef = db.collection(DatabaseConstants.COLLECTION_USERS).doc(id);

    [err, user] = await to(docRef.get());
    if (err) {
      console.error(`Could not get user. Error : ${err.message}`);
      return null;
    }
    if (user.exists) {
      if (user.data().backgroundImage) return user.data().backgroundImage;
      else return DEFAULT_BG;
    }
    return null;
  };
}

export default new UserService();
