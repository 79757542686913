import React, { useContext } from "react";
import styled from "styled-components";
import LocaleContext from "../../context/LocaleContext";
import _ from "lodash";
import {
  siteSourceIsEnedisRelated,
  isSiteEnedisCompliant,
} from "../../helpers/Validators";
import Site from "../../helpers/Site";

const StyledTextWarning = styled.p`
  color: red;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: ${(props) => props.smallSize || "1rem"};
  margin-top: ${(props) => props.warningMargin || "10px"};
`;

export const ShowConsentFileStatus = ({ site, smallSize, warningMargin }) => {
  if (!site) return null;

  const { i18n } = useContext(LocaleContext);
  if (
    siteSourceIsEnedisRelated(site) &&
    !isSiteEnedisCompliant(site) &&
    !site.isC_AND_I
  )
    return (
      <StyledTextWarning smallSize={smallSize} warningMargin={warningMargin}>
        ⚠️
        <div>
          {i18n.t("homePage.consentFileIsMissing")}
          <br />
          {site.limitConsentDate &&
            `${i18n.t("homePage.limitDate")} : ${site.limitConsentDate}`}
        </div>
      </StyledTextWarning>
    );
  return null;
};
