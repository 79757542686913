import React, { useState } from "react";
import { URL_TEST } from "../../constants/Auth";

const Test = ({ token }) => {
  const [result, setResult] = useState("");
  const [color, setColor] = useState("green");
  const [isLoading, setIsLoading] = useState(false);

  const send = () => {
    setIsLoading(true);
    fetch(URL_TEST, { headers: { Authorization: token } })
      .then((res) => {
        setColor(res.ok ? "green" : "red");
        res
          .text()
          .then((txt) => {
            try {
              setResult(JSON.stringify(JSON.parse(txt), null, "\t"));
              setIsLoading(false);
            } catch {
              setResult(txt);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            setColor("red");
            setResult(JSON.stringify(err));
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setColor("red");
        setResult(JSON.stringify(err));
        setIsLoading(false);
      });
  };
  return (
    <div className="cloud-functions-tester-container">
      {isLoading && <div class="cloud-functions-tester-loader"></div>}
      {!isLoading && (
        <>
          <label htmlFor="token">Response:</label>
          <textarea
            cols="30"
            rows="10"
            id="textarea"
            type="text"
            style={{ color }}
            value={result}
          />

          <button id="send" onClick={send}>
            SEND
          </button>
        </>
      )}
    </div>
  );
};

export default Test;
