import React from "react";

export const InfoPopUp = (props) => {
  const { showInfoPopUp, message } = props;
  if (!showInfoPopUp) return null;
  return (
    <div className="info-popup-container">
      <div className="popup-card">{message}</div>
    </div>
  );
};
