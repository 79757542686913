import React, { useContext } from "react";
import LOGO from "../assets/img/logo.png";
import { Link } from "react-router-dom";
import LocaleContext from "../context/LocaleContext";
export const DesktopShortcut = () => {
  const { i18n } = useContext(LocaleContext);

  return (
    <div className="shortcut-container">
      <a href="https://app.likewatt.com/#/">
        <div
          id="icon"
          style={{
            width: "64px",
            height: "64px",
            backgroundImage: `url(${LOGO})`,
            backgroundSize: "cover",
            margin: "auto",
          }}
        ></div>
        <div id="title" style={{ visibility: "hidden" }}>
          Optiwize - raccourci
        </div>
      </a>
      <p>{i18n.t("forABetterExperience")} </p>
    </div>
  );
};
