const ENEDIS_ERRORS = {
  SGT562: "Problème : le message de demande est malformé",
  SGT4K4: "La date de début doit être antérieure à la date de fin.",
  SGT4H2:
    "La demande ne peut pas aboutir, les données de mesures ne sont pas corrigées pour un point du segment C5.",
  SGT4L8:
    "La durée demandée n’est pas compatible avec le type de mesure demandé",
  SGT441: "Les pas de puissances demandés sont incorrects.",
  SGT583:
    "La demande ne peut pas aboutir, le sens de la mesure ne correspond pas.",
  SGT4N5:
    "Une opposition à l'enregistrement de la courbe de charge est déjà active ou demandée sur ce point",
  SGT4K2:
    "Le client doit avoir donné son accord pour la transmission de ses données de mesure.",
  SGT473: "Le contrat n'est pas actif à la date souhaitée.",
  SGT4G3: "Aucune mesure trouvée sur ce point",
  SGT4K1: "La date de fin doit être antérieure ou égale à la date du jour",
  SGT4M7:
    "La consultation des données de mesure n'est pas possible pour un point de regroupement TURPE",
  SGT4M2: "Demande non recevable : Le point n'a jamais été mis en service",
  SGT400: "Une erreur fonctionnelle est survenue",
  SGT4L1:
    "Il n’existe aucun contrat actif sur le point à la date de la demande",
  SGT587:
    "La demande ne peut pas aboutir, le client ne dispose pas d’un contrat en soutirage sur ce point",
  SGT588:
    "La demande ne peut pas aboutir, le client ne dispose pas d’un contrat en injection sur ce point",
  SGT4N8:
    "La demande ne peut pas aboutir, le segment du point n'est pas compatible avec la demande",
  SGT401: "Demande non recevable : point inexistant",
  SGT477: "Une mise en service est en cours sur le point",
  SGT478: "Un changement de fournisseur est en cours sur le point",
  SGT480: "Une modification contractuelle est en cours sur le point",
  SGT4H9:
    "La demande ne porte pas sur un point équipé d'un compteur communicant ouvert aux services niveau 2",
  SGT4M1: "Demande non recevable : Le point est résilié",
  SGT4M3:
    "Demande non recevable : la situation du point nécessite un nouveau raccordement",
  SGT589:
    "La demande ne peut pas aboutir car le compteur n’est actuellement pas téléopérable",
  SGT422: "La situation du point ne permet pas de répondre à la demande",
  SGT4N1:
    "La consultation de données de mesures n’est autorisée que sur la période sur laquelle le client est détenteur du point",
};

export { ENEDIS_ERRORS };
