import React from "react";

import {
  Columns,
  Button,
  Notification,
  Form,
  Message,
} from "react-bulma-components/full";

import "./EditorSiteForm.scss";

import "react-datepicker/dist/react-datepicker.css";
import LocaleContext from "../../context/LocaleContext";
import Tooltip from "../shared/Tootip";
import CollapseConfig from "../Optimization/CollapseConfig";
import SiteConstants from "../../constants/Site";
import SiteService from "../../services/Site";
import { ProfileConfigurator } from "./Step2Components";
import { DownloadIcon } from "../shared/ActionIcons";
import {
  siteSourceIsCsv,
  siteSourceIsDailyIndex,
  siteSourceIsEnedis,
  siteSourceIsMesures,
  siteSourceIsProfile,
} from "../../helpers/Validators";
import { CustomCaption } from "../CustomCaption";
import { sendMailForConsent } from "../../services/CloudFunctions";
import dayjs from "dayjs";
import { CustomButton } from "../CustomButton";
import { CustomButtonSecondary } from "../CustomButtonSecondary";
import { CustomModal } from "../CustomModal";
import { CustomButtonWithIcon } from "../CustomButtonWithIcon";
import Consent from "../../services/Consent";
import { FaCheckCircle } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";

export default class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapses: {
        prm: false,
        csv: false,
        profile: false,
      },
      showModal: false,
      consentInfos: {},
    };
  }
  static contextType = LocaleContext;

  openCollapse = () => {
    const { site } = this.props;
    const { collapses } = this.state;
    if (
      siteSourceIsEnedis(site) ||
      siteSourceIsMesures(site) ||
      siteSourceIsDailyIndex(site)
    ) {
      return this.setState({
        collapses: {
          ...collapses,
          prm: true,
        },
      });
    } else if (siteSourceIsCsv(site)) {
      return this.setState({
        collapses: {
          ...collapses,
          csv: true,
        },
      });
    } else if (siteSourceIsProfile(site)) {
      return this.setState({
        collapses: {
          ...collapses,
          profile: true,
        },
      });
    }
    return this.setState({
      collapses: {
        prm: true,
        csv: false,
        profile: false,
      },
    });
  };

  fetchConsentInfo = async () => {
    const { site } = this.props;
    const consentInformations = await Consent.getSiteConsent(site.id);
    this.setState({ consentInfos: consentInformations[0] });
  };

  componentDidMount = () => {
    this.fetchConsentInfo();
    if (this.props.site?.profile)
      window.scrollTo({ bottom: 0, behavior: "smooth" });
    this.openCollapse();
  };

  handleOpenDropdown = (dropdown) => {
    const { collapses } = this.state;
    const buffer = { ...collapses };
    Object.entries(buffer).forEach(([name, value]) => {
      return name === dropdown
        ? (buffer[name] = !value)
        : (buffer[name] = false);
    });
    if (!this.props.editMode && ["csv", "prm"].includes(dropdown))
      this.props.resetSiteProfile();
    this.setState({
      collapses: buffer,
    });
  };

  handleShowModal = () => {
    const { site } = this.props;

    if (
      site.profile.length === 0 &&
      site.enedisBuffer &&
      !site.isConsentMailSent
    )
      this.setState({ showModal: true });
  };

  renderMailConsentModal = () => {
    const { showModal } = this.state;
    const {
      handleNextStep,
      canGoToStepThree,
      fetchingEnedis,
      isUploadingReport,
      customerConsentEmail,
      customerConsentFirstName,
      customerConsentLastName,
      enedisBuffer,
      handleSendEmail,
    } = this.props;

    return (
      <CustomModal
        color="danger"
        show={showModal}
        title="Validation avant d'envoi d'email à votre client"
        cancelLabel="Annuler"
        validateLabel="Continuer"
        loading={
          canGoToStepThree() ||
          isUploadingReport ||
          fetchingEnedis ||
          ((customerConsentFirstName.length === 0 ||
            customerConsentLastName.length === 0 ||
            customerConsentEmail.length === 0) &&
            enedisBuffer)
        }
        onClose={() => this.setState({ showModal: false })}
        onConfirm={() => {
          handleSendEmail();
          handleNextStep();
        }}
      >
        En continuant, votre client recevra un email lui demandant de valider
        son consentement pour la collecte de ses données auprès d'Enedis.
      </CustomModal>
    );
  };

  render() {
    const {
      site,
      isUploadingReport,
      uploadReportProgress,
      agreed,
      reportURL,
      enedisBuffer,
      type,
      error,
      handleEnedis,
      handleAgreement,
      handleChangeInputFile,
      handlePrevStep,
      handleNextStep,
      canGoToStepThree,
      fetchingEnedis,
      editMode,
      siteToEdit,
      handleChangeConsentFile,
      isUploadingConsent,
      uploadConsentProgress,
      consentFileUrl,
      license,
      handleChangeSiteProfileType,
      changeProfileMonthlyConsumption,
      handleToggleMonthlyIndex,
      cancelEditor,
      handleChangeInput,
      customerConsentEmail,
      customerConsentFirstName,
      customerConsentLastName,
      isConsentMailSent,
      hasConsentData,
      handleSendEmail,
      loadingEmail,
    } = this.props;

    const { collapses, consentInfos } = this.state;

    const { Translation, i18n } = this.context;
    const canCheck = () => {
      //false = disabled
      if (isUploadingReport || fetchingEnedis) return false;
      if (
        editMode &&
        siteToEdit.enedisNumber &&
        siteSourceIsEnedis(site) &&
        (siteToEdit.siteHasData || !site.automaticUpdateError)
      ) {
        return false;
      }
      if (editMode && siteToEdit.enedisNumber && siteSourceIsMesures(site)) {
        return false;
      }
      if (
        editMode &&
        siteToEdit.enedisNumber &&
        siteToEdit.reportURL &&
        !siteToEdit.profile
      ) {
        {
          return false;
        }
      }
      if (!editMode && site.enedisData && !site.automaticUpdateError) {
        return false;
      }
      return true;
    };

    const getDataSource = () => {
      let source = "";
      switch (site.dataSource) {
        case SiteConstants.DATA_SOURCES.ENEDIS:
        case SiteConstants.DATA_SOURCES.MESURES:
        case SiteConstants.DATA_SOURCES.DAILY_INDEX:
          source = "electricityMeter";
          break;
        case SiteConstants.DATA_SOURCES.CSV:
          source = "dataFile";
          break;
        case SiteConstants.DATA_SOURCES.PROFILE:
          source = `Profil type`;
          break;

        default:
          break;
      }
      return source;
    };

    return (
      <div className="create-site-wrapper container">
        <div className="title is-4 has-text-centered">
          <div className="subtitle is-uppercase">
            <Translation>
              {(t) => `${t("step")} 2 ${t("outOfPreposition")} 3`}
            </Translation>
          </div>
          <Translation>{(t) => t("consumptionData")}</Translation>
        </div>
        <Columns>
          <Columns.Column size={12}>
            <div>
              {site.reportURL && (
                <Message.Body
                  style={{
                    color: "#23d160",
                    marginBottom: "2em",
                    borderWidth: 0,
                    textAlign: "center",
                  }}
                >
                  <Translation>{(t) => `${t("dataSource")}`}</Translation>{" "}
                  {i18n.t(getDataSource())}
                </Message.Body>
              )}
              {error && <Notification color="danger">{error}</Notification>}
            </div>
            <CollapseConfig
              className="collapse"
              headerText={i18n.t("electricityMeter")}
              isCollapsed={collapses.prm}
              handleOpenDropdown={this.handleOpenDropdown}
              dropdownName="prm"
              //   abbr={i18n.t("captions.prm")}
            >
              <div className="has-text-centered has-margin-vertical">
                <div className="title is-4 has-text-centered">
                  <div className="subtitle is-uppercase"></div>
                </div>

                <>
                  <div className="has-margin-vertical">
                    <Form.Input
                      className="text-input"
                      type="number"
                      name="enedisBuffer"
                      value={enedisBuffer}
                      min="0"
                      maxLength="14"
                      disabled={!canCheck()}
                      onChange={(e) => handleEnedis(e)}
                      color={
                        enedisBuffer && enedisBuffer.length < 14
                          ? "danger"
                          : null
                      }
                    ></Form.Input>
                    <CustomCaption isFullWidth hasTextCentered isUnderligned>
                      <a
                        target="_blank"
                        href={SiteConstants.PDL_EXEMPLE}
                        rel="noreferrer"
                      >
                        {i18n.t("editorProject.whereCanIFindPDL")}
                      </a>
                    </CustomCaption>
                    {enedisBuffer && enedisBuffer.length < 14 && (
                      <div className="contract-tooltip">
                        <Form.Help
                          style={{
                            color: "red",
                            fontSize: 15,
                            borderWidth: 0,
                          }}
                        >
                          <Translation>
                            {(t) => `${t("numberLengthLimit")}`}
                          </Translation>
                        </Form.Help>
                        <Tooltip
                          content={i18n.t("pdlToolTip")}
                          mode="focus"
                          from="powers"
                          img={SiteConstants.HELP_CONTRACT}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      margin: "auto",
                      width: "60%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ textAlign: "left" }}>
                      Informations du client d'Enedis référencées par ce PDL
                    </div>
                    <div
                      style={{
                        display: "flex",
                        textAlign: "left",
                        marginBottom: 2,
                      }}
                    >
                      <Columns.Column size={6}>
                        <div>Nom</div>
                        <Form.Input
                          type="text"
                          name="customerConsentLastName"
                          value={customerConsentLastName}
                          onChange={handleChangeInput}
                          disabled={hasConsentData}
                        ></Form.Input>
                      </Columns.Column>
                      <Columns.Column size={6}>
                        <div>Prénom</div>
                        <Form.Input
                          type="text"
                          name="customerConsentFirstName"
                          value={customerConsentFirstName}
                          onChange={handleChangeInput}
                          disabled={hasConsentData}
                        ></Form.Input>
                      </Columns.Column>
                    </div>
                    <Columns.Column size={12}>
                      <div style={{ textAlign: "left", marginBottom: 2 }}>
                        Email
                      </div>
                      <Form.Input
                        type="email"
                        name="customerConsentEmail"
                        value={customerConsentEmail}
                        onChange={handleChangeInput}
                        disabled={hasConsentData}
                      ></Form.Input>
                    </Columns.Column>
                  </div>
                  <div
                    className="has-margin-vertical consent"
                    style={{
                      margin: "auto",
                      width: "60%",
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      position: "relative",
                      paddingLeft: "0.75rem",
                    }}
                  >
                    {hasConsentData && (
                      <>
                        <FaCheckCircle color="rgb(35, 209, 96)" width={25} />
                        <div
                          style={{ lineHeight: 0, color: "rgb(35, 209, 96)" }}
                        >
                          Autorisation reçu
                          {consentInfos?._createdAt &&
                            ` le ${consentInfos?._createdAt}`}
                        </div>
                      </>
                    )}
                    {!hasConsentData && isConsentMailSent && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "left",
                          justifyContent: "left",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            alignItems: "center",
                          }}
                        >
                          <IoIosCloseCircle color="red" width={25} />
                          <div style={{ lineHeight: 0, color: "red" }}>
                            Votre client n'a pas donné son autorisation
                          </div>
                        </div>
                        <CustomButtonWithIcon
                          onClick={() => handleSendEmail()}
                          margin="5% 0"
                          width="30%"
                          type="info"
                          disabled={loadingEmail}
                          loading={loadingEmail}
                        >
                          Renvoyer
                        </CustomButtonWithIcon>
                      </div>
                    )}
                  </div>
                </>
              </div>

              <div className="file" style={{ margin: "3em auto" }}>
                <label className="file-label" style={{ margin: "0 auto" }}>
                  <input
                    className="file-input"
                    type="file"
                    name="consumingFile"
                    onChange={handleChangeConsentFile}
                  />
                  <span className="file-cta is-full-width">
                    <span className="file-icon">
                      {isUploadingConsent && (
                        <i className="fa fa-circle-o-notch fa-spin fa-fw" />
                      )}
                      {!isUploadingConsent && <i className="fa fa-upload" />}
                    </span>
                    <span className="file-label">
                      {isUploadingConsent &&
                        `${Math.floor(uploadConsentProgress)} %`}
                      {!isUploadingConsent && !consentFileUrl && (
                        <Translation>
                          {(t) => `${t("importEnedisClientConsent")}*`}
                        </Translation>
                      )}
                      {!isUploadingConsent && consentFileUrl && (
                        <Translation>
                          {(t) => `${t("consentUploaded")}`}
                        </Translation>
                      )}
                    </span>
                  </span>
                  {consentFileUrl && (
                    <>
                      <DownloadIcon link={consentFileUrl} />
                    </>
                  )}
                </label>
              </div>

              <div className="has-margin-vertical has-text-centered">
                * Un mail du client d’Enedis confirmant son « bon pour accord »,
                en retour à votre mail disponible{" "}
                <a
                  href={i18n.t("mailForConsent")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ici
                </a>{" "}
                <span>ou </span>{" "}
                <a
                  href={SiteConstants.CONSENT_TEMPLATE}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  le formulaire complété et signé par le client d’Enedis.
                </a>
              </div>
            </CollapseConfig>
            <CollapseConfig
              className="collapse"
              headerText={i18n.t("cantSendNumber")}
              isCollapsed={collapses.csv}
              handleOpenDropdown={this.handleOpenDropdown}
              dropdownName="csv"
              abbr={i18n.t("captions.csv")}
            >
              <div className="file">
                <label className="file-label" style={{ margin: "0 auto" }}>
                  <input
                    className="file-input"
                    type="file"
                    name="consumingFile"
                    onChange={handleChangeInputFile}
                  />
                  <span className="file-cta is-full-width">
                    <span className="file-icon">
                      {isUploadingReport && (
                        <i className="fa fa-circle-o-notch fa-spin fa-fw" />
                      )}
                      {!isUploadingReport && <i className="fa fa-upload" />}
                    </span>
                    <span className="file-label">
                      {isUploadingReport &&
                        `${Math.floor(uploadReportProgress)} %`}
                      {!editMode &&
                        !isUploadingReport &&
                        !siteSourceIsCsv(site) &&
                        i18n.t("sendMyData")}
                      {editMode &&
                        !isUploadingReport &&
                        !siteSourceIsCsv(siteToEdit) &&
                        !siteSourceIsCsv(site) &&
                        i18n.t("sendMyData")}
                      {!editMode &&
                        !isUploadingReport &&
                        siteSourceIsCsv(site) &&
                        i18n.t("dataWasSent")}
                      {editMode &&
                        !isUploadingReport &&
                        (siteSourceIsCsv(siteToEdit) ||
                          siteSourceIsCsv(site)) &&
                        i18n.t("dataWasSent")}
                    </span>
                  </span>
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  marginTop: "15px",
                }}
              >
                <ul style={{ textAlign: "left" }}>
                  <li style={{ marginBottom: "10px", fontWeight: "400" }}>
                    {/* Pour l'envoi des données merci de respecter le format
                      texte suivant, avec les en-têtes, avec{" "}
                      <strong>10 lignes minimum</strong> en plus des en-têtes : */}
                    <Translation>
                      {(t) => `${t("step2DataFormat")}`}
                    </Translation>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={SiteConstants.CONSO_DATA_SAMPLE}
                    >
                      ({i18n.t("downloadConsoDataExample")})
                    </a>
                  </li>
                  <li>
                    <img
                      style={{ height: "auto", width: "100%" }}
                      alt="description fichier"
                      src={SiteConstants.ConsoData}
                    />
                  </li>
                </ul>
              </div>
            </CollapseConfig>

            <CollapseConfig
              className="collapse"
              headerText={i18n.t("step2UseProfileData")}
              isCollapsed={collapses.profile}
              handleOpenDropdown={this.handleOpenDropdown}
              dropdownName="profile"
              abbr={i18n.t("captions.profile")}
            >
              <ProfileConfigurator
                handleChangeSiteProfileType={handleChangeSiteProfileType}
                selectedProfile={site.profile}
                consumptionIndexes={site.consumptionIndexes}
                changeProfileMonthlyConsumption={
                  changeProfileMonthlyConsumption
                }
                handleToggleMonthlyIndex={handleToggleMonthlyIndex}
                monthlyConsumptionIndex={site?.monthlyConsumptionIndex}
              />
            </CollapseConfig>

            <div
              className="is-centered buttons has-addons"
              style={{ paddingBottom: "2%" }}
            >
              <CustomButton
                background="$lightgray"
                color="black"
                className="cancel-button"
                border="solid black 1px"
                hoverBackgroundColor="#C0C0C0"
                onClick={() => cancelEditor(site)}
                // loading={fetchingEnedis}
              >
                <Translation>{(t) => t("cancel")}</Translation>
              </CustomButton>
              <CustomButtonSecondary
                marginLeft="3%"
                onClick={handlePrevStep}
                disabled={isUploadingReport || fetchingEnedis}
              >
                <i
                  className="fa fa-angle-left"
                  style={{ marginRight: "10px" }}
                />{" "}
                <Translation>{(t) => `${t("previous")}`}</Translation>
              </CustomButtonSecondary>

              <CustomButton
                color={!canGoToStepThree() ? "white" : ""}
                disabled={
                  canGoToStepThree() ||
                  isUploadingReport ||
                  fetchingEnedis ||
                  ((customerConsentFirstName.length === 0 ||
                    customerConsentLastName.length === 0 ||
                    customerConsentEmail.length === 0) &&
                    enedisBuffer &&
                    !consentFileUrl)
                }
                onClick={
                  site.enedisBuffer && !isConsentMailSent && !consentFileUrl
                    ? this.handleShowModal
                    : handleNextStep
                }
                loading={fetchingEnedis}
              >
                <Translation>{(t) => `${t("next")}`}</Translation>
                <i
                  className="fa fa-angle-right"
                  style={{ marginLeft: "10px" }}
                />{" "}
              </CustomButton>
            </div>
          </Columns.Column>
        </Columns>

        {this.renderMailConsentModal()}
      </div>
    );
  }
}
