import React from "react";
import "./EditorSiteForm.scss";
import loadable from "loadable-components";
import LocaleContext from "../../context/LocaleContext";
import { CustomButton } from "../CustomButton";
import { CustomButtonSecondary } from "../CustomButtonSecondary";
import { CustomHoverPopUp } from "../CustomHoverPopUp";

const Step3Classic = loadable(() => import("./Step3Classic"));
const Step3TarifBuilder = loadable(() => import("./Step3TarifBuilder"));

export const TABS = { REGULAR: "REGULAR", PRICEBUILDER: "PRICEBUILDER" };

export default class Step3 extends React.Component {
  static contextType = LocaleContext;
  render() {
    const { tab, tarifBuilderProps, classicProps, handleSwitchTab, site } =
      this.props;
    const { Translation, i18n } = this.context;

    return (
      <div className="create-site-wrapper container">
        <div className="title is-4 has-text-centered">
          <div className="subtitle is-uppercase">
            <Translation>
              {(t) => `${t("step")} 3 ${t("outOfPreposition")} 3`}
            </Translation>
          </div>
          <Translation>{(t) => `${t("purchasePriceSettings")}`}</Translation>
        </div>
        <div
          className="tabs is-boxed is-toggle"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <CustomHoverPopUp
            padding="10% 5%"
            title={i18n.t("regularPrice")}
            content={i18n.t("captions.step3ClassicIndication")}
          >
            <CustomButton
              padding="12%"
              margin="0"
              isWindow
              roundedLeft
              onClick={() =>
                site.country !== "fr" ? null : handleSwitchTab(TABS.REGULAR)
              }
              className={`tab ${tab === TABS.REGULAR ? "is-active" : ""} ${
                site.country !== "fr" ? "disabled" : ""
              }`}
              current={tab !== TABS.REGULAR}
            >
              <Translation>{(t) => `${t("regularPrice")}`}</Translation>
            </CustomButton>
          </CustomHoverPopUp>

          <CustomHoverPopUp
            title={i18n.t("customPrice")}
            content={i18n.t("captions.step3TarifBuilderIndication")}
          >
            <CustomButton
              padding="10% 8%"
              marginLeft="15%"
              isWindow
              roundedRight
              border="none"
              current={tab !== TABS.PRICEBUILDER}
              onClick={() => handleSwitchTab(TABS.PRICEBUILDER)}
              className={`tab ${tab === TABS.PRICEBUILDER ? "is-active" : ""}`}
            >
              <Translation>{(t) => `${t("customPrice")}`}</Translation>
            </CustomButton>
          </CustomHoverPopUp>
        </div>
        {tab === TABS.REGULAR && <Step3Classic {...classicProps} />}
        {tab === TABS.PRICEBUILDER && (
          <Step3TarifBuilder {...tarifBuilderProps} />
        )}
      </div>
    );
  }
}
