import React, { useContext } from "react";
import { Columns, Form, Table, Tabs } from "react-bulma-components/full";
import SiteConstants from "../../../constants/Site";
import LocaleContext from "../../../context/LocaleContext";
import { ProfileTable } from "./ProfileTable";
import { getUserData } from "../../../store/authentication/AuthenticationStore";
import UserConstants from "../../../constants/User";
import { CustomButton } from "../../CustomButton";
export const ProfileConfigurator = (props) => {
  const { i18n } = useContext(LocaleContext);
  const {
    handleChangeSiteProfileType,
    selectedProfile,
    consumptionIndexes,
    changeProfileMonthlyConsumption,
    handleToggleMonthlyIndex,
    monthlyConsumptionIndex,
  } = props;
  const { domain } = getUserData();
  const profileChoices = Object.entries(SiteConstants.profilesDisplayNames);

  const shouldProfileBeDisabled = (profile) => {
    return (
      domain === UserConstants.DOMAINS.BTINF &&
      [
        SiteConstants._profilesDisplayNames.ENT_BT_SUP,
        SiteConstants._profilesDisplayNames.ENT_HTA,
      ].includes(profile)
    );
  };

  const handleProfiles = () => {
    if (
      selectedProfile === SiteConstants.profiles.RES_BASE_INF_6 ||
      selectedProfile === SiteConstants.profiles.RES_BASE_SUP_6
    )
      return SiteConstants._profilesDisplayNames.RES_BASE;
    return selectedProfile ?? "";
  };
  return (
    <Columns>
      <Columns.Column size={12}>
        <Form.Field>
          <Form.Control>
            <Form.Select
              value={handleProfiles()}
              onChange={handleChangeSiteProfileType}
              name="profile"
              style={{ width: "100%" }}
              className="select-pv"
            >
              {!selectedProfile && (
                <option value="">{i18n.t("selectProfile")}</option>
              )}
              {profileChoices.map(([key, value]) => (
                <option
                  key={key}
                  value={key}
                  disabled={shouldProfileBeDisabled(key)}
                  title={
                    shouldProfileBeDisabled(key) ? i18n.t("cta.BTINF") : ""
                  }
                >
                  {i18n.t(key)}
                </option>
              ))}
            </Form.Select>
          </Form.Control>
        </Form.Field>
      </Columns.Column>
      {selectedProfile && (
        <div
          className="tabs is-boxed is-toggle"
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "4%",
            border: "none",
          }}
        >
          <CustomButton
            isWindow
            roundedLeft
            borderStyle="none"
            current={!monthlyConsumptionIndex}
            onClick={() => handleToggleMonthlyIndex(true)}
            className={`tab ${monthlyConsumptionIndex ? "is-active" : ""}`}
            active={monthlyConsumptionIndex}
          >
            {i18n.t("monthly")}
          </CustomButton>
          <CustomButton
            isWindow
            roundedRight
            marginLeft="0px"
            border="none"
            current={monthlyConsumptionIndex}
            onClick={() => handleToggleMonthlyIndex(false)}
            active={!monthlyConsumptionIndex}
          >
            {i18n.t("yearly")}
          </CustomButton>
        </div>
      )}

      <Columns.Column size={12}>
        <Table>
          <ProfileTable
            profile={selectedProfile}
            consumptionIndexes={consumptionIndexes}
            changeProfileMonthlyConsumption={changeProfileMonthlyConsumption}
            monthlyConsumptionIndex={monthlyConsumptionIndex}
          />
        </Table>
      </Columns.Column>
    </Columns>
  );
};
