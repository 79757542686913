import React from "react";
import Header from "../../Header";
import logo from "../../../assets/img/logo.png";
import "./MainSkeleton.scss";
const MainSkeleton = () => {
  return (
    <>
      <Header />
      <div id="main-skeleton">
        <div className="logo-holder">
          <img src={logo} alt="logo" />
        </div>
      </div>
    </>
  );
};
export { MainSkeleton };
