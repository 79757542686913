import { BehaviorSubject } from "rxjs";

let siteData = {};

const siteData$ = new BehaviorSubject(siteData);

export const resetSiteData = () => {
  siteData = {};
  siteData$.next(siteData);
};

export const updateSiteData = (payload) => {
  siteData = {
    ...siteData,
    ...payload,
  };
  siteData$.next(siteData);
};

export const setSiteData = (payload) => {
  siteData = payload;
  siteData$.next(siteData);
};

export const getSiteData = () => siteData$._value;

export default siteData$;
