import { BehaviorSubject } from "rxjs";

let userData = {};

const userData$ = new BehaviorSubject(userData);

export const resetUserData = () => {
  userData = {};
  userData$.next(userData);
};

export const updateUserData = (payload) => {
  userData = {
    ...userData,
    ...payload,
  };
  userData$.next(userData);
};

export const getUserData = () => userData$._value;

export default userData$;
