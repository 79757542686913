import styled from "styled-components";

export const SiteTypeContainer = styled.div`
  width: 60%;
  margin: auto;
  height: 45vh;
  display: grid;
  padding: 1em 0;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(
    ${(props) => Math.round(props.numberOfChoices / 2)},
    1fr
  );
  grid-column-gap: 10%;
  grid-row-gap: 10%;
`;
