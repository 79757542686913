module.exports = {
  COLLECTION_USERS: "users",
  COLLECTION_SITES: "sites",
  COLLECTION_CONSENT: "consent",
  COLLECTION_SCENARIOS: "scenarios",
  COLLECTION_AUTH_CNI: "authentification-cni",
  COLLECTION_ADMIN: "admin",
  COLLECTION_ANALYSIS: "analysis",
  COLLECTION_LICENSES: "licenses",
  COLLECTION_DEVELOPPERS: "developpers",
  COLLECTION_COLLECTIVE_SITES: "collectiveSites",
  COLLECTION_DEFAULT_RATES: "defaultRates",
  TRASH_USER_ID: "DPLxaIKUVRf3lK0wcFl72ziheXc2",
};
