const fr_technologies = {
  STATIONS: {
    sellingPrice: "Prix de vente (c{{currency}}/kWh)",
    startTime: "Heure de début de mise en service",
    endTime: "Heure de fin de mise en service",
    months: "Mois d'utilisation",
    days: "Jours d'utilisation",
  },
  PROJECT: {
    addNewDemandRow: "Ajouter une ligne",
  },
  PV: {
    name: "Photovoltaïque",
    subventions: "Prime",
    subventionDuration: "Durée de la subvention",
  },
  PV2: {
    name: "Photovoltaïque 2",
    subventions: "Prime",
    subventionDuration: "Durée de la subvention",
  },
  WT: {
    name: "Eolien",
  },
  GENERAL: {
    leasingPayment: "Prix de la location",
  },
  BATTERY: {
    arbitrage: "Pilotage avec arbitrage économique",
  },
  GRID: {
    consumptionReference: "Consommation cible",
    reference:
      "Modifications appliquées à la consommation de référence du projet",
    post_rea:
      "Modifications appliquées à la consommation post réalisation du projet",
  },
};

const en_technologies = {
  STATIONS: {
    sellingPrice: "Selling price (c{{currency}}/kWh)",
    startTime: "Station start time",
    endTime: "Station end time",
    months: "Months of usage",
    days: "Days of usage",
  },
  PROJECT: {
    addNewDemandRow: "Add a row",
  },
  PV: {
    subventions: "Subventions",
    subventionDuration: "Subvention duration",
  },
  WT: {
    name: "Windturbine",
  },
  GENERAL: {
    leasingPayment: "Leasing payment",
  },
  BATTERY: {
    arbitrage: "Economical arbitrage",
  },
  GRID: {
    consumptionReference: "Target consumption",
    reference: "Modifying original project consumption",
    post_rea: "Modifying post project consumption",
  },
};

export { fr_technologies, en_technologies };
