import React, { useEffect } from "react";
const POP_UP =
  "https://firebasestorage.googleapis.com/v0/b/likewatt-4bb4b.appspot.com/o/utils%2Fpopup_noel.jpg?alt=media&token=0e209642-aeb7-486c-8cdc-471362656242";

const LINK_TO = "https://g.page/r/CeiWUGRJrO5LEB0/review";

const redirectToLink = () => {
  localStorage.setItem("event_popup_seen", true);
  window.open(LINK_TO, "_blank").focus();
};

const EventPopUp = (props) => {
  useEffect(() => {
    window.addEventListener("click", closePopUp);

    return () => {
      window.removeEventListener("click", closePopUp);
    };
  }, []);

  const { show, close } = props;

  const closePopUp = (e) => {
    {
      if (show) {
        if (document.getElementById("cta").contains(e.target)) {
          redirectToLink();

          close();
        } else {
          close();
        }
      }
      window.removeEventListener("click", closePopUp);

      localStorage.setItem("event_popup_seen", true);
      close();
    }
  };
  if (show)
    return (
      <div className="event-popup">
        <div className="content">
          <img id="cta" src={POP_UP} alt="popup"></img>
        </div>
      </div>
    );
  else {
    return null;
  }
};

export { EventPopUp };
