import React from "react";
import SiteConstants from "../../../constants/Site";
import { ProfileSlots } from "./ProfileTableComponents";

export const ProfileTable = (props) => {
  const { profile } = props;
  if (profile)
    return (
      <ProfileSlots
        {...props}
        slots={SiteConstants.timeSlotsByProfile[profile]}
      />
    );
  return null;
};
