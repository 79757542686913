import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import LocaleContext from "../context/LocaleContext";
import { Columns, Button, Form } from "react-bulma-components/full";
import { getUserData } from "../store/authentication/AuthenticationStore";
import { MAILTYPES } from "../constants/MailTypes";
import {
  toogleNotification,
  getNotificationsToShow,
} from "../store/notifications/NotificationsStore";
import { POP_UPS } from "../constants/Notifications";
import { sendMailToContactOrSupport } from "../services/CloudFunctions";
import { resetQueryParams } from "../helpers/Router";
import { countUnvalidFields } from "../utils/Utils";

const StyledTextArea = styled.textarea`
  width: 100%;
  height: 25rem;
  resize: vertical;
  padding: 1em;
`;

const Styledlabel = styled(Form.Label)`
  font-size: 1em !important;
  margin-top: 1em;
`;

const getMailfromQueryType = (queryType) => {
  switch (queryType) {
    case MAILTYPES.SUPPORT:
      return "support@likewatt.com";
    case MAILTYPES.CONTACT:
      return "contact@likewatt.com";
    default:
      break;
  }
};

const getDefaultSubjectFromType = (queryType) => {
  switch (queryType) {
    case MAILTYPES.SUPPORT:
      return "Demande de support";
    case MAILTYPES.CONTACT:
      return "Demande de devis";
    default:
      break;
  }
};

const getQueryType = () => {
  const [, query] = window.location.href.split("?");
  if (!query) return "";
  const [, queryType] = query.split("=");
  return queryType;
};

export const CustomMailEditor = () => {
  //To use the correct e-mail address
  useEffect(() => {
    const type = getQueryType();
    const to = getMailfromQueryType(type);
    if (!to) return toogleNotification(POP_UPS.SEND_MAIL);
    const subject = getDefaultSubjectFromType(type);
    setState((prev) => ({
      ...prev,
      to,
      type,
      subject,
    }));
    return () => {
      resetQueryParams();
    };
  }, []);

  const [sendingMail, setSendingMail] = useState(false);
  const [mailError, setMailError] = useState("");
  const [state, setState] = useState({
    subject: "",
    body: "",
    company: "",
  });

  const handleChangeState = ({ target: { name, value } }) => {
    setState((prev) => ({
      ...prev,
      mailError: "",
      [name]: value,
    }));
  };

  const handleSendMail = async () => {
    setMailError("");
    const { email, phone } = getUserData();
    const { type, subject, body, company } = state;
    const payload = {
      from: email,
      phone,
      type,
      subject,
      body,
      company,
    };
    setSendingMail(true);
    try {
      await sendMailToContactOrSupport(payload);
    } catch {
      setSendingMail(false);
      return setMailError(i18n.t("mail.failure"));
    } finally {
      setSendingMail(false);
    }
    setMailError(i18n.t("mail.success"));
    setTimeout(() => {
      //Prevents the pop up to show if it has been closed before the tiemout could elapse
      if (
        getNotificationsToShow().find((notif) => notif === POP_UPS.SEND_MAIL)
      ) {
        toogleNotification(POP_UPS.SEND_MAIL);
      }
    }, 1500);
  };

  const canSendMail = () => {
    const { body, subject, company } = state;
    if (sendingMail) return false;
    return countUnvalidFields(body, subject, company) === 0;
  };

  const { subject, body, company } = state;
  const { i18n } = useContext(LocaleContext);

  return (
    <Columns>
      <Columns.Column>
        <Styledlabel>
          {i18n.t("mail.to")} : {state.to}
        </Styledlabel>
        <Styledlabel>{i18n.t("mail.object")}*</Styledlabel>
        <Form.Input
          onChange={handleChangeState}
          name="subject"
          value={subject}
        />
        <Styledlabel>{i18n.t("mail.company")}*</Styledlabel>
        <Form.Input
          onChange={handleChangeState}
          name="company"
          value={company}
        />
        <Styledlabel>{i18n.t("mail.body")}*</Styledlabel>
        <StyledTextArea onChange={handleChangeState} name="body" value={body} />
        {!mailError && (
          <Button
            disabled={!canSendMail()}
            loading={sendingMail}
            color="success"
            onClick={handleSendMail}
          >
            {i18n.t("mail.send")}
          </Button>
        )}
        <div>{mailError}</div>
      </Columns.Column>
    </Columns>
  );
};
