export const handleRedirects = (history, pathname, payload) => {
  history.push({
    pathname,
    state: payload,
  });
};

export const getCurrentPage = () => {
  //localhost:3000/#/results will return /results
  const page = window.location.hash;
  return page.slice(1, page.length);
};

export const setQueryParams = (param) => {
  const originUrl = window.location.href;
  window.history.pushState(null, "", `${originUrl}?type=${param}`);
};

export const resetQueryParams = () => {
  const [urlBeforePopUp] = window.location.href.split("?");
  window.history.replaceState(null, "", urlBeforePopUp);
};
