import React from "react";
import to from "await-to-js";
import "./EditorSiteForm.scss";
import Firebase from "firebase";
import moment from "moment";
import RandomString from "randomstring";
import "react-datepicker/dist/react-datepicker.css";

import SiteService from "../../services/Site";
import UserService from "../../services/User";
import Auth from "../../services/Auth";

import ApiService from "../../services/Api";

import SiteHelper from "../../helpers/Site";
import { FileChecker } from "../../helpers/Files/FileChecker";
// import MunicipalTaxHelper from "../../helpers/MunicipalTax";
import DepartementTaxHelper from "../../helpers/DepartementalTax";
import Enedis from "../../helpers/Enedis";

import DepartementTaxConstants from "../../constants/DepartementalTax";
import SiteConstants from "../../constants/Site";
import ForeignSite from "../../helpers/ForeignSite";
import { countUnvalidFields, countUnvalidArrays } from "../../utils/Utils";
import Step0 from "./Step0";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import LocaleContext from "../../context/LocaleContext";
import { btInfModel } from "../../helpers/EnedisModels/BtInf";
import { defaultAreaOfTensionModel } from "../../helpers/EnedisModels/DefaultAreaOfTension";
import { updateThisSiteScenarios } from "./SiteUtils";
import { toogleNotification } from "../../store/notifications/NotificationsStore";
import { POP_UPS } from "../../constants/Notifications";
import { InfoPopUp } from "./InfoPopUp";
import UserConstants from "../../constants/User";
import { formatErrorStatus, extractEnedisErrorCode } from "../../utils/Utils";
import {
  hasContractSituation,
  isPRMConsumerSegment,
} from "../../helpers/Validators";
const TABS = { REGULAR: "REGULAR", PRICEBUILDER: "PRICEBUILDER" };
const COUNTRY_SHORTNAME = ["GP", "MQ", "GF", "RE", "YT"];
import { Loader } from "react-bulma-components";
import { getDefaultValues } from "../../store/defaultValues/DefaultValuesStore";

import { defaultRateModel } from "../../constants/DefaultRates";
import _, { last } from "lodash";
import { sendMailForConsent } from "../../services/CloudFunctions";
import dayjs from "dayjs";
import Consent from "../../services/Consent";
import { time } from "highcharts";
import i18n from "../../config/i18n";
import { CustomModal } from "../CustomModal";
let DEFAULT = {};
export default class EditorSiteForm extends React.Component {
  setStartingStepWithUser = () => {
    const { type, fromAdmin = false } = this.props;
    if (fromAdmin) return 3;
    switch (type) {
      case SiteConstants.type.CONSUMER:
        return 1;
      default:
        return 0;
    }
  };
  constructor(props) {
    super(props);
    DEFAULT = getDefaultValues(SiteConstants.type.CONSUMER);

    const {
      type,
      userDefaultRates,
      collectiveSite,
      isFullSellOut = false,
    } = props;

    this.state = {
      step: this.setStartingStepWithUser(),
      site: SiteHelper.initialStateSite(
        type,
        userDefaultRates,
        collectiveSite,
        isFullSellOut,
      ),
      uploadReportProgress: 0,
      actionRequired: false,
      reportData: [],
      isSubmitingSite: false,
      isUploadingReport: false,
      agreed: false,
      enedisData: null,
      fetchingEnedis: false,
      _ip: null,
      tab: TABS.REGULAR,
      isUploadingConsent: false,
      uploadConsentProgress: 0,
      isFetchingTimeZone: false,
      timeZoneError: "",
      shouldUpdateScenarios: false,
      showInfoPopUp: false,
      showModal: false,
      loadingEmail: false,
      isMailSent: false,
      allSubscribedPowersAreZero: false,
    };
  }
  static contextType = LocaleContext;

  handleChangeSiteType = (type) =>
    this.setState({ site: { ...this.state.site, type: type } });
  handleChangeInput = ({ target }) => {
    const site = { ...this.state.site, [target.name]: target.value };
    if (
      target.name === "purchaseMeterOwner" ||
      target.name === "ratesOption" ||
      target.name === "purchaseContractType"
    )
      site.analysisId = "";
    this.setState({
      site,
    });

    if (target.name === "country") {
      this.setState({
        site: {
          ...this.state.site,
          autoCo2: false,
          country: target.value,
        },
      });
    }
    // update subsite on collectiveSite in case the name changed
    if (this.props.isCollectiveSiteEditorMode && target.name === "name")
      this.props.updateSubSite(site);
  };

  handleChangeInputConsent = ({ target: { name, value } }) => {
    const site = { ...this.state.site, [name]: value };
    this.setState({ site });
  };
  handleChangeAreaOfTension = ({ target }) => {
    return this.setState({
      site: {
        ...this.state.site,
        [target.name]: target.value,
        ratesOption: SiteConstants.areaOfTensionRatesOption[target.value][0],
        analysisId: "",
        hasWeekendPricing: false,
        tempoPricing: false,
        hasPeakHours: false,
        includeTURPE:
          target.value === SiteConstants.purchaseAreaOfTension["BT<36 kVA"],
      },
    });
  };
  handleChangeInputFile = async (e) => {
    let err,
      fileURL,
      rows = null;
    let file = e.target.files[0];
    let { site } = this.state;

    this.setState({
      isUploadingReport: true,
      error: false,
    });

    [err] = await to(
      FileChecker(file, DEFAULT.consumerFiles.CONSO_DATA, false),
    );
    if (err) {
      return this.setState({
        isUploadingReport: false,
        error: err.message,
      });
    } else {
      const sitePath = site.collectiveSiteId
        ? `${site.collectiveSiteId}/Sites/${site.id}`
        : site.id;
      [err, fileURL] = await to(
        SiteService.uploadReport(file, sitePath, (snapshot) => {
          this.setState({
            uploadReportProgress:
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
          });

          switch (snapshot.state) {
            case Firebase.storage.TaskState.SUCCESS:
              console.log("File uploaded!");
              break;
            case Firebase.storage.TaskState.ERROR:
              console.error("Error when uploading file!");
              break;
            case Firebase.storage.TaskState.PAUSED:
              console.log("File upload has been paused!");
              break;
            case Firebase.storage.TaskState.CANCELED:
              console.warning("File upload has been canceled!");
              break;
            default:
              console.log();
          }
        }),
      );
      if (err) return console.error("erreur ? ", err);
      [err] = await to(ApiService.initDataFile(this.state.site.id));
      if (!err) {
        await SiteService.update(this.state.site.id, {
          ...this.state.site,
          enedisData: false,
          automaticUpdateError: null,
          profile: "",
          reportURL: fileURL,
          dataSource: "CSV",
          siteHasData: true,
        });
        const updatedDataSourceHistory =
          await SiteService.incrementSiteDataSourceHistory(
            this.state.site,
            "CSV",
          );

        try {
          await SiteService.deleteAnalysisFiles(
            this.state.site.id,
            this.state.site.collectiveSiteId,
          );
        } catch {
          console.log("No file to delete");
        }
        this.setState({
          site: {
            ...this.state.site,
            enedisData: false,
            reportURL: fileURL,
            automaticUpdateError: null,
            profile: "",
            dataSource: "CSV",
            siteHasData: true,
            dataSourceHistory: updatedDataSourceHistory,
          },
          isUploadingReport: false,
        });
      }
    }
  };
  handleCodeFromMarker = (latLng) => {
    this.setState({
      site: {
        ...this.state.site,
        addressGeocode: [latLng.lat, latLng.lng],
      },
    });
  };
  handleSelectAddressCityInput = (item) => {
    const { label, id, departement } = item;
    this.setState({
      site: {
        ...this.state.site,
        addressCity: label,
        addressDepartementNum: departement,
        addressDepartement:
          DepartementTaxHelper.getByDepartementalNum(departement)[
            DepartementTaxConstants.FIELD_NAME
          ],
      },
    });
  };
  handleSelectAddressDepartementInput = (item) => {
    const { label, id } = item;

    this.setState({
      site: {
        ...this.state.site,
        addressDepartement: label,
        addressDepartementNum: id,
      },
    });
  };

  handleChangeSubscribedPowerInput = (id, e) => {
    const name = e.target.name;
    const value = e.target.value;
    const { Utils } = this.context;
    let { subscribedPowers = [], TCSPE } = this.state.site;
    if (this.state.site.purchaseAreaOfTension === "BT>36 kVA") {
      subscribedPowers = subscribedPowers.map((subP /** subscribedPower */) =>
        subP.id === id
          ? { ...subP, [name]: value, PTE: name === "HPH" ? value : subP.PTE }
          : subP,
      );
    }

    if (
      this.state.site.purchaseAreaOfTension !== "BT<36 kVA" ||
      this.state.site.purchaseAreaOfTension !== "BT>36 kVA"
    ) {
      subscribedPowers = subscribedPowers.map((subP /** subscribedPower */) =>
        subP.id === id ? { ...subP, [name]: value } : subP,
      );
    }

    if (this.state.site.purchaseAreaOfTension === "BT<36 kVA") {
      subscribedPowers = subscribedPowers.map((subP /** energyPrice */) => {
        if (subP.id === id)
          return {
            ...subP,
            HCB: value,
            HCH: value,
            HPH: value,
            HPB: value,
            PTE: value,
          };
        // else return { ...enyP, HCH: value, [name]: value };
      });
    }

    // Vérification si toutes les valeurs numériques de tous les subscribedPowers sont égales à 0
    const allValuesAreZero = subscribedPowers.every((subP) => {
      // Récupérer toutes les valeurs numériques de chaque subscribedPower
      const numericValues = Object.values(subP)
        .filter((val) => !isNaN(parseFloat(val))) // Garder uniquement les valeurs numériques (chaînes ou nombres)
        .map((val) => parseFloat(val)); // Convertir les chaînes en nombres
      // Vérifier si toutes ces valeurs numériques sont égales à 0
      return numericValues.every((val) => val === 0);
    });

    this.setState({
      site: {
        ...this.state.site,
        subscribedPowers,
        TCSPE: Utils.getTCSPEFromSubscribedPowers(subscribedPowers, TCSPE),
      },
      allSubscribedPowersAreZero: allValuesAreZero, // Nouveau state à true si toutes les valeurs sont 0
    });
  };

  checkAllSubscribedPowers = () => {
    const { site } = this.state;
    const {
      subscribedPowers = [],
      tarifs,
      tarifBuilder,
      restOfTheYearTarifs,
    } = site;

    const hasOnlyZeroValues = (obj) =>
      Object.values(obj)
        .filter((val) => !isNaN(parseFloat(val)))
        .every((val) => parseFloat(val) === 0);

    const allSubscribedPowersAreZero =
      subscribedPowers.every(hasOnlyZeroValues);

    const displayWarningPopUp = tarifBuilder
      ? tarifs.every((val) => parseFloat(val.subscribedPower) === 0) &&
        parseFloat(restOfTheYearTarifs.subscribedPower) === 0
      : allSubscribedPowersAreZero;

    if (displayWarningPopUp) {
      this.setState({ showModal: true });
    } else {
      this.handleSubmitSite();
    }
  };

  handleAddSubscribedPowersLine = () =>
    this.setState({
      site: {
        ...this.state.site,
        subscribedPowers: this.state.site.subscribedPowers.concat(
          SiteHelper.newStateSubscribedPower(this.state.site),
        ),
      },
    });

  handleDeleteSubscribedPowersLine = (id) => {
    return this.setState({
      site: {
        ...this.state.site,
        subscribedPowers: this.state.site.subscribedPowers.filter(
          (subP /** subscribedPower */) => subP.id !== id,
        ),
        analysisId: "",
      },
    });
  };
  handleChangeEnergyPriceInput = (id, e) => {
    const name = e.target.name;
    let value = e.target.value;
    const { site } = this.state;
    let { energyPrices = [], ratesOption, hasWeekendPricing } = site;
    // if (value === "" || parseInt(value) < 0) value = "0";

    if (!site.hasWeekendPricing && !site.hasPeakHours) {
      energyPrices = energyPrices.map((enyP /** energyPrice */) => {
        return {
          ...enyP,
          HCB: value,
          HCH: value,
          HPB: value,
          HPH: value,
          PTE: value,
        };
      });
    }

    if (site.purchaseAreaOfTension !== "BT<36 kVA") {
      energyPrices = energyPrices.map((enyP /** energyPrice */) =>
        enyP.id === id ? { ...enyP, [name]: value } : enyP,
      );
      if (site.purchaseAreaOfTension === "BT>36 kVA")
        energyPrices.map((e) => (e.id === id ? { ...e, PTE: e.HPH } : e));
    }
    if (site.purchaseAreaOfTension === "BT<36 kVA") {
      energyPrices = energyPrices.map((enyP /** energyPrice */) => {
        if (enyP.id === id) {
          if (hasWeekendPricing) {
            if (name === "HPB") {
              return { ...enyP, PTE: value, HPH: value, [name]: value };
            } else {
              return {
                ...enyP,
                HCH: value,
                [name]: value,
              };
            }
          } else {
            switch (ratesOption) {
              case SiteConstants.ratesOption.CU:
              case SiteConstants.ratesOption.LU:
              case SiteConstants.ratesOption.MUDT:
                if (name === "HPB") {
                  return { ...enyP, PTE: value, HPH: value, [name]: value };
                } else {
                  return {
                    ...enyP,
                    HCH: value,
                    [name]: value,
                  };
                }
              default:
                return { ...enyP, [name]: value, PTE: value };
            }
          }
        }
      });
    }

    this.setState({ site: { ...site, energyPrices, analysisId: "" } });
  };
  handleChangeEnergyTempoPriceInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const { site } = this.state;
    const { energyPricesTempo = [] } = site;

    const updatedEnergyTempoPrices = {
      ...energyPricesTempo[0], // Copie de l'objet existant
      [name]: value,
    };
    console.log({ site });

    this.setState({
      site: {
        ...site,
        energyPricesTempo: [updatedEnergyTempoPrices],
        analysisId: "",
      },
    });
  };

  handleAddEnergyPricesLine = () =>
    this.setState({
      site: {
        ...this.state.site,
        energyPrices: this.state.site.energyPrices.concat(
          SiteHelper.newStateEnergyPrice(this.state.site),
        ),
      },
    });
  handleDeleteEnergyPriceLine = (id) => {
    return this.setState({
      site: {
        ...this.state.site,
        energyPrices: this.state.site.energyPrices.filter(
          (enyP /** energyRate */) => enyP.id !== id,
        ),
        analysisId: "",
      },
    });
  };
  handleChangeOffPeakHourStartTime = (id, startTime) => {
    const { site } = this.state;
    let { offPeakHours = [] } = site;
    offPeakHours = offPeakHours.map((pkH /** offPeakHour */) =>
      pkH.id === id ? { ...pkH, startTime: startTime } : pkH,
    );
    this.setState({ site: { ...site, offPeakHours, analysisId: "" } });
  };
  handleChangeOffPeakHourEndTime = (id, endTime) => {
    const { site } = this.state;
    let { offPeakHours = [] } = site;
    if (endTime === 0) endTime = 24;
    offPeakHours = offPeakHours.map((pkH /** offPeakHour */) =>
      pkH.id === id ? { ...pkH, endTime: endTime } : pkH,
    );

    this.setState({ site: { ...site, offPeakHours, analysisId: "" } });
  };

  handleSwitchPrice = (price) => {
    const { site } = this.state;
    switch (price) {
      case "HP/HC":
        this.setState({
          site: {
            ...site,
            hasPeakHours: true,
            hasWeekendPricing: false,
            tempoPricing: false,
          },
        });
        break;
      case "WE":
        this.setState({
          site: {
            ...site,
            hasPeakHours: false,
            hasWeekendPricing: true,
            tempoPricing: false,
          },
        });
        break;
      case "TEMPO":
        this.setState({
          site: {
            ...site,
            hasPeakHours: false,
            hasWeekendPricing: false,
            tempoPricing: true,
          },
        });
        break;
      default:
        this.setState({
          site: {
            ...site,
            hasPeakHours: false,
            hasWeekendPricing: false,
            tempoPricing: false,
          },
        });
        break;
    }
  };

  handleSwitchPriceWE = () => {
    this.setState({
      site: {
        ...this.state.site,
        hasPeakHours: false,
        hasWeekendPricing: true,
        tempoPricing: false,
      },
    });
  };
  handleChangeOffPeakHourInput = (id, e) => {
    const name = e.target.name;
    const value = e.target.value;
    const { site } = this.state;
    let { offPeakHours = [] } = site;
    offPeakHours = offPeakHours.map((pkH /** offPeakHour */) =>
      pkH.id === id ? { ...pkH, [name]: value } : pkH,
    );
    this.setState({ site: { ...site, offPeakHours, analysisId: "" } });
  };
  handleAddOffPeakHoursLine = () => {
    return this.setState({
      site: {
        ...this.state.site,
        offPeakHours: this.state.site.offPeakHours
          ? this.state.site.offPeakHours.concat(
              SiteHelper.initialStateOffPeakHour(),
            )
          : [].concat(SiteHelper.initialStateOffPeakHour()),
      },
    });
  };
  handleDeleteOffPeakHourLine = (id) => {
    return this.setState({
      site: {
        ...this.state.site,
        offPeakHours: this.state.site.offPeakHours.filter(
          (pkH /** offPeakHour */) => pkH.id !== id,
        ),
        analysisId: "",
      },
    });
  };

  uploadProof = async (site, user) => {
    const { lastname, firstname, address, email } = user;
    const {
      id,
      collectiveSiteId,
      _agreedAt,
      _step3,
      _step3At,
      enedisNumber,
      agreed,
    } = site;
    const { _ip } = this.state;
    let err = null;
    let payload = {
      nom: lastname,
      prénom: firstname,
      adresse: address,
      email: email,
      consentement: agreed,
      date_Consentement: _agreedAt,
      clic: _step3,
      date_clic: _step3At,
      ip: _ip,
      prm: enedisNumber,
    };
    const sitePath = collectiveSiteId ? `${collectiveSiteId}/Sites/${id}` : id;
    const res = await to(SiteService.uploadEnedisProof(sitePath, payload));
    console.log({ res });
  };

  handleStep3ParametersUpdate = () =>
    this.setState({ shouldUpdateScenarios: true });

  euroToCent = (data) => {
    if (!Array.isArray(data) || data.length === 0) {
      throw new Error(
        "Le paramètre doit être un tableau avec au moins un objet",
      );
    }
    // Remove useless object values before the conversion
    return data.map(({ endDate, startDate, id, ...rest }) => {
      const values = Object.values(rest)
        .map(parseFloat)
        .filter((value) => !isNaN(value));

      // We check if all values < 1.5 before to convert in CT
      const shouldConvert = values.every((value) => value < 1.5);

      if (shouldConvert) {
        return Object.fromEntries(
          Object.entries(rest).map(([key, value]) => {
            const valueFloat = parseFloat(value);

            if (!isNaN(valueFloat)) {
              return [key, valueFloat * 100];
            }

            return [key, value];
          }),
        );
      }
      return rest;
    });
  };

  handleSubmitSite = async () => {
    this.setState({ isSubmitingSite: true });

    let err = null;
    const { onSubmitSuccess, editMode, siteToEdit } = this.props;

    let newsite = {
      ...this.state.site,
      isFullSellOut: this.state.site.isFullSellOut || false,
      currency: this.state.site.currency || "EUR",
    };

    let { site, agreed, user, _agreedAt, tab } = this.state;

    if (tab === TABS.PRICEBUILDER) {
      return this.handleSubmitTarif();
    }

    // await this.replaceEmptyValues();
    if (this.state.site.purchaseAreaOfTension === "BT>36 kVA") {
      for (let e of newsite.subscribedPowers) {
        e.PTE = e.HPH;
      }
      for (let e of newsite.energyPrices) {
        e.PTE = e.HPH;
      }
    }
    if (this.state.site.purchaseAreaOfTension === "BT<36 kVA") {
      for (let e of newsite.subscribedPowers) {
        e.PTE = e.HPH;
      }
      for (let e of newsite.energyPrices) {
        e.PTE = e.HPH;
      }
    }
    // We convert energyPrices in cents if user add values < 1.5
    newsite.energyPrices = this.euroToCent(newsite.energyPrices);
    newsite.energyPricesWe = this.euroToCent(newsite.energyPricesWe);
    newsite.energyPricesTempo = this.euroToCent(newsite.energyPricesTempo);

    if (newsite?.profile === SiteConstants._profilesDisplayNames.RES_BASE) {
      const maxSubP = Math.max(
        ...newsite.subscribedPowers.map(
          ({ endDate, startDate, id, ...rest }) => ({ ...rest }),
        ),
      );
      if (maxSubP <= 6) {
        newsite.profile = SiteConstants.profiles.RES_BASE_INF_6;
      } else newsite.profile = SiteConstants.profiles.RES_BASE_SUP_6;
    }

    console.log("newSite in step 3", { newsite });

    newsite.TCSPE = parseFloat(newsite.TCSPE);
    newsite.subscriptionRates =
      parseFloat(
        Array.isArray(newsite.subscriptionRates)
          ? newsite.subscriptionRates[0].pricePerYear
          : newsite.subscriptionRates,
      ) || 0;

    newsite.co2rate = parseFloat(newsite.co2rate);
    if (newsite.autoCo2) {
      newsite.co2rate = null;
    }
    //If first creation OR editMode from CSV to PRM
    if (!editMode || (editMode && !siteToEdit.enedisNumber)) {
      //Prevent erasing reportURL with the local one while it is being retrieved by API
      if (newsite.enedisNumber) {
        delete newsite.reportURL;
        delete newsite.automaticUpdateError;
        delete newsite.updateStatus;
      }
    }
    newsite.endDate = newsite?.endDate || new Date();
    newsite.startDate = newsite?.endDate || new Date();
    // console.log({ site });
    // console.log({ newsite });
    if (site?.profile) {
      newsite.reportURL = "";
      newsite.automaticUpdateError = null;
      newsite.dataSource = "PROFILE";
      newsite.enedisData = false;
      newsite.dataSourceHistory =
        await SiteService.incrementSiteDataSourceHistory(newsite, "PROFILE");
    }

    // if (newsite.enedisNumber !== "") {
    //   console.log("uploading proof");
    //   this.uploadProof(newsite, user);
    // }

    if (typeof newsite.timezone === "undefined") {
      console.log("No timezone");
      newsite.timezone = await this.getTimeZone();
    }

    if (newsite.isFullSellOut) {
      try {
        await SiteHelper.handleTMYRequests(site); //trigger PVGIS ONLY
      } catch (e) {
        console.log({ e });
        return this.setState({
          fetchingEnedis: false,
          error: formatErrorStatus(503),
        });
      }
    }

    newsite.endDate = new Date();
    newsite.startDate = new Date();

    const consent = await Consent.getSiteConsent(site.id);
    if (consent.length > 0 || newsite.consentFileUrl.length > 0) {
      newsite.hasConsentData = true;
      newsite.isConsentMailSent = true;
      newsite.disabled = false;
    }

    [err] = await to(SiteService.update(this.state.site.id, newsite));

    // if (this.state.site.enedisNumber === "")
    //   [err] = await to(ApiService.initDataFile(this.state.site.id));

    if (err) {
      this.setState({ error: err.data.message, isSubmitingSite: false });
      return Promise.reject(err);
    }

    const sitePath = newsite.collectiveSiteId
      ? `${newsite.collectiveSiteId}/Sites/${newsite.id}`
      : newsite.id;
    if (newsite?.profile && !newsite.reportURL) {
      await SiteService.deleteAnalysisFiles(
        newsite.id,
        newsite.collectiveSiteId,
      );

      ApiService.createConsoDataFromProfile(newsite);
    }

    if (this.state.shouldUpdateScenarios) {
      console.log("SCENARIOS SHOULD BE UPDATED");
      this.setState({ showInfoPopUp: true });
      await updateThisSiteScenarios(newsite);
      await SiteService.deleteAnalysisFiles(
        newsite.id,
        newsite.collectiveSiteId,
      );
      this.setState({ showInfoPopUp: false });
    }
    console.log("NEWSITE", newsite);
    onSubmitSuccess(newsite);
    this.setState({ isSubmitingSite: false, error: null });
  };

  handleNextStep = async () => {
    const {
      editMode,
      siteToEdit,
      isCollectiveSiteEditorMode = false,
    } = this.props;
    const { site, error } = this.state;
    if (this.state.step === 2) {
      console.log("SITE STEP 2", site);

      /** On clicking next in step 2 */
      /** First creation */
      if (!editMode || siteToEdit.addressGeocode !== site.addressGeocode) {
        this.setState({
          fetchingEnedis: true,
        });
        await SiteService.update(this.state.site.id, site); // update to register adress
        if (!isCollectiveSiteEditorMode) {
          try {
            await SiteHelper.handleTMYRequests(site); //trigger PVGIS ONLY
          } catch (e) {
            console.log({ e });
            await SiteService.update(this.state.site.id, {
              ...site,
              TMYstatus: 500,
            });
            return this.setState({
              fetchingEnedis: false,
              error: formatErrorStatus(503),
            });
          }
        }
        await SiteService.update(this.state.site.id, {
          ...site,
          TMYstatus: 200,
        });
        this.setState({
          fetchingEnedis: false,
        });
      }
      /** If PRM */
      if (site.enedisNumber && !site.purchaseAreaOfTension && !site.reportURL) {
        this.setState({ fetchingEnedis: true });
        await SiteService.update(this.state.site.id, site);
        const [contractErr, contractRes] = await to(this.enedisSubmit()); // Contract information AND Site update in BDD
        console.log({ contractErr, contractRes });
        if (!contractErr) {
          ApiService.forceUpdateData(this.state.site.id);
        } else
          return this.setState({
            fetchingEnedis: false,
            error: formatErrorStatus(contractErr),
          });

        //trigger data fetching from enedis via API
        console.log({ error, consent: site.consentFileUrl.length !== 0 });
        if (site.consentFileUrl.length !== 0) {
          this.setState({
            site: {
              ...this.state.site,
              hasConsentData: true,
            },
          });
        }
        if (
          !this.state.fetchingEnedis &&
          site.enedisData !== null &&
          !contractErr
        ) {
          return this.setState({
            step: this.state.step + 1,
            site: {
              ...this.state.site,
              _step3: true,
              _step3At: moment().format(),
            },
          });
        } else return 0;
      } else {
        this.setState({
          site: {
            ...this.state.site,
            _step3: true,
            _step3At: moment().format(),
          },
        });
      }
    }
    this.setState({ step: this.state.step + 1 });
  };
  handlePrevStep = () => {
    this.setState({ step: this.state.step === 1 ? 1 : this.state.step - 1 });
  };

  switchToStep = (step) => this.setState({ step });

  canGoToStepTwo = () => {
    const { site = {} } = this.state;
    const { isCollectiveSiteEditorMode } = this.props;
    const { name, address } = site;
    if (isCollectiveSiteEditorMode && name.length !== 0) return true;
    if (name.length !== 0 && address.length !== 0) return true;
    return false;
  };
  canSubmitSite = () => {
    const { site = {}, error } = this.state;
    const {
      purchaseContractType,
      purchaseMeterOwner,
      purchaseAreaOfTension,
      ratesOption,
      subscribedPowers,
      energyPrices,
      subscriptionRates,
      energyPricesWe,
      energyPricesTempo,
      TCSPE,
      offPeakHours = [],
    } = site;
    const unvalidArrays = countUnvalidArrays(
      subscribedPowers,
      energyPrices,
      energyPricesWe,
      energyPricesTempo,
      offPeakHours,
    );
    const unvalidFields = countUnvalidFields(
      subscribedPowers.map(({ endDate, startDate, ...rest }) => rest),
      energyPrices.map(({ endDate, startDate, ...rest }) => rest),
      energyPricesWe.map(({ endDate, startDate, ...rest }) => rest),
      Array.isArray(subscriptionRates)
        ? subscriptionRates[0]
        : subscriptionRates,
      TCSPE,
      offPeakHours,
    );
    if (unvalidArrays > 0 || unvalidFields > 0) {
      return false;
    }

    if (
      purchaseContractType.length !== 0 &&
      purchaseMeterOwner.length !== 0 &&
      purchaseAreaOfTension.length !== 0 &&
      ratesOption.length !== 0 &&
      subscribedPowers.length !== 0 &&
      energyPrices.length !== 0 &&
      //   typeof this.state.site?.timezone !== "undefined" &&
      !error
    )
      return true;
    return false;
  };

  formatDepartmentNumber = (place) => {
    //get Department number without zipCode

    const hasZipCode = place.address_components.find((e) =>
      e.types.find((type) => type === "postal_code"),
    );
    const isCorsica =
      place.address_components.find((address) =>
        address.types.find((type) => type === "administrative_area_level_1"),
      ).long_name === "Corse";

    const isOversea = COUNTRY_SHORTNAME.includes(
      place.address_components.find((address) =>
        address.types.find((type) => type === "country"),
      ).short_name,
    );
    // Trimming department to remove 0 in first position, or returns the first two figures
    let addressDepartementNum =
      place.address_components
        .find((e) => e.types.find((type) => type === "postal_code"))
        ?.long_name.charAt(0) === "0"
        ? place.address_components
            .find((e) => e.types.find((type) => type === "postal_code"))
            ?.long_name.charAt(1)
        : place.address_components
            .find((e) => e.types.find((type) => type === "postal_code"))
            ?.long_name.substring(0, 2);

    if (!hasZipCode && !isOversea) {
      let departement = place.address_components
        .find((e) =>
          e.types.find((type) => type === "administrative_area_level_2"),
        )
        .long_name.toUpperCase();
      departement =
        DepartementTaxHelper.getDepartementalNumberByName(departement);

      return departement;
    }
    // Dealing with Corse as Google returns 20 instead of 02B or 02A
    if (isCorsica) {
      let departement = place.address_components.find((e) =>
        e.types.find((type) => type === "administrative_area_level_2"),
      ).long_name;
      switch (departement) {
        case "Haute-Corse":
          return "02B";
        default:
          return "02A";
      }
    }

    if (isOversea) {
      let departementName = place.address_components.find((e) =>
        e.types.find((type) => type === "country"),
      ).long_name;
      if (departementName === "Guyane française") departementName = "Guyane";
      addressDepartementNum = DepartementTaxHelper.getDepartementalNumberByName(
        departementName.toUpperCase(),
      );
    }
    return addressDepartementNum;
  };
  buildAddressObject = (place, country = "fr") => {
    // console.log({ place });
    let address = {};
    const FRANCE = ["fr", "re", "gp", "mq", "gf"];
    if (!FRANCE.includes(country)) {
      this.setState({
        tab: TABS.PRICEBUILDER,
        site: {
          ...this.state.site,
          address: place.formatted_address,
          addressCity: "LYON",
          addressDepartement: "RHÔNE",
          addressDepartementNum: 69,
          addressZipCode: "69003",
          addressGeocode: [
            place.geometry.location.lat(),
            place.geometry.location.lng(),
          ],
        },
      });
      return (address = {
        addressDepartementNum: 69,
      });
    }

    this.setState({
      site: {
        ...this.state.site,
        address: place.formatted_address,
        addressCity: (
          place.address_components.find((e) =>
            e.types.find((type) => type === "locality"),
          ) ||
          place.address_components.find((e) =>
            e.types.find((type) => type === "soblocality"),
          ) ||
          place.address_components.find((e) =>
            e.types.find((type) => type === "route"),
          )
        ).long_name.toUpperCase(),
        addressGeocode: [
          place.geometry.location.lat(),
          place.geometry.location.lng(),
        ],
      },
    });
    if (
      place.address_components.find((e) =>
        e.types.find((type) => type === "administrative_area_level_2"),
      )
    )
      this.setState({
        site: {
          ...this.state.site,
          addressDepartement: place.address_components
            .find((e) =>
              e.types.find((type) => type === "administrative_area_level_2"),
            )
            .long_name.toUpperCase(),
        },
      });

    let addressCity = place.address_components
      .find((e) => e.types.find((type) => type === "locality"))
      .long_name.toUpperCase();

    let addressDepartementNum = this.formatDepartmentNumber(place);
    this.setState({
      site: {
        ...this.state.site,
        address: place.formatted_address,
        addressCity,
        addressZipCode:
          place.address_components.find((e) =>
            e.types.find((type) => type === "postal_code"),
          )?.long_name || addressDepartementNum.toString(),
        addressGeocode: [
          place.geometry.location.lat(),
          place.geometry.location.lng(),
        ],
      },
    });

    return (address = {
      addressDepartementNum: parseInt(addressDepartementNum),
    });
  };

  getTimeZone = async () => {
    this.setState({ isFetchingTimeZone: true });

    try {
      let timezone = await ApiService.getTimeZone({
        lat: this.state.site.addressGeocode[0],
        lng: this.state.site.addressGeocode[1],
      });

      this.setState({
        site: {
          ...this.state.site,
          timezone: timezone,
        },
        isFetchingTimeZone: false,
      });

      return timezone;
    } catch (error) {
      console.error("Error fetching timezone:", error);
      this.setState({ isFetchingTimeZone: false });
      throw error;
    }
  };

  handleCodeFromGoogle = async (place) => {
    const { site } = this.state;
    const { country } = site;

    let cityObject;
    try {
      cityObject = this.buildAddressObject(place, country);
    } catch (err) {
      return Promise.reject("ERROR SIREN");
    }
    let { addressDepartementNum } = cityObject;
    this.setState({
      site: {
        ...this.state.site,
        addressDepartementNum,
      },
    });
    try {
      await this.getTimeZone();
    } catch (e) {
      return this.setState({
        timeZoneError: e.response.data.message,
      });
    } finally {
      this.setState({ isFetchingTimeZone: false });
    }
  };

  getMapInitialValue = () => {
    return this.state.site.address;
  };

  handleEnedis = ({ target }) => {
    const regEx = new RegExp("^[0-9]{0,14}$");
    let valid = regEx.test(target.value);
    if (valid) {
      this.setState({
        error: "",
        agreed: false,
        site: {
          ...this.state.site,
          enedisNumber: target.value,
          enedisBuffer: target.value,
        },
      });
    }
  };

  handleAgreement = ({ target: { name, checked } }) => {
    SiteService.deleteAnalysisFiles(
      this.state.site.id,
      this.state.site?.collectiveSiteId,
    );
    return this.setState({
      [name]: checked,
      _agreedAt: moment().format(),
      // We need to reset reportURL & purchaseAreaOfTension to trigger ENEDIS after CSV or PROFILE
      site: {
        ...this.state.site,
        reportURL: "",
        purchaseAreaOfTension: "",
      },
    });
  };

  handleSubmitProducerSite = async () => {
    this.setState({ isSubmitingSite: true });
    return this.handleSubmitTarif();
  };

  canGoToStepThree = () => {
    //if Demo user, then Consent File is compulsory

    // if CSV then enedis number must be empty
    if (this.state.site.profile && this.state.site?.consumptionIndexes) {
      let valid = false;
      Object.entries(this.state.site.consumptionIndexes).map(
        ([month, array]) => {
          //   const sumOfArray = array.reduce((prev, curr) => prev + curr, 0);
          if (array.includes(NaN)) {
            valid = true;
            return;
          }
        },
      );
      return valid;
    }

    if (
      this.state.site.reportURL &&
      (this.props?.siteToEdit?.enedisNumber === this.state.site.enedisBuffer ||
        !this.state.site.enedisBuffer)
    ) {
      return false;
    }
    // if not CSV, enedisNumber must be valid

    if (!this.state.site.reportURL && this.state.site.enedisNumber) {
      return false;
    }

    return true;
  };

  getEnedisData = async (enedisNumber) => {
    const { site, agreed, user, _agreedAt, tab } = this.state;
    const { siteToEdit, editMode } = this.props;
    console.log({ enedisNumber });
    if (this.state.enedisData !== null) return;

    let data,
      res,
      err = null;
    let startDate = moment().subtract(2, "y");
    startDate = moment(startDate).format();
    let endDate = moment().format();
    let id = site.id;

    await SiteService.update(this.state.site.id, {
      ...this.state.site,
      automaticUpdateError: null,
    });
    [err, data] = await to(ApiService.getContractInfo(enedisNumber));

    if (data.data) {
      let error;
      console.log(data.data);
      if (data.data.message === "unsubscribedPoint") {
        error = "Erreur : Le point a été résilié";
      } else if (data.data.message === "Enedis API timeout") {
        error =
          "Serveur Enedis surchargé, relancez l'extraction ultérieurement";
      } else if (extractEnedisErrorCode(data.data.message).includes("SGT401")) {
        error = "Demande non recevable : point inexistant";
      } else if (
        extractEnedisErrorCode(data.data.message).includes("ILE500") ||
        extractEnedisErrorCode(data.data.message).includes("SGT500")
      ) {
        error =
          "Serveur Enedis surchargé, relancez l'extraction ultérieurement";
      } else {
        error =
          data.data?.["soapenv:Envelope"]?.["soap:Body"]?.["ns3:Fault"]
            ?.faultstring?._text || data.data.statusCode;
      }

      this.setState({
        fetchingEnedis: false,
        error: formatErrorStatus(error) || error,
        site: {
          ...this.state.site,
          automaticUpdateError: formatErrorStatus(error) || error,
          faltyPrm: enedisNumber,
        },
      });
      SiteService.update(this.state.site.id, {
        ...this.state.site,
        agreed: false,
        automaticUpdateError: formatErrorStatus(error) || error,
        faltyPrm: enedisNumber,
      });

      return Promise.reject(error);
    }

    if (data) {
      const isConsumerSegment =
        hasContractSituation(data) || isPRMConsumerSegment(data);

      let error = "PRODUCER PRM";
      if (!isConsumerSegment) {
        this.setState({
          fetchingEnedis: false,
          error: formatErrorStatus(error) || error,
          site: {
            ...this.state.site,
            automaticUpdateError: formatErrorStatus(error) || error,
            faltyPrm: enedisNumber,
            enedisNUmber: "",
          },
        });
        return Promise.reject(error);
      }
      error = "BASIC_LIMIT";
      if (
        user.domain === UserConstants.DOMAINS.BTINF &&
        data.situationAlimentation.alimentationPrincipale.domaineTension
          ._attributes.code !== "BTINF"
      ) {
        toogleNotification(POP_UPS.BTINF);
        this.setState({
          fetchingEnedis: false,
          error: formatErrorStatus(error) || error,
          site: {
            ...this.state.site,
            automaticUpdateError: formatErrorStatus(error) || error,
            faltyPrm: enedisNumber,
            enedisNUmber: "",
          },
        });
        SiteService.update(this.state.site.id, {
          ...this.state.site,
          agreed: false,
          automaticUpdateError: formatErrorStatus(error) || error,
          faltyPrm: enedisNumber,
          enedisNUmber: "",
        });

        return Promise.reject(error);
      }
    }
    //
    if (editMode) {
      if (site.enedisNumber !== "") {
        await SiteService.deleteAnalysisFiles(
          site.id,
          this.state.site?.collectiveSiteId,
        );
        this.setState({ site: { ...site } }); //TEST FORCE UPDATE
      }
    } else {
      if (site.enedisNumber !== "" && site.enedisNumber !== null) {
        this.setState({
          site: { ...site, reportURL: "" }, //TEST FORCE UPDATE
        });
      }
    }
    // if (site.enedisNumber !== "") this.uploadProof(site, user);
    if (
      data.situationAlimentation.alimentationPrincipale.domaineTension
        ._attributes.code !== "BTINF"
    ) {
      const updateDataSourceHistory =
        await SiteService.incrementSiteDataSourceHistory(
          site,
          SiteConstants.DATA_SOURCES.ENEDIS,
        );
      if (editMode && this.state.error !== "") {
        SiteService.setFaltyPrm(id, null);
        if (enedisNumber === data._attributes.id) {
          this.setState({
            fetchingEnedis: false,
            error: "",
            site: {
              ...defaultAreaOfTensionModel(data, site, Enedis),
              faltyPrm: null,
              reportURL: siteToEdit.reportURL || "",
              dataSourceHistory: updateDataSourceHistory,
            },
          });
          SiteService.update(site.id, {
            ...this.state.site,
            automaticUpdateError: null,
            dataSourceHistory: updateDataSourceHistory,
          });
          return Promise.resolve({ status: "OK" });
        }
      } else {
        SiteService.setFaltyPrm(id, null);

        this.setState({
          fetchingEnedis: false,
          error: "",
          site: {
            ...defaultAreaOfTensionModel(data, site, Enedis),
            dataSourceHistory: updateDataSourceHistory,
          },
        });
        SiteService.update(site.id, this.state.site);
        return Promise.resolve({ status: "OK" });
      }
    } else {
      return await this.handleBTINF(data, enedisNumber);
    }
  };

  handleBTINF = async (data, enedisNumber) => {
    const { site } = this.state;
    const updateDataSourceHistory =
      await SiteService.incrementSiteDataSourceHistory(
        site,
        SiteConstants.DATA_SOURCES.ENEDIS,
      );

    if (enedisNumber === data._attributes.id) {
      this.setState({
        fetchingEnedis: false,
        error: "",
        site: {
          ...btInfModel(data, site, Enedis),
          dataSourceHistory: updateDataSourceHistory,
        },
      });
      await SiteService.update(site.id, {
        ...this.state.site,
        purchaseAreaOfTension: Enedis.formatAreaOfTension(
          data.situationAlimentation.alimentationPrincipale.domaineTension
            ._attributes.code,
        ),
      });

      return Promise.resolve({ status: "OK" });
    }
  };
  enedisSubmit = async () => {
    const { agreed, site, error } = this.state;
    let { enedisNumber } = site;

    // if (error) {
    //   console.log({ error });
    //   return Promise.reject("Une erreur est survenue :", error);
    // }

    if (enedisNumber !== "") {
      this.setState({ site: { ...site, automaticUpdateError: null } });
      return await this.getEnedisData(enedisNumber);
    }
    // if (site.reportURL) return true;
    return Promise.reject("Une erreur est survenue , ", error);
  };

  removeLine = (id) =>
    this.setState({ tarifs: this.state.tarifs.filter((e) => e.id !== id) });

  handleSwitchTab = (tab) =>
    this.setState({
      tab,
      site: {
        ...this.state.site,
        tarifBuilder: tab === "PRICEBUILDER",
        analysisId: "",
        purchaseAreaOfTension:
          tab === "PRICEBUILDER"
            ? this.state.site.purchaseAreaOfTension || "HTA"
            : this.state.site.purchaseAreaOfTension || "",
      },
    });

  handleSendEmail = async () => {
    const { site } = this.state;

    const currentPageURL = window.location.href;
    const link = `${currentPageURL}consent/${site.user}/${site.id}`;

    const payload = {
      target: site.customerConsentEmail,
      company: "Likewatt",
      link,
    };

    const date = dayjs().format("DD/MM/YYYY HH:mm");

    try {
      this.setState({
        loadingEmail: true,
      });
      try {
        await sendMailForConsent(payload);
      } catch (e) {
        console.log("ERROR", e);
      }
    } catch {
      console.log("ERROR");
    } finally {
      await SiteService.updateKey(site.id, "isConsentMailSent", true);
      await SiteService.updateKey(site.id, "dateMailConsent", date);
      await SiteService.updateKey(
        site.id,
        "customerConsentFirstName",
        site.customerConsentFirstName,
      );
      await SiteService.updateKey(
        site.id,
        "customerConsentLastName",
        site.customerConsentLastName,
      );
      await SiteService.updateKey(
        site.id,
        "customerConsentEmail",
        site.customerConsentEmail,
      );
      this.setState({
        loadingEmail: false,
        isMailSent: true,
        site: {
          ...this.state.site,
          isConsentMailSent: true,
        },
      });
    }
  };

  render() {
    const { step, isLoading } = this.state;
    if (isLoading)
      return (
        <div
          className="has-text-centered"
          style={{ fontSize: "25px", padding: "1em" }}
        >
          <Loader
            style={{
              width: 300,
              margin: "auto",
              marginTop: "10vh",
              height: 300,
              border: "4px solid #9EBE5A",
              borderTopColor: "transparent",
              borderRightColor: "transparent",
            }}
          />
        </div>
      );
    switch (step) {
      case 0:
        return this.renderStepZero();
      case 1:
        return this.renderStepOne();
      case 2:
        return this.renderStepTwo();
      case 3:
        return this.renderStepThree();
      default:
        return <div>Impossible d'afficher l'étape</div>;
    }
  }

  setSiteType = (type) =>
    this.setState({
      site: {
        ...this.state.site,
        type,
        reportURL: "",
      },
      tab: TABS.REGULAR,
    });

  handleChangeConsentFile = async (e) => {
    let err,
      fileURL,
      rows = null;
    let file = e.target.files[0];
    let { site } = this.state;

    this.setState({
      isUploadingConsent: true,
      actionRequired: false,
      error: "",
    });

    [err, fileURL] = await to(
      SiteService.uploadConsentFile(
        file,
        site,

        (snapshot) => {
          this.setState({
            uploadConsentProgress:
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
          });

          switch (snapshot.state) {
            case Firebase.storage.TaskState.SUCCESS:
              console.log("File uploaded!");
              break;
            // case Firebase.storage.TaskState.RUNNING:
            //   console.log(`File is uploading...`);
            //   break;
            case Firebase.storage.TaskState.ERROR:
              console.error("Error when uploading file!");
              break;
            case Firebase.storage.TaskState.PAUSED:
              console.log("File upload has been paused!");
              break;
            case Firebase.storage.TaskState.CANCELED:
              console.warning("File upload has been canceled!");
              break;
            default:
              console.log();
          }
        },
      ),
    );

    this.setState({
      site: {
        ...this.state.site,
        consentFileUrl: fileURL,
      },
      isUploadingConsent: false,
    });
  };

  renderStepZero = () => (
    <Step0 setType={this.setSiteType} handleNextStep={this.handleNextStep} />
  );

  renderStepOne = () => {
    const {
      site = {},
      isSubmitingSite,
      isFetchingTimeZone,
      timeZoneError,
    } = this.state;
    const {
      name,
      country,
      description,
      currency,
      addressGeocode,
      co2rate,
      autoCo2,
    } = site;
    const { isCollectiveSiteEditorMode, handleRemoveEditor } = this.props;
    return (
      <Step1
        isCollectiveSiteEditorMode={isCollectiveSiteEditorMode}
        site={site}
        name={name}
        co2rate={co2rate}
        autoCo2={autoCo2}
        country={country}
        currency={currency}
        description={description}
        addressGeocode={addressGeocode}
        handleChangeInput={this.handleChangeInput}
        handleChangeCheckbox={this.handleChangeCheckbox}
        handleNextStep={this.handleNextStep}
        canGoToStepTwo={this.canGoToStepTwo}
        handleCodeFromGoogle={this.handleCodeFromGoogle}
        getMapInitialValue={this.getMapInitialValue}
        handleCodeFromMarker={this.handleCodeFromMarker}
        handleSubmitProducerSite={this.handleSubmitProducerSite}
        editMode={this.props.editMode}
        isSubmitingSite={isSubmitingSite}
        isFetchingTimeZone={isFetchingTimeZone}
        timeZoneError={timeZoneError}
        handleRemoveEditor={handleRemoveEditor}
        cancelEditor={this.props.cancelEditor}
        handleSubmitSite={this.handleSubmitSite}
      />
    );
  };

  resetSiteProfile = () =>
    this.setState({ site: { ...this.state.site, profile: "" } });

  renderStepTwo = () => {
    const {
      isUploadingReport,
      site = {},
      uploadReportProgress,
      actionRequired,
      agreed,
      error,
      fetchingEnedis,
      user,
      isUploadingConsent,
      uploadConsentProgress,
      loadingEmail,
      isMailSent,
    } = this.state;
    const { editMode, siteToEdit } = this.props;
    const {
      reportURL,
      enedisBuffer,
      type,
      enedisNumber,
      consentFileUrl,
      customerConsentFirstName,
      customerConsentLastName,
      customerConsentEmail,
      isConsentMailSent,
      hasConsentData,
    } = site;

    return (
      <Step2
        enedisNumber={enedisNumber}
        loadingEmail={loadingEmail}
        isMailSent={isMailSent}
        editMode={editMode}
        siteToEdit={siteToEdit}
        fetchingEnedis={fetchingEnedis}
        site={site}
        isUploadingReport={isUploadingReport}
        uploadReportProgress={uploadReportProgress}
        actionRequired={actionRequired}
        agreed={agreed}
        reportURL={reportURL}
        enedisBuffer={enedisBuffer}
        hasConsentData={hasConsentData}
        isConsentMailSent={isConsentMailSent}
        customerConsentFirstName={customerConsentFirstName}
        customerConsentLastName={customerConsentLastName}
        customerConsentEmail={customerConsentEmail}
        type={type}
        handleEnedis={this.handleEnedis}
        handleAgreement={this.handleAgreement}
        enedisSubmit={this.enedisSubmit}
        handleChangeInputFile={this.handleChangeInputFile}
        handleChangeConsentFile={this.handleChangeConsentFile}
        handleSendEmail={this.handleSendEmail}
        consentFileUrl={consentFileUrl}
        isUploadingConsent={isUploadingConsent}
        uploadConsentProgress={uploadConsentProgress}
        handlePrevStep={this.handlePrevStep}
        handleNextStep={this.handleNextStep}
        canGoToStepThree={this.canGoToStepThree}
        error={error}
        license={user.license}
        handleChangeSiteProfileType={this.handleChangeSiteProfileType}
        changeProfileMonthlyConsumption={this.changeProfileMonthlyConsumption}
        handleToggleMonthlyIndex={this.handleToggleMonthlyIndex}
        resetSiteProfile={this.resetSiteProfile}
        cancelEditor={this.props.cancelEditor}
        handleChangeInput={this.handleChangeInput}
      />
    );
  };

  loadMonthlyOrYearlyIndexes = (
    monthlyConsumptionIndex = this.state.site.monthlyConsumptionIndex,
  ) =>
    monthlyConsumptionIndex
      ? SiteHelper.initialConsumptionIndexes()
      : { 1: SiteHelper.initialConsumptionIndexes()[1] };

  handleToggleMonthlyIndex = (isMonthly) => {
    const { site } = this.state;
    this.setState({
      site: {
        ...site,
        monthlyConsumptionIndex: isMonthly,
        consumptionIndexes: this.loadMonthlyOrYearlyIndexes(isMonthly),
      },
    });
  };

  changeProfileMonthlyConsumption = (targetMonth, consumption, index) => {
    const { site } = this.state;
    const { consumptionIndexes } = site;
    const buffer = { ...consumptionIndexes };
    buffer[targetMonth][index] = consumption;
    this.setState({ site: { ...site, consumptionIndexes: buffer } });
    window.scrollTo({ bottom: 0, behavior: "smooth" });
  };

  getAreaOfTensionByProfile = (profile) => {
    switch (profile) {
      case SiteConstants.profiles.RES_BASE_INF_6:
      case SiteConstants.profiles.RES_BASE_SUP_6:
      case SiteConstants.profiles.RES_BASE:
      case SiteConstants.profiles.RES_HP_HC:
        return [
          SiteConstants.purchaseAreaOfTension["BT<36 kVA"],
          SiteConstants.ratesOption.CU,
        ];
      case SiteConstants.profiles.ENT_BT_SUP:
        return [
          SiteConstants.purchaseAreaOfTension["BT>36 kVA"],
          SiteConstants.ratesOption.CU,
        ];
      case SiteConstants.profiles.PRO_BASE:
      case SiteConstants.profiles.PRO_HP_HC:
        return [
          SiteConstants.purchaseAreaOfTension["BT<36 kVA"],
          SiteConstants.ratesOption.CU4,
        ];
      case SiteConstants.profiles.ENT_HTA:
        return [
          SiteConstants.purchaseAreaOfTension["HTA"],
          SiteConstants.ratesOption["CU pointe fixe"],
        ];
      default:
        console.log("ICI BASE2");
        return [this.state.site.purchaseAreaOfTension, ""];
        break;
    }
  };

  handleChangeSiteProfileType = ({ target: { value: profile } }) => {
    console.log({ profile });
    const [purchaseAreaOfTension, ratesOption] =
      this.getAreaOfTensionByProfile(profile);
    SiteService.deleteAnalysisFiles(
      this.state.site.id,
      this.state.site?.collectiveSiteId,
    );
    this.setState({
      site: {
        ...this.state.site,
        profile,
        consumptionIndexes: this.loadMonthlyOrYearlyIndexes(),
        purchaseAreaOfTension,
        ratesOption,
        purchaseContractType:
          SiteConstants.purchaseContractType["Contrat unique"],
        includeTURPE:
          purchaseAreaOfTension ===
          SiteConstants.purchaseAreaOfTension["BT<36 kVA"],
      },
    });
  };

  handleChangeRestOfTheYearTarifs = ({ target: { name, value } }) => {
    const { site } = this.state;
    this.setState({
      site: {
        ...site,
        restOfTheYearTarifs: {
          ...this.state.site.restOfTheYearTarifs,
          [name]: value,
        },
      },
    });
  };

  resetTarifBuilder = () => {
    const defaultValue = defaultRateModel();
    this.setState({
      site: {
        ...this.state.site,
        tarifs: [
          {
            energyRate: 0,
            subscriptionRate: 0,
            id: RandomString.generate(10),
            months: defaultValue.rates[0].months,
            days: [1, 2, 3, 4, 5, 6, 7],
            startHour: 0,
            endHour: 24,
            subscribedPower: 0,
          },
        ],
        restOfTheYearTarifs: {
          energyRate: 0,
          subscribedPower: 0,
          subscriptionRate: 0,
        },
      },
    });
  };
  applyDefaultParameters = (rateId) => {
    if (rateId) {
      if (
        this.props?.userDefaultRates &&
        this.props.userDefaultRates.length > 0
      ) {
        this.setState({
          site: {
            ...this.state.site,
            tarifs: this.props.userDefaultRates.find((r) => r.rateId === rateId)
              .rates,
            restOfTheYearTarifs: this.props.userDefaultRates.find(
              (r) => r.rateId === rateId,
            ).restOfTheYear,
          },
        });
      }
    }
  };

  handleChangeWeekendPrices = (id, e) => {
    const name = e.target.name;
    let value = e.target.value;
    const { site } = this.state;
    let { energyPricesWe = [] } = site;
    energyPricesWe = energyPricesWe.map((enyP /** energyPrice */) => {
      if (enyP.id === id) {
        if (name === "HPB") {
          return { ...enyP, PTE: value, HPH: value, [name]: value };
        } else {
          return {
            ...enyP,
            HCH: value,
            [name]: value,
          };
        }
      }
    });

    this.setState({ site: { ...site, energyPricesWe } });
  };

  tarifBuilderProps = () => {
    const { site, isSubmitingSite } = this.state;
    const { tarifs, restOfTheYearTarifs } = site;

    return {
      addLine: this.addLine,
      removeLine: this.removeLine,
      changeMonth: this.changeMonth,
      changeField: this.changeField,
      changeDays: this.changeDays,
      handlePrevStep: this.handlePrevStep,
      handleSubmitSite: this.handleSubmitSite,
      handleSubmitProducerSite: this.handleSubmitProducerSite,
      switchToStep: this.switchToStep,
      site,
      canSubmitTarifs: this.canSubmitTarifs,
      tarifs,
      isSubmitingSite,
      resetTarifBuilder: this.resetTarifBuilder,
      applyDefaultParameters: this.applyDefaultParameters,
      restOfTheYearTarifs: restOfTheYearTarifs,
      handleChangeRestOfTheYearTarifs: this.handleChangeRestOfTheYearTarifs,
      defaultRates: this.props?.userDefaultRates,
      handleStep3ParametersUpdate: this.handleStep3ParametersUpdate,
      cancelEditor: this.props.cancelEditor,
      checkAllSubscribedPowers: this.checkAllSubscribedPowers,
    };
  };

  classicProps = () => {
    const { editMode } = this.props;
    const { site, isSubmitingSite, error, user, allSubscribedPowersAreZero } =
      this.state;
    const {
      purchaseContractType,
      purchaseMeterOwner,
      purchaseAreaOfTension,
      ratesOption,
      subscribedPowers = [],
      subscriptionRates = 0,
      energyPrices = [],
      energyPricesWe = [],
      energyPricesTempo = [],
      offPeakHours = [],
      enedisData,
      includeTURPE,
      includeTVA,
      TCSPE,
      hasWeekendPricing = false,
      tempoPricing = false,
      hasPeakHours = true,
    } = site;
    return {
      subscriptionRates,
      site,
      energyPrices,
      energyPricesWe,
      energyPricesTempo,
      purchaseContractType,
      purchaseMeterOwner,
      purchaseAreaOfTension,
      ratesOption,
      offPeakHours,
      subscribedPowers,
      includeTURPE,
      includeTVA,
      hasWeekendPricing,
      hasPeakHours,
      tempoPricing,
      isSubmitingSite,
      editMode,
      TCSPE,
      error,
      handleChangeEnergyPriceInput: this.handleChangeEnergyPriceInput,
      handleChangeEnergyTempoPriceInput: this.handleChangeEnergyTempoPriceInput,
      handleDeleteEnergyPriceLine: this.handleDeleteEnergyPriceLine,
      handleChangeInput: this.handleChangeInput,
      handleChangeAreaOfTension: this.handleChangeAreaOfTension,
      handleChangeOffPeakHourStartTime: this.handleChangeOffPeakHourStartTime,
      handleChangeOffPeakHourEndTime: this.handleChangeOffPeakHourEndTime,
      handleChangeOffPeakHourInput: this.handleChangeOffPeakHourInput,
      handleDeleteOffPeakHourLine: this.handleDeleteOffPeakHourLine,
      handleChangeSubscribedPowerInput: this.handleChangeSubscribedPowerInput,
      handleDeleteSubscribedPowersLine: this.handleDeleteSubscribedPowersLine,
      enedisData,
      switchToStep: this.switchToStep,
      handlePrevStep: this.handlePrevStep,
      handleSwitchPrice: this.handleSwitchPrice,
      handleSwitchPriceWE: this.handleSwitchPriceWE,
      canSubmitSite: this.canSubmitSite,
      handleSubmitSite: this.handleSubmitSite,
      handleSubmitProducerSite: this.handleSubmitProducerSite,
      handleAddOffPeakHoursLine: this.handleAddOffPeakHoursLine,
      userDomain: user.domain,
      handleStep3ParametersUpdate: this.handleStep3ParametersUpdate,
      handleChangeWeekendPrices: this.handleChangeWeekendPrices,
      checkAllSubscribedPowers: this.checkAllSubscribedPowers,
      cancelEditor: this.props.cancelEditor,
      allSubscribedPowersAreZero,
    };
  };

  renderStepThree = () => {
    const { site, tab, showInfoPopUp, showModal, isSubmitingSite } = this.state;
    const { i18n } = this.context;
    if (!this.state.user) return null;
    return (
      <>
        {showModal && (
          <CustomModal
            show={showModal}
            title={i18n.t("subscribedPowerTitleModal")}
            onClose={() => this.setState({ showModal: false })}
            onConfirm={async () => {
              this.handleSubmitSite();
            }}
            validateLabel={i18n.t("yes")}
            cancelLabel={i18n.t("no")}
            loading={isSubmitingSite}
          >
            {i18n.t("subscribedPowerModal")}
          </CustomModal>
        )}
        <InfoPopUp
          showInfoPopUp={showInfoPopUp}
          message={i18n.t("editorProject.updatingScenarios")}
        />
        <Step3
          handleSwitchTab={this.handleSwitchTab}
          tarifBuilderProps={this.tarifBuilderProps()}
          classicProps={this.classicProps()}
          tab={tab}
          site={site}
        />
      </>
    );
  };

  canSubmitTarifs = () => {
    const { site } = this.state;
    const { tarifs, restOfTheYearTarifs } = site;

    let buffer = tarifs;
    if (tarifs.length <= 0) return false;
    buffer.forEach((tarif) => {
      if (
        tarif.months.length > 1 &&
        !tarif.months[tarif.months.length - 1].month
      )
        tarif.months.pop();
    });

    tarifs.forEach((tarif, i) => {
      if (_.isUndefined(tarif.name)) {
        tarif.name = i18n.t("rate") + " " + (i + 1);
      }
    });

    const missingDays = tarifs.filter((t) => typeof t.days === "undefined");

    if (missingDays.length > 0) return false;

    if (countUnvalidFields(tarifs, restOfTheYearTarifs) > 0) {
      return false;
    }
    return true;
  };

  handleSubmitTarif = async () => {
    let err = null;

    const { onSubmitSuccess, editMode } = this.props;
    let { site, agreed, user, _agreedAt } = this.state;
    // 19109695879000

    console.log("SUBMIT tarif");
    let newTarifs = ForeignSite.formatTarifsParams(
      site.tarifs,
      site.restOfTheYearTarifs,
    );

    let payload = {
      ...site,
      grid_params: { priceBuilderSlots: newTarifs, priceBuilder: 1 },
      tarifBuilder: true,
      tarifs: site.tarifs.map((t) => ({
        ...t,
        energyRate: +t.energyRate,
        subscribedPower: +t.subscribedPower,
        subscriptionRate: +t.subscriptionRate,
      })),
    };

    payload = {
      ...payload,
      purchaseAreaOfTension: SiteConstants.purchaseAreaOfTension["BT>36 kVA"],
      purchaseContractType: "",
      purchaseMeterOwner: "",
      ratesOption: "",
    };

    if (payload.enedisNumber) {
      delete payload.reportURL;
    }

    payload.co2rate = payload.autoCo2 ? null : parseFloat(payload.co2rate);

    if (site?.profile) {
      payload.endDate = new Date();
      payload.startDate = new Date();
      payload.dataSource = SiteConstants.DATA_SOURCES.PROFILE;
    }

    if (payload?.profile === SiteConstants._profilesDisplayNames.RES_BASE) {
      const maxSubP = Math.max(
        ...payload.tarifs.map((tarif) => +tarif.subscribedPower),
        +site.restOfTheYearTarifs.subscribedPower,
      );
      if (maxSubP <= 6) {
        payload.profile = SiteConstants.profiles.RES_BASE_INF_6;
      } else payload.profile = SiteConstants.profiles.RES_BASE_SUP_6;
    }

    this.setState({ isSubmitingSite: true });
    [err] = await to(SiteService.update(this.state.site.id, payload));

    if (err) return console.error(err);
    if (site.isFullSellOut) {
      [err] = await to(
        ApiService.initTMY(
          this.state.site.id,
          this.state.site.addressGeocode,
          this.state.site.country,
        ),
      );
      if (err) {
        this.setState({ isSubmitingSite: false, error: err.data.message });
        return console.error(err);
      }
    } else {
      if (this.state.site.enedisNumber === "" && !payload.profile)
        [err] = await to(ApiService.initDataFile(this.state.site.id));
      if (err) {
        this.setState({ isSubmitingSite: false, error: err?.data?.message });
        return console.error(err);
      }
    }

    if (payload?.profile && !payload.reportURL)
      ApiService.createConsoDataFromProfile(payload);

    if (this.state.shouldUpdateScenarios) {
      console.log("SCENARIOS SHOULD BE UPDATED");
      this.setState({ showInfoPopUp: true });
      await updateThisSiteScenarios(payload);
      const sitePath = site.collectiveSiteId
        ? `${site.collectiveSiteId}/Sites/${site.id}`
        : site.id;
      await SiteService.deleteAnalysisFiles(site.id, site?.collectiveSiteId);

      this.setState({ showInfoPopUp: false });
    }

    this.setState({ isSubmitingSite: false, error: null });

    onSubmitSuccess(payload);
  };

  changeMonth = (months, id) => {
    const { site } = this.state;
    const { tarifs } = site;

    let range = tarifs.find((t) => t.id === id);
    range.months = months;
    this.handleStep3ParametersUpdate();
    return this.setState({
      site: {
        ...site,
        analysisId: "",
        tarifs: tarifs.filter((tarif) => tarif.months.length > 0),
      },
    });
  };

  addLine = () => {
    const { site } = this.state;
    const { tarifs } = site;
    const defaultValue = defaultRateModel();
    console.log({ defaultValue });

    this.setState({
      site: {
        ...site,
        analysisId: "",
        tarifs: [
          ...tarifs,
          {
            energyRate: 0,
            subscriptionRate: 0,
            id: RandomString.generate(10),
            months: defaultValue.rates[0].months,
            days: [1, 2, 3, 4, 5, 6, 7],
            startHour: 0,
            endHour: 24,
            subscribedPower: 0,
          },
        ],
      },
    });
    this.handleStep3ParametersUpdate();
  };

  removeLine = (id) => {
    this.setState({
      site: {
        ...this.state.site,
        analysisId: "",
        tarifs: this.state.site.tarifs.filter((e) => e.id !== id),
      },
    });
    this.handleStep3ParametersUpdate();
  };

  changeDays = (index, lineId, value) => {
    const { site } = this.state;
    const { tarifs } = site;
    console.log({ index, lineId, value });
    let currentDaysArray =
      tarifs.find((tarif) => tarif.id === lineId)?.days || [];
    if (currentDaysArray?.find((day) => day === value))
      currentDaysArray = currentDaysArray.filter((day) => day !== value);
    else currentDaysArray = [...currentDaysArray, value].sort();
    this.setState({
      site: {
        ...site,
        tarifs: tarifs.map((tarif) =>
          tarif.id !== lineId
            ? tarif
            : {
                ...tarif,
                days: currentDaysArray,
              },
        ),
      },
    });
    this.handleStep3ParametersUpdate();
  };
  changeField = (index, lineId, field, value) => {
    const { site } = this.state;
    const { tarifs } = site;
    console.log({ index, lineId, field, value });
    if (field === "endHour") {
      if (value === 0) value = 24;
    }
    let line = tarifs.find((e) => e.id === lineId);
    line[field] = value;
    tarifs[index] = line;
    this.handleStep3ParametersUpdate();

    this.setState({
      site: { ...site, tarifs, analysisId: "" },
    });
  };

  createSiteStepOne = async () => {
    const [err] = await to(SiteService.add(this.state.site));
    if (err) console.log("Erreur while creating site for step one: ", err);

    // add site to collective-site's list of sites
    if (this.props.isCollectiveSiteEditorMode)
      this.props.updateSubSite(this.state.site);
  };

  getUser = async () => {
    if (this.props.user) return this.setState({ user: this.props.user });
    const [err, user] = await to(UserService.get(Auth.getUserId()));
    if (!err) return this.setState({ user });
  };

  deleteSiteOnExit = async () => {
    if (
      (!this.state.site.purchaseAreaOfTension &&
        this.state.site.tarifs.length === 0) ||
      !this.state.site.address
    ) {
      // delete site from collective-site's list of sites
      if (this.props.isCollectiveSiteEditorMode)
        this.props.deleteSubSite(this.state.site);

      //remove from firestore
      console.log("Removing....", this.state.site);
      await SiteService.remove(this.state.site.id, this.state.site.type);
      // SiteService.deleteFolderContents(`Sites/${this.state.site.id}`); //remove from storage
    }
  };

  handleChangeCheckbox = (e) =>
    this.setState({ site: { ...this.state.site, autoCo2: e.target.checked } });

  componentWillUnmount = () => {
    window.removeEventListener("unload", this.deleteSiteOnExit);

    if (!this.props.editMode) {
      this.deleteSiteOnExit();
    }
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });

    const { analytics, PageNames } = this.context;
    analytics.logEvent("page_view", { page_title: PageNames.HOME.EDITOR });
    const { editMode, siteToEdit } = this.props;
    await this.getUser();
    if (editMode) {
      this.setState({
        site: { ...SiteHelper.initialStateSite(), ...siteToEdit }, // Spreading default values & replacing by site's data
        tab:
          siteToEdit.tarifBuilder || siteToEdit.country !== "fr"
            ? TABS.PRICEBUILDER
            : TABS.REGULAR,
        step: siteToEdit.type === "" ? 0 : 1,
        agreed: siteToEdit.dataSource && siteToEdit.dataSource === "ENEDIS",
      });

      if (siteToEdit.country !== "fr") {
        this.setState({
          site: {
            ...this.state.site,
            autoCo2: false,
          },
        });
      }
    } else {
      window.addEventListener("unload", (e) => this.deleteSiteOnExit(e));
      await this.createSiteStepOne();
    }
    this.setState({ isLoading: false });
  };
}
