import { BehaviorSubject } from "rxjs";
import SiteConstants from "../../constants/Site";

let defaultValues = {
  CONSUMER: {},
};

const defaultValues$ = new BehaviorSubject(defaultValues);

/**
 *
 * @param {*} type Type of site ["CONSUMER"]
 * Is only called ONCE when fetching default values from API
 * @returns nothing
 */
export const setDefaultValues = (type, payload) => {
  switch (type) {
    case SiteConstants.type.CONSUMER_PLUS:
      defaultValues[SiteConstants.type.CONSUMER] = payload;
      break;
    default:
      defaultValues[type] = payload;
      break;
  }
  defaultValues$.next(defaultValues);
};

/**
 *
 * @param {*} type Type of site ["CONSUMER"]
 * @returns defaultValues Object
 */
export const getDefaultValues = (type) => {
  switch (type) {
    case SiteConstants.type.CONSUMER_PLUS:
      return defaultValues[SiteConstants.type.CONSUMER];
    default:
      return defaultValues[type];
  }
};

export default defaultValues$;
