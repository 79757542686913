import AuthConstants from "../constants/Auth";
import { pause } from "../utils/Utils";
import Api from "./Api";
import Axios from "axios";
import Developpers from "./Developpers";
import Site from "./Site";
import User from "./User";

const sendMailUnknownError = async ({
  siteId,
  userId,
  scenarioId,
  optimId,
}) => {
  const token = await Api.getToken();
  Axios.post(
    AuthConstants.URL_SEND_UNKNOWN_ERROR_MAIL,
    {
      userId,
      siteId,
      scenarioId,
      optimId,
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `${token}`,
      },
    },
  );
};

const sendMailBrokerage = async ({
  site,
  userId,
  scenarioId,
  SIRET,
  totalEnergyPerTimeSlot,
}) => {
  const token = await Api.getToken();
  return Axios.post(
    AuthConstants.URL_SEND_BROKERAGE_MAIL,
    {
      userId,
      site,
      scenarioId,
      SIRET,
      totalEnergyPerTimeSlot,
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `${token}`,
      },
    },
  );
};

const sendMailToContactOrSupport = async ({
  from,
  type,
  subject,
  body,
  phone,
  company,
}) => {
  const token = await Api.getToken();

  return Axios.post(
    AuthConstants.URL_SEND_CONTACT_OR_SUPPORT,
    {
      from,
      type,
      subject,
      body,
      env: process.env.REACT_APP_ENV,
      phone,
      company,
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `${token}`,
      },
    },
  );
};

const sendNewQuoteMail = async (site) => {
  const token = await Api.getToken();
  let dev, devAccount, user;

  try {
    dev = await Developpers.getByDevelopper();
    devAccount = dev.account;
    user = await User.get(devAccount);
  } catch {
    console.error("Could'nt find dev");
  }

  const target = dev.email.toString().replace(",", ";");

  await Axios.post(
    AuthConstants.URL_SEND_QUOTE_CANI,
    {
      target: target || user.email,
      site,
      // in test, change target to your email
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `${token}`,
      },
    },
  );

  await Site.update(site.id, { quoteWasSent: true });
};

const sendMailForConsent = async ({ target, company, link }) => {
  const token = await Api.getToken();

  return Axios.post(
    AuthConstants.URL_SEND_CONSENT,
    {
      target,
      company,
      link,
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `${token}`,
      },
    },
  );
};

export {
  sendNewQuoteMail,
  sendMailUnknownError,
  sendMailBrokerage,
  sendMailToContactOrSupport,
  sendMailForConsent,
};
