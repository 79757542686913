import React from "react";
import styled from "styled-components";

export const CustomButtonSecondary = (props) => {
  const {
    children = "defaultLabel",
    onClick = () => {
      console.log("Button handler");
    },
    iconPosition = "left",
    margin = "10px",
    loading = false,
  } = props;

  return (
    <StyledButton onClick={onClick} {...props} disable={props.disabled}>
      <WrapperButton style={loading ? { opacity: 0 } : {}}>
        {iconPosition === "left" && props.icon && (
          <StyledIconWrapper marginLeft={margin}>
            {props.icon}
          </StyledIconWrapper>
        )}
        {children}
        {iconPosition === "right" && props.icon && (
          <StyledIconWrapper marginRight={margin}>
            {props.icon}
          </StyledIconWrapper>
        )}
      </WrapperButton>
    </StyledButton>
  );
};
const WrapperButton = styled.div`
  display: ${(props) => props.flex && "flex"};
  align-items: ${(props) => props.flex && "center"};
`;
const StyledButton = styled.button`
  background-color: ${(props) => props.background || "white"};
  color: ${(props) => props.color || "black"};
  padding: ${(props) => props.padding || "1%"};
  border: ${(props) => props.border || "solid #006276 1px"};
  margin-left: ${(props) => props.marginLeft || "3%"};
  margin-bottom: ${(props) => props.marginBottom || "0%"};
  font-size: ${(props) => props.fontSize || "1rem !important"};
  font-family: ${(props) => props.fontFamily || "Roboto"};
  transition: 0.5s ease 0s;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  display: inline-flex;

  &:hover {
    background-color: ${(props) => props.hoverBackgroundColor || "#dcecf5"};
    background-color: ${(props) => !props.current || "#dcecf5"};
    color: ${(props) => props.hoverColor || "black"};
    background-color: ${(props) => !props.disable || "lightgrey"};
    color: ${(props) => !props.disable || "black"};
  }
  background-color: ${(props) => !props.disable || "lightgrey"};
  color: ${(props) => !props.disable || "black"};
  cursor: ${(props) => !props.disable || "not-allowed"};
  border: ${(props) => !props.disable || "solid #006276 1px"};
`;

const StyledIconWrapper = styled.span`
  margin-left: ${(props) =>
    props.marginLeft ? props.marginLeft : props.marginRight};
  margin-right: ${(props) =>
    props.marginRight ? props.marginRight : props.marginLeft};
  font-size: ${(props) => props.fontSize || "1rem"};
`;

export const StyledLoader = styled.div`
  width: 20px;
  height: 20px;
  border: ${(props) => props.border || "2px solid white"};
  border-bottom-color: ${(props) => props.color || "green"};
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const StyledButtonContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
