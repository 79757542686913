import React, { useContext } from "react";
import LocaleContext from "../context/LocaleContext";
import styled from "styled-components";
import { Button } from "react-bulma-components/full";
import { ACC } from "./Site/Step0Components/SiteTypes";
import LOCK from "../assets/img/lock.png";
import LIGHTBULB from "../assets/img/lightbulb.png";
import { blue } from "../assets/style/SharedColors";
import {
  toogleNotification,
  resetAllNotifications,
} from "../store/notifications/NotificationsStore";
import { POP_UPS } from "../constants/Notifications";
import { setQueryParams } from "../helpers/Router";
import { MAILTYPES } from "../constants/MailTypes";
import Auth from "../services/Auth";

export const mailTrigger = () => {
  resetAllNotifications();
  setQueryParams(MAILTYPES.CONTACT);
  toogleNotification(POP_UPS.SEND_MAIL);
};

const StyledCTAMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  vertical-align: middle;
  height: 100%;
  white-space: pre-wrap;
  img {
    max-width: 40%;
    height: auto;
    margin: 0 auto;
    ${(props) =>
      props.useFilter &&
      `
	    filter: invert(19%) sepia(84%) saturate(2023%) hue-rotate(168deg)
      brightness(99%) contrast(101%);
	`}

    &.embedded {
      max-width: 20%;
      padding: 1em;
    }
  }
  section {
    color: ${blue};
    i {
      filter: invert(19%) sepia(84%) saturate(2023%) hue-rotate(168deg)
        brightness(99%) contrast(101%);
      padding: 0 0.5em;
    }
    text-align: center;
    font-size: 1.5rem;
    padding: 0.5em;
    a {
      font-weight: bold;
    }
    span {
      padding: 1em 0;
    }
    button {
      border-radius: 10px;
      transition: transform 0.2s ease-in-out;
      font-size: 0.75em;
      margin: 10px 0;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
`;

export const CustomGetACCMessage = () => {
  const { i18n } = useContext(LocaleContext);
  return (
    <StyledCTAMessage>
      <img src={ACC} alt="ACC" />
      <section className="message">
        <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
        <p>
          {i18n.t("cta.endOfACC")} <br />
        </p>
        <span>
          {i18n.t("cta.toSubscribeWriteUs")}{" "}
          <a onClick={mailTrigger}>contact@likewatt.com!</a>
        </span>
      </section>
    </StyledCTAMessage>
  );
};

export const CustomEndOfDemoMessage = () => {
  const { i18n } = useContext(LocaleContext);
  return (
    <StyledCTAMessage useFilter>
      <img className="embedded" src={LOCK} alt="LOCK" />
      <section>
        <p>
          {i18n.t("cta.trialIsOver")} <br />
        </p>
        <Button onClick={mailTrigger} color="success">
          {i18n.t("cta.subscribeToKeepProjects")}
        </Button>
      </section>
    </StyledCTAMessage>
  );
};

export const CustomUpgradePlanCreateSiteMessage = () => {
  const { i18n } = useContext(LocaleContext);
  return (
    <StyledCTAMessage>
      <img className="embedded" src={LOCK} alt="LOCK" />
      <section>
        <p>{i18n.t("cta.deleteProjectOr")}</p>
        <Button onClick={mailTrigger} color="success">
          {i18n.t("cta.upgradeLimit")}
        </Button>
      </section>
    </StyledCTAMessage>
  );
};

export const CustomUpgradeToChatMessage = () => {
  const { i18n } = useContext(LocaleContext);

  return (
    <StyledCTAMessage>
      <section>
        <p style={{ fontSize: "0.75em" }}>{i18n.t("cta.upgradeForChat")}</p>
        <Button onClick={mailTrigger} color="success">
          {i18n.t("cta.upgradePlanForChat")}
        </Button>
      </section>
    </StyledCTAMessage>
  );
};

export const CustomUpgradeToBTSUPMessage = () => {
  const { i18n } = useContext(LocaleContext);

  return (
    <StyledCTAMessage useFilter>
      <section>
        <img className="embedded" src={LIGHTBULB} alt="LIGHTBULB" />
        <p>{i18n.t("cta.BTINF")}</p>
        <Button onClick={mailTrigger} color="success">
          {i18n.t("cta.upgradePlan")}
        </Button>
      </section>
    </StyledCTAMessage>
  );
};

export const CustomUpgradePlanMessage = () => {
  const { i18n } = useContext(LocaleContext);
  const licence = Auth.getUser().license.toUpperCase();
  return (
    <StyledCTAMessage>
      <img className="embedded" src={LOCK} alt="LOCK" />
      <section>
        <p>{i18n.t("cta.deleteProjectOr")}</p>
        <Button onClick={mailTrigger} color="success">
          {licence === "PREMIUM"
            ? i18n.t("cta.upgradePlanForMoreProjects")
            : i18n.t("cta.upgradeLimit")}
        </Button>
      </section>
    </StyledCTAMessage>
  );
};
export const CustomUpgradePlanFeatureMessage = () => {
  const { i18n } = useContext(LocaleContext);
  return (
    <StyledCTAMessage useFilter>
      <img className="embedded" src={LOCK} alt="LOCK" />
      <section>
        <p>{i18n.t("cta.toAccessThisFeature")}</p>
        <Button onClick={mailTrigger} color="success">
          {i18n.t("cta.upgradePlan")}
        </Button>
      </section>
    </StyledCTAMessage>
  );
};
export const CustomUpgradeMaxUsers = () => {
  const { i18n } = useContext(LocaleContext);

  return (
    <StyledCTAMessage>
      <img className="embedded" src={LOCK} alt="LOCK" />
      <section>
        <p>{i18n.t("cta.toLogin")}</p>
        <Button onClick={mailTrigger} color="success">
          {i18n.t("cta.upgradeLimit")}
        </Button>
      </section>
    </StyledCTAMessage>
  );
};
