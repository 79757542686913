import firebase from "firebase";
import KEYS from "./keys";

const PRODconfig = {
  apiKey: KEYS.PROD,
  authDomain: "likewatt-4bb4b.firebaseapp.com",
  databaseURL: "https://likewatt-4bb4b.firebaseio.com",
  projectId: "likewatt-4bb4b",
  storageBucket: "likewatt-4bb4b.appspot.com",
  messagingSenderId: "1035197136790",
  appId: "1:1035197136790:web:6a35d21586d0b9e8",
  measurementId: "G-DQDBN33P5S",
};

const TESTConfig = {
  apiKey: "AIzaSyAIy--5OktXf60Az0DJqpSmR1JT1e-dL9o",
  authDomain: "test-likewatt.firebaseapp.com",
  projectId: "test-likewatt",
  storageBucket: "test-likewatt.appspot.com",
  messagingSenderId: "689468541487",
  appId: "1:689468541487:web:23b50823f960b8c7ea14da",
};

var DEVConfig = {
  apiKey: KEYS.DEV,
  authDomain: "dev-likewatt-1ec3f.firebaseapp.com",
  databaseURL: "https://dev-likewatt-1ec3f.firebaseio.com",
  projectId: "dev-likewatt-1ec3f",
  storageBucket: "dev-likewatt-1ec3f.appspot.com",
  messagingSenderId: "643393847048",
  appId: "1:643393847048:web:71982cf1406e9134f276bd",
  measurementId: "G-EGSPJM6Q61",
};

let fire,
  secondary = null;
if (process.env.REACT_APP_ENV === "production") {
  console.log("PROD");
  fire = firebase.initializeApp(PRODconfig);
  secondary = firebase.initializeApp(PRODconfig, "Secondary");
  firebase.analytics(fire);
}
if (process.env.REACT_APP_ENV === "development") {
  console.log("DEV");
  fire = firebase.initializeApp(DEVConfig);
  secondary = firebase.initializeApp(DEVConfig, "Secondary");
  firebase.analytics(fire);
}

if (process.env.REACT_APP_ENV === "test") {
  console.log("TEST");
  fire = firebase.initializeApp(TESTConfig);
  secondary = firebase.initializeApp(TESTConfig, "Secondary");
}
// const fire = firebase.initializeApp(PRODconfig);
// const secondary = firebase.initializeApp(PRODconfig, "Secondary");
const storage = fire.storage();
const db = fire.firestore();

export { fire, secondary, storage, db };
