import Randomstring from "randomstring";

export const defaultRateModel = () => ({
  rateId: "",
  name: "",
  rates: [
    {
      energyRate: 0,
      subscriptionRate: 0,
      id: Randomstring.generate(10),
      months: [
        { month: 1, year: 2020 },
        { month: 2, year: 2020 },
        { month: 3, year: 2020 },
        { month: 4, year: 2020 },
        { month: 5, year: 2020 },
        { month: 6, year: 2020 },
        { month: 7, year: 2020 },
        { month: 8, year: 2020 },
        { month: 9, year: 2020 },
        { month: 10, year: 2020 },
        { month: 11, year: 2020 },
        { month: 12, year: 2020 },
      ],
      days: [1, 2, 3, 4, 5, 6, 7],
      startHour: 0,
      endHour: 24,
      subscribedPower: 0,
      name: "",
    },
  ],
  restOfTheYear: {
    energyRate: 0,
    subscriptionRate: 0,
    subscribedPower: 0,
  },
});
