import React from "react";
import { Link } from "react-router-dom";
const Terms = () => {
  return (
    <div id="terms" className="terms-container">
      <Link
        className="link-container"
        style={{
          width: "100%",
          textAlign: "center",
          display: "block",
        }}
        to={{
          pathname: "/",
        }}
      >
        Retour à l'accueil
      </Link>
      <div className="terms">
        {/* This WEB APP could be used to transform Word document to HTML */}
        {/* https://wordhtml.com/ */}
        <p>(Mises à jour le 21 juillet 2023)</p>
        <h3>
          <strong>CONDITIONS GÉNÉRALES D'UTILISATION (CGU)</strong>
        </h3>
        <h5>1. Définitions</h5>
        <p>
          « Bon de Commande » désigne tout devis, proposition commerciale, ou
          bon de commande édité par Likewatt et signé par le Client pour
          commander un Abonnement ou des Prestations au titre des présentes.
        </p>
        <p>
          « Client(s) » désigne toute personne physique ou morale ayant signé un
          Bon de Commande avec Likewatt.
        </p>
        <p>
          « Service(s) » désigne tout service digital réalisé par la Plateforme,
          incluant collecte de données, suivi de performances, simulation,
          calcul et aide à la décision dans le dimensionnement des projets
          énergétiques.
        </p>
        <p>
          « Plateforme » désigne la plateforme digitale accessible à l’adresse{" "}
          <a href="https://app.likewatt.com">https://app.likewatt.com</a>,
          proposant aux Clients les Services.
        </p>
        <p>
          « Prestations » désignent toutes les interventions de Likewatt
          effectuées auprès du Client au-delà de son Abonnement. Les Prestations
          peuvent porter sur l’accompagnement technique et fonctionnel, le
          paramétrage de la Plateforme, la création de documents, le reporting,
          le support. La liste des Prestations n’est pas exhaustive. Les
          Prestations commandées par le Client sont désignées au Bon de
          Commande.
        </p>
        <p>
          « Abonnement » désigne l’abonnement donnant accès à toutes ou
          certaines fonctionnalités de la Plateforme sur la Période
          d’abonnement, en fonction du forfait sélectionné. Les caractéristiques
          du forfait, les fonctionnalités mises à disposition et le prix de
          l’Abonnement sont désignés au Bon de Commande.
        </p>
        <p>
          « Durée d'abonnement » désigne la période durant laquelle l’Abonnement
          à la Plateforme de Likewatt est effectif telle que précisée au Bon de
          commande.
        </p>
        <p>
          Les « Utilisateurs » sont les salariés, représentants, consultants,
          contractuels ou agents du Client autorisés à accéder à la Plateforme
          en son nom et qui ont accès au même Abonnement du Client, en utilisant
          son compte unique défini par un identifiant et un mot de passe.
        </p>
        <p>
          Est considérée comme « Donnée » toute donnée personnelle ou technique
          renseignée ou collectée par le Client et liée au paramétrage de son
          compte ou de ses projets étudiés sur la Plateforme.
        </p>
        <p>
          « Accord » désigne le présent document qui constitue les Conditions
          Générales d’Utilisation (ci-après « les CGU ») et qui a pour objet de
          définir les modalités d’utilisation à destination du Client et de ses
          Utilisateurs.
        </p>
        <h5>2. Objet des conditions générales</h5>
        <p>
          Les présentes CGU entrent en vigueur à leur date de mise en ligne et
          s’appliquent dès la première utilisation de la Plateforme. Elles
          constituent un accord ayant force obligatoire entre les Clients et
          Likewatt.
        </p>
        <p>
          Ces CGU sont accessibles à tout moment sur la Plateforme et
          prévaudront, le cas échéant, sur toute autre version ou tout autre
          document contradictoire.
        </p>
        <p>
          Tout accès ou utilisation de la Plateforme suppose l’acceptation
          concomitante et sans réserve des termes des présentes CGU.
        </p>
        <p>
          Les Clients de la Plateforme s’engagent à respecter les CGU décrites
          ci-après.
        </p>
        <p>
          Les Clients sont invités à prendre régulièrement connaissance et à
          lire attentivement la dernière version des CGU applicables.
        </p>
        <p>
          Toute modification des CGU est notifiée électroniquement aux Clients
          qui sont libres d’en refuser l’application et mettre ainsi un terme à
          leur inscription.
        </p>
        <p>
          En l’absence de refus exprès des Clients, les CGU seront applicables
          dès la prochaine utilisation de la Plateforme.
        </p>
        <p>
          Si une ou plusieurs stipulations des CGU sont tenues non valides ou
          déclarées comme telles en application d’une loi, règlement ou à la
          suite d’une décision d’une juridiction compétente, elles seront
          réputées non écrites mais les autres demeureront en vigueur.
        </p>
        <h5>3. Accès à la Plateforme</h5>
        <p>
          L’accès à la Plateforme est réservé aux seuls Clients inscrits et à
          leurs Utilisateurs. Likewatt se réserve le droit d’offrir un accès
          gratuit à certains utilisateurs sur une période limitée et de
          restreindre ou de bloquer cet accès à tout moment.
        </p>
        <p>
          Les frais de télécommunication lors de l’accès à internet et de
          l’utilisation de la Plateforme sont à la charge des Clients. Il n’y a
          aucune restriction de temps ou de durée d’utilisation de la
          Plateforme, quel que soit le profil du Client.
        </p>
        <p>
          Likewatt se réserve le droit, sans préavis, ni indemnité, par exemple,
          de changer les méthodes opérationnelles, les serveurs et les heures
          d’accessibilité, de fermer temporairement ou définitivement la
          Plateforme ou l’accès à un ou plusieurs Services pour effectuer une
          mise à jour ou des modifications.
        </p>
        <p>
          De la même manière Likewatt se réserve le droit d’apporter à la
          Plateforme et à ses Services toutes les modifications et améliorations
          qu’elle jugera nécessaires ou utiles dans le cadre du bon
          fonctionnement de la Plateforme.
        </p>
        <h5>4. Utilisation des Données et Vie privée</h5>
        <h6>a) Traitement des Données des Clients</h6>
        <p>
          L’utilisation de la Plateforme par l’Utilisateur implique le recueil
          de certaines Données personnelles : Nom, Prénom, Adresse postale,
          Numéro de Téléphone et Adresse mail.
        </p>
        <p>
          La finalité de la collecte, du traitement et de la conservation de ces
          données est de permettre à l’Utilisateur de s'authentifier
          correctement et en toute sécurité, d’utiliser la Plateforme une fois
          son profil établi et de le tenir informé des nouveautés et actualités
          relatives à la Plateforme. Sont aussi recueillies des Données
          techniques pour le paramétrage des projets étudiés ainsi que pour la
          collecte des informations liées au compteur électrique d’une personne
          physique ou morale tierce comme : le numéro du Point de Référencement
          Mesure (PRM), les données techniques et contractuelles, l’historique
          des mesures en kWh et la courbe de charge.
        </p>
        <p>
          L’Utilisateur est informé qu’un dispositif de stockage sécurisé,
          incluant notamment des dispositifs de pare-feu et de cryptage est
          utilisé.
        </p>
        <p>
          L’Utilisateur est informé que les Données collectées sont conservées
          pour la durée nécessaire à l’utilisation par ses soins, de la
          Plateforme. A compter de la suppression du compte du Client, toutes
          les Données associées seront conservées pendant une durée de 2 ans.
          L’Utilisateur dispose d’un droit d’accès, de communication, de
          rectification et, sous réserve des dispositions légales applicables,
          de suppression des Données le concernant. Ce droit d’accès, de
          communication de rectification ou de suppression des Données s’exerce
          sur simple demande, en justifiant de son identité :
        </p>
        <p>
          Par courrier électronique, à l’adresse suivante : contact@likewatt.com
        </p>
        <p>
          Par courrier recommandé, à l’adresse de LIKEWATT : 59 Rue de
          l'Abondance, 69003 Lyon.
        </p>
        <p>
          La Plateforme se conforme aux dispositions de la loi n° 78-17 du 6
          janvier 1978 relative à l’informatique, aux fichiers et aux libertés,
          ainsi qu’à celles du Règlement européen 2016/679 du 27 avril 2016
          relatif à la protection des données personnelles et à la loi du 20
          juin 2018 n° 2018-493 relative à la protection des données
          personnelles.
        </p>
        <p>
          Likewatt maintiendra des protections administratives, physiques et
          techniques appropriées aux fins de protéger les Données des Clients.
          Likewatt respectera les engagements prévus dans les présentes
          Conditions Générales d’Utilisation et veillera à ce que son personnel,
          son groupe et chacun de ses sous-traitants respectent ses termes.
        </p>
        <p>
          L’Utilisateur est informé et consent à la collecte, le traitement et
          la conservation de ses données dans les conditions décrites ci-dessus.
        </p>
        <h6>b) Obligations des Clients</h6>
        <p>
          Pour consulter et extraire les données techniques et contractuelles,
          l’historique des mesures en kWh et de la courbe de charge disponibles
          pour un PRM donné (Point de Référencement Mesure), Le Client s’engage
          à disposer de l’autorisation expresse préalable du client ENEDIS
          référencé par le PRM en question. Cette autorisation expresse doit
          explicitement inclure l’identité de la personne physique ou morale
          donnant son consentement, l’identité des receveurs mentionnant le
          Client et son partenaire Likewatt et l’accord à demander et à recevoir
          communication auprès d'Enedis des données techniques et
          contractuelles, de l’historique des mesures en kWh et de la courbe de
          charge pour des fins d'étude sur une durée de 12 mois.
        </p>
        <h6>c) Limites concernant Likewatt</h6>
        <p>
          Likewatt n'utilisera ni ne permettra à quiconque d'utiliser les
          Données du Client dans le but de communiquer avec une personne ou une
          entreprise tierces, sauf instruction contraire ou autorisation de la
          part du Client.
        </p>
        <p>
          Likewatt n'utilisera les Données du Client que pour fournir au Client
          des Prestations à sa demande ou du support dans le cadre de son
          Abonnement, et ce dans les limites permises par la loi en vigueur, par
          le présent accord et par la politique de confidentialité relative à
          l'utilisation de la Plateforme.
        </p>
        <p>
          Likewatt peut exploiter les Données du Client à des fins
          d’apprentissage algorithmique pour générer des informations de manière
          globale et anonyme qui viennent appuyer certaines fonctionnalités de
          la Plateforme. Le Client consent à ce que Likewatt puisse utiliser et
          publier ces informations, à condition qu'elles n'incluent pas les
          Données des Clients ni ne permettent de l'identifier.
        </p>
        <h6>d) Publicité</h6>
        <p>
          Le Client accorde à Likewatt le droit d'ajouter son nom et le logo de
          son entreprise à sa liste de Clients et sur son Site web. Il peut
          toutefois demander à s'y opposer en contactant le service client.
        </p>
        <h5>5. Garanties et responsabilités</h5>
        <h6>a) Exclusion de garanties</h6>
        <p>
          Sans limiter les obligations qui leur incombent en vertu de la section
          « Utilisation des données » du présent accord, Likewatt, ainsi que ses
          filiales et agents, ne font aucune déclaration ni n'offrent aucune
          garantie en ce qui concerne l'adéquation, la fiabilité, la
          disponibilité, la rapidité, la sécurité ou l'exactitude des Services
          et des données fournis par la Plateforme. Les interfaces de
          programmation applicative (API) peuvent ne pas être disponibles à tout
          moment. Dans la mesure autorisée par la loi, les Services fournis par
          la Plateforme sont fournis en l'état sans garantie ou condition
          d'aucune sorte. Likewatt exclut toute garantie et condition de toute
          sorte, que ce soit expressément ou implicitement, en ce qui concerne
          les Services fournis par la Plateforme, y compris toutes les garanties
          implicites ou conditions de qualité marchande, d'adéquation à un usage
          particulier, de titre et de non-contrefaçon.
        </p>
        <h6>b) Limitation de responsabilité</h6>
        <p>
          Si, nonobstant les autres conditions du présent accord, Likewatt est
          tenue comme responsable d’un dommage envers le Client découlant de
          l’utilisation de la Plateforme, les parties conviennent que la
          responsabilité agrégée sera limitée à un montant égal au total des
          montants que le Client a versé ou doit verser pour le service
          d'Abonnement dans les douze mois précédant l'incident ayant donné lieu
          à la réclamation ; si cette limitation ne s'applique pas au Client du
          fait qu'il n'utilise que les Services gratuits, la responsabilité
          agrégée de Likewatt sera alors limitée à cent Euros.
        </p>
        <p>
          Le client comprend et accepte que sans son accord concernant cette
          limitation de responsabilité, il n'aura pas accès à la Plateforme.
        </p>
        <h5>6. Propriété intellectuelle</h5>
        <p>
          Tous les éléments de propriété intellectuelle utilisés par Likewatt
          sont protégés par les lois relatives à la propriété intellectuelle et
          autres, et notamment le droit d’auteur.
        </p>
        <p>
          La Plateforme et tous ses éléments, notamment mais non limitativement,
          les codes source, les algorithmes, les modèles, les textes, les
          images, les vidéos, les photographies, les marques, les logos, les
          dénominations sociales, les noms de domaine sont la propriété
          exclusive de Likewatt. A l’exception des éléments publiés sous une
          licence libre, toute reproduction ou représentation, même partielle,
          par quelque procédé que ce soit, toute requête automatisée ou non
          visant la récupération des données publiées sur cette plateforme,
          faites sans l’autorisation de l’éditeur sont illicites et constituent
          une contrefaçon conformément aux dispositions de la loi du 11 mars
          1957, articles 40, 41 et 42 du code pénal. Tout contrevenant sera
          poursuivi devant les juridictions compétentes.
        </p>
        <h5>7. Conditions générales de l'abonnement</h5>
        <h6>a) Durée d’abonnement</h6>
        <p>
          La Durée d'abonnement du Client est précisée dans son Bon de commande.
          Sauf mention contraire dans le Bon de commande, le renouvellement de
          l'abonnement est automatique et s’applique pour la même durée que la
          Durée d'abonnement initiale, précisée dans le Bon de commande. Afin
          d'éviter le renouvellement de l'abonnement, un avis doit être envoyé
          par le Client dans le délai indiqué dans le Bon de commande.
        </p>
        <h6>b) Ajustements des tarifs au renouvellement</h6>
        <p>
          Au moment du renouvellement, Likewatt peut changer les tarifs de
          l’Abonnement ou les caractéristiques de ce dernier. Si tel est le cas,
          le Client en sera informé au moins trente (30) jours avant la date de
          son renouvellement. Si le Client s'oppose à ces changements, il peut
          décider de ne pas renouveler son abonnement, en envoyant son avis de
          non-renouvellement à Likewatt
        </p>
        <h6>c) Résiliation pour juste cause</h6>
        <p>
          Chaque partie peut mettre fin au présent Accord pour juste cause de
          même qu’à l’Abonnement : (i) suivant un préavis de trente (30) jours
          donné à l'autre partie d'une violation substantielle des CGU présentes
          et notamment des conditions indiquées dans le paragraphe « 4.
          Utilisation des Données et Vie privée », si ladite violation n'est pas
          résolue à l'expiration de ladite période, ou (ii) immédiatement si
          l'autre partie fait l'objet d'une demande en faillite ou de toute
          autre procédure relative à l'insolvabilité, la cessation d'activité,
          la liquidation ou la cession au bénéfice des créanciers.
        </p>
        <p>
          Likewatt peut également mettre fin au présent Accord pour juste cause
          de même qu’à l’Abonnement suivant un préavis de trente (30) jours si
          la société considère que le Client agit ou a agi d'une façon qui
          entraîne des retombées négatives ou nuisibles pour Likewatt, ses
          prospects ou ses clients.
        </p>
        <p>
          Hormis les cas mentionnés, il ne peut être mis fin au présent Accord
          avant la fin de la Durée d'abonnement.
        </p>
        <h6>d) Suspension pour actes prohibés</h6>
        <p>
          Likewatt peut suspendre, sans préavis, l'accès de tout Client à l'un
          ou à l'ensemble des Services en cas : (i) d'utilisation du Service
          d'une manière qui porte atteinte aux lois nationales ou étrangères
          applicables ou aux règles ou termes du présent Accord, (ii) d'actes
          répétés de publication ou de chargement de contenu qui enfreint de
          façon effective ou supposée les droits de propriété intellectuelle ou
          de marque d'une personne ou d'une entité. Likewatt peut, sans préavis,
          examiner ou supprimer les données des Clients ou les documents
          destinés aux Clients si la société détermine en toute bonne foi qu'ils
          enfreignent les présentes conditions, sous réserve qu'elle n'ait
          aucune obligation de préapprouver, de contrôler, de surveiller ou de
          modifier les données des clients ou les documents destinés aux
          Clients.
        </p>
        <h6>e) Suspension pour défaut de paiement</h6>
        <p>
          Likewatt envoie toujours un avis de défaut de paiement pour toute
          somme due. À moins que la somme totale ne soit réglée, Likewatt peut
          suspendre l'accès d'un Client à l'un ou à l'ensemble des Services dix
          (10) jours après l'envoi dudit avis. Likewatt ne suspend pas le
          Service tant que le Client conteste les frais applicables de façon
          raisonnable et de bonne foi et qu'il coopère consciencieusement afin
          de résoudre le différend. Si un Service est suspendu pour défaut de
          paiement, il se peut que Likewatt facture des frais de réactivation
          afin de rétablir ledit service.
        </p>
        <h6>f) Suspension et résiliation des Services gratuits</h6>
        <p>
          Likewatt peut suspendre, limiter ou résilier à tout moment les
          Services gratuits pour quelque motif que ce soit sans préavis. La
          société peut également résilier l'abonnement d'un Client aux Services
          gratuits pour cause d'inactivité de sa part.
        </p>
        <h5>8. Confidentialité</h5>
        <p>
          Le Client et Likewatt s’engagent à conserver confidentielles les
          informations et documents concernant l’autre partie, de quelque nature
          qu’ils soient, financiers, techniques, sociaux ou commerciaux, qui ont
          pu être accessibles au cours de l’exécution du contrat.
        </p>
        <h5>9. Litiges, droit applicable et juridiction compétente</h5>
        <p>
          Les présentes CGV ainsi que l’ensemble des informations contractuelles
          mentionnées sur la Plateforme sont rédigées en langue française et
          soumises à la loi française.
        </p>
        <p>
          En cas de différend ou litige entre les parties, celles-ci
          s’efforceront, de bonne foi, de parvenir à un accord amiable.
        </p>
        <p>
          Si aucune solution amiable n’est trouvée, les tribunaux compétents du
          ressort de la Cour d’appel de Lyon seront exclusivement compétents.
        </p>
        <h5>10. Mentions légales</h5>
        <p>
          <strong>Propriétaire et éditeur du site :</strong>
        </p>
        <p>
          La société LIKEWATT, société SAS au capital de 11 557 € immatriculée
          au registre du commerce et des sociétés de LYON sous le n°850 417 668,
          ayant son siège social au 59 Rue de l'Abondance, 69003 Lyon, prise en
          la personne de son représentant légal dûment habilité en cette qualité
          audit siège
        </p>
        <p>
          <strong>Directeur de la publication :</strong>
        </p>
        <p>Monsieur Chady KHARRAT, 59 Rue de l'Abondance, 69003 Lyon</p>
        <p>
          <strong>Hébergeurs :</strong>
        </p>
        <p>La société OVH, 2 rue Kellermann, 59100 Roubaix, France</p>
        <p>
          La société Firebase, 188 King ST, San Francisco, CA 94107, Etats Unis
        </p>
        <p>
          <strong>Développeur du site :</strong>
        </p>
        <p>La société LIKEWATT, 59 Rue de l'Abondance, 69003 Lyon</p>
      </div>
    </div>
  );
};

export default Terms;
